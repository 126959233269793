import React from "react";
import { TextField, Grid } from "@material-ui/core";
import { SearchRounded } from "@material-ui/icons";
import { secondaryColor } from "../assets/ThemeStyling";

//this component can be imported and used to override the styling for MUI search,
//future tables do not need the search form, this will leverage MUI functionality.
//see ProjectMatterportLinksTable for details on usage.

export default function CustomSearch({ searchText, onSearchChange }) {
  return (
    <Grid container spacing={2} style={{ width: "100%", marginTop: "1rem" }}>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Search"
          color="secondary"
          value={searchText}
          onChange={(event) => onSearchChange(event.target.value)}
          InputProps={{
            startAdornment: (
              <SearchRounded
                style={{ color: secondaryColor, marginRight: ".5rem" }}
              />
            ),
            endAdornment: null,
          }}
        />
      </Grid>
    </Grid>
  );
}

// Use commented code below to override exsiting styling
//must add custom search to table options

// const customSearchTheme = createTheme({
//     overrides: {
//       MUIDataTableToolbar: {
//         iconActive: {
//           display: "none",
//         },
//       },
//     },
//   });
