import { Delete } from "@material-ui/icons";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import MomentUtils from "@date-io/moment";
import React from "react";
import { useQuery } from "@apollo/client";
import { Field, Form } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";
import {
  Tooltip,
  IconButton,
  Grid,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Loading } from "@web-applications/daffodil-component-library";
import { poundUK } from "../../queries/Project";
import { filterHidden, GET_USERS, sortByName } from "../../queries/User";
import { TextFieldAdapter } from "./NewProjectDateWizardStep2";
import { designFeesLabel } from "../../assets/GlobalLabels";

export default function ProjectCostsWizardStep1({ project, onSaveClicked }) {
  const { loading, error, data } = useQuery(GET_USERS, {
    onCompleted: function () {},
  });

  if (loading || data == null) {
    return <Loading />;
  }

  const users = [
    { _id: null, first_name: "Not", last_name: "Approved" },
    ...data.users.filter(filterHidden).sort(sortByName),
  ];

  const handleSubmit = () => {};

  return (
    <Form
      mutators={arrayMutators}
      initialValues={project}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, submitting, values, form }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Project Costs
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Field name="client_budget">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label="Client Direct"
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="includeFFInClientDirect" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...input} value="includeFFInClientDirect" />
                  }
                  label="Include F&F Total In Client Direct"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12}>
            <Field name="contract_budget">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label="Contract Budget"
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="final_accounts">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label="Final Accounts"
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="final_accounts_agreed_by">
              {({ input, meta, ...rest }) => (
                <TextField
                  {...input}
                  // {...rest}
                  // value={input.value.user_name}
                  // onChange={(event, value) => input.onChange(value)}
                  select
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                  label="Final Accounts Agreed By"
                  fullWidth
                  // required
                  variant="outlined"
                  // style={{ paddingBottom: "15px" }}
                >
                  {users.map((user) => (
                    <MenuItem key={user._id} value={user._id}>
                      {`${user.first_name} ${user.last_name}`}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="expected_margin">
              {({ input, meta }) => (
                <NumberFormat
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                  {...input}
                  label="Expected Margin"
                  displayType="tel"
                  decimalScale={0}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Field name="retention_yesno" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "retention_yesno" }}
                      {...input}
                      value="retention_yesno"
                    />
                  }
                  label={""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="retention_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    clearable={true}
                    label="Retention Release Date"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12} md={6}>
            <Field name="retention">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label="Retention Release Amount"
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="design_budget">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label={designFeesLabel}
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <FieldArray name="ffEntries">
            {({ fields }) => {
              let total = 0;
              values.ffEntries.map((entry) => {
                if (typeof entry.value === "string") {
                  entry.value = entry.value.replace(/,/g, "");
                }
                total = total + parseFloat(entry.value);
              });
              return (
                <>
                  {fields.map((entry, index) => {
                    return (
                      <Grid key={index} item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <Field
                              value={entry.name}
                              name={"ffEntries[" + index + "].name"}
                              component={TextFieldAdapter}
                              label="F&F Cost Name"
                            />
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <Field name={"ffEntries[" + index + "].value"}>
                              {({ input, meta }) => (
                                <NumberFormat
                                  {...input}
                                  onFocus={(event) => {
                                    event.target.select();
                                    input.onFocus(event);
                                  }}
                                  thousandSeparator={true}
                                  value={values.ffEntries[index].value}
                                  customInput={TextField}
                                  variant="outlined"
                                  fullWidth
                                  margin="dense"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        £
                                      </InputAdornment>
                                    ),
                                  }}
                                  label="Value"
                                  displayType="tel"
                                  decimalScale={2}
                                  fixedDecimalScale
                                  required
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ""
                                  }
                                />
                              )}
                            </Field>
                          </Grid>
                          <Grid item xs={1}>
                            <Tooltip
                              title="Remove"
                              placement="top"
                              style={{
                                display: "flex",

                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                aria-label="Remove"
                                onClick={() => fields.remove(index)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        fields.push({ name: "", value: 0 });
                      }}
                    >
                      Add F&amp;F Cost
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={12} className="MuiTypography-body1">
                    F&amp;F Total: {poundUK.format(total)}
                  </Grid>
                </>
              );
            }}
          </FieldArray>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                values.final_accounts_agreed_by_name = "";
                for (var i = 0; i < users.length; i++) {
                  if (users[i]._id === values.final_accounts_agreed_by) {
                    values.final_accounts_agreed_by_name =
                      users[i].first_name + " " + users[i].last_name;
                  }
                }
                const floatValues = [
                  "client_budget",
                  "contract_budget",
                  "final_accounts",
                  "retention",
                  "design_budget",
                ];
                for (let i = 0; i < floatValues.length; i++) {
                  let field = floatValues[i];
                  if (typeof values[field] === "string") {
                    values[field] = parseFloat(values[field].replace(/,/g, ""));
                  }
                }
                if (values.ffEntries != null) {
                  for (let i = 0; i < values.ffEntries.length; i++) {
                    if (typeof values.ffEntries[i].value === "string") {
                      values.ffEntries[i].value = parseFloat(
                        values.ffEntries[i].value.replace(/,/g, "")
                      );
                    }
                  }
                }
                values.expected_margin = parseInt(values.expected_margin);
                onSaveClicked(values);
              }}
              disabled={
                submitting ||
                values.client_budget == null ||
                values.contract_budget == null ||
                values.expected_margin == null ||
                values.final_accounts == null
              }
              type="submit"
            >
              Save
            </Button>
            {/* \<pre>{JSON.stringify(values, null, 2)}</pre> */}
          </Grid>
        </Grid>
      )}
    </Form>
  );
}
