import { UserContext } from "../../utils/PageWrapper";
import { useMutation } from "@apollo/client";
import React, { useContext } from "react";
import { Query } from "@apollo/react-components";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { Link } from "react-router-dom";

import { useHistoryState } from "../../queries/Project";
import { GET_USERS, UPDATE_USER_PREFERENCES } from "../../queries/User";
import Loading from "../../reusable/Loading";
import {
  Typography,
  Grid,
  Button,
  Tooltip,
  IconButton,
  Breadcrumbs,
} from "@material-ui/core";
import { Add, RemoveRedEye } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/core/styles";
import { secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  emailLabel,
  nameLabel,
  roleLabel,
} from "../../assets/GlobalLabels";
import ModeSelect from "./ModeSelect";

export default function UserList() {
  const user = useContext(UserContext);
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);
  const [tableCurrentPage, setTableCurrentPage] = useHistoryState(
    "tableCurrentPage",
    0
  );
  return (
    <Grid container spacing={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ fontSize: "14px", padding: "12px" }}
      >
        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          User Management
        </Typography>

        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          Users Search
        </Typography>
      </Breadcrumbs>

      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ModeSelect />
          <Button
            variant="contained"
            style={{
              marginLeft: "auto",
            }}
            color="primary"
            component={Link}
            to="users/add"
          >
            <Add style={{ marginRight: "10px" }} />
            New User
          </Button>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Query query={GET_USERS}>
          {({ loading, error, data }) => {
            if (loading) {
              return <Loading />;
            }

            if (error) {
              return <Loading />;
            }

            let { users } = data;

            const formattedUsersArray = [];

            users.forEach((user) => {
              let userRole = "";

              switch (user.role_id) {
                case 1:
                  userRole = "User";

                  break;
                case 2:
                  userRole = "Manager";

                  break;
                case 3:
                  userRole = "Office Member";

                  break;
                case 4:
                  userRole = "Admin";

                  break;

                default:
                  break;
              }

              const data = [
                {
                  columnDetails: {
                    name: nameLabel,
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: `${user.first_name} ${user.last_name}`,
                },
                {
                  columnDetails: {
                    name: roleLabel,
                    options: {
                      filter: true,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: userRole,
                },
                {
                  columnDetails: {
                    name: emailLabel,
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: (
                    <a
                      style={{ color: secondaryColor }}
                      href={`mailto:${user.email}`}
                    >
                      {user.email}
                    </a>
                  ),
                },
                {
                  columnDetails: {
                    name: "Created Date",
                    options: {
                      filter: false,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: moment(user.created_date, "x").format(
                    "DD/MM/YYYY"
                  ),
                },
                {
                  columnDetails: {
                    name: "Archived",
                    options: {
                      filter: true,
                      sort: true,
                      download: true,
                    },
                  },
                  columnData: user.archived ? "Yes" : "No",
                },
                {
                  columnDetails: {
                    name: actionsLabel,
                    options: {
                      filter: false,
                      sort: false,
                      download: false,
                      display: "true",
                    },
                  },
                  columnData: (
                    <Tooltip title="View User Details" placement="top">
                      <IconButton
                        aria-label="View"
                        component={Link}
                        to={"users/edit/" + user._id}
                      >
                        <RemoveRedEye />
                      </IconButton>
                    </Tooltip>
                  ),
                },
              ];

              formattedUsersArray.push(data);
            });

            const columnsArray = [];
            const userData = [];

            formattedUsersArray.forEach((columns, index) => {
              const columnData = [];

              columns.forEach((column) => {
                columnData.push(column.columnData);
              });

              userData.push(columnData);

              //only create column titles on first pass
              if (index === 0) {
                columns.forEach((column) => {
                  columnsArray.push(column.columnDetails);
                });
              }
            });

            const options = {
              filterType: "checkbox",
              selectableRows: "none",
              print: false,
              responsive: "standard",
              downloadOptions: {
                filename: "Users.csv",
              },
              sortOrder: {
                name: nameLabel,
                direction: "asc",
              },
              onChangePage: (currentPage) => {
                setTableCurrentPage(currentPage);
              },
              page: tableCurrentPage,
              onChangeRowsPerPage: (rowsPerPage) => {
                if (user.preferences == null) {
                  user.preferences = {};
                }
                user.preferences.usersRowsPerPage = rowsPerPage;
                updateUserPreferences({
                  variables: {
                    _id: user._id,
                    preferences: user.preferences,
                  },
                });
              },
              rowsPerPage:
                user?.preferences?.usersRowsPerPage >= 10
                  ? user?.preferences?.usersRowsPerPage
                  : 10,
            };

            return (
              <ThemeProvider
                theme={(outerTheme) => ({
                  ...outerTheme,
                  // outerTheme: Object.assign(outerTheme.overrides, {
                  //   MUIDataTableHeadCell: {
                  //     root: {
                  //       "&:last-child": {
                  //         width: 100,
                  //         padding: "4px 24px",
                  //       },
                  //     },
                  //   },
                  // }),
                })}
              >
                <MUIDataTable
                  title=""
                  data={userData}
                  columns={columnsArray}
                  options={options}
                />
              </ThemeProvider>
            );
          }}
        </Query>
      </Grid>
    </Grid>
  );
}
