export function initTasks() {
  const currentDate = new Date();
  const tasks = [
    {
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      progress: 0,
      type: "project",
      hideChildren: false,

      id: "ProjectSample3",
      name: "Some Project3",
      tableDisplayData: [
        "Job Number",
        "Job Name",
        "Client",
        "Location",
        "Designer",
        "QS",
        "PM",
        "Duration",
        "Start",
        "Finish",
        "Project Status",
      ],
    },
  ];
  return tasks;
}

export const findLeadInitials = (users, role) => {
  const foundUser = users.find((user) => user.role === role);

  let initials = "";
  if (foundUser)
    initials =
      foundUser.userInfo[0].first_name.slice(0, 1) +
      foundUser.userInfo[0].last_name.slice(0, 1);

  return initials;
};

export const services = ["All", "Build", "Design", "Design and Build"];

export function getStartEndDateForProject(tasks, projectId) {
  const projectTasks = tasks.filter((t) => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}
