export const practicalCompletionLabel = "Practical Completion";
export const nameLabel = "Name";
export const clientNameLabel = "Client Name";
export const clientLabel = "Client";
export const leadLabel = "Lead";
export const projectRoleLabel = "Project Role";
export const contactNumberLabel = "Contact Number";
export const emailAddressLabel = "Email Address";
export const emailLabel = "Email";
export const actionsLabel = "Actions";
export const roleLabel = "Role";
export const dateLabel = "Date";
export const projectLabel = "Project";
export const detailsLabel = "Details";
export const statusLabel = "Status";
export const designFeesLabel = "Design Fees";
