import React, { useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";

import Loading from "../../reusable/Loading";
import { openSnackbar } from "../../reusable/Notifier";

import {
  Grid,
  TextField,
  Typography,
  Button,
  MenuItem,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@material-ui/core";

import { Field, FormSpy } from "react-final-form";
import { useStore } from "./WizPageStore";
import PageSwitch from "./PageSwitch";
import { Box } from "@mui/system";

const FIND_ADDRESS = gql`
  query findAddress($postcode: String!) {
    findAddress(postcode: $postcode) {
      address_1
      address_2
      city
      postcode
    }
  }
`;

export default function NameLocation() {
  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);
  const [expanded, setExpanded] = useState(false);

  // console.log("expanded", expanded);

  const [findAddress, { loading, data }] = useLazyQuery(FIND_ADDRESS, {
    onCompleted: (returnData) => {
      // console.log("completed data", returnData);
    },
    onError: () => {
      openSnackbar({
        message: "Failed to find address, please try again.",
        type: "error",
      });
    },
  });

  return (
    <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
      {({ values, pristine, submitting, form }) => (
        <Box
          //main container
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            //content container
            container
            spacing={3}
            style={{ flex: "1 0 auto" }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                {"Name & Location"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Field name="project_name">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    label="Project Name"
                    fullWidth
                    required
                    size="small"
                    variant="outlined"
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field name="project_address.postcode">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="Postcode"
                        fullWidth
                        required
                        size="small"
                        variant="outlined"
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      findAddress({
                        variables: {
                          postcode: values.project_address?.postcode,
                          // postcode: values.addressPostcode,
                        },
                      });
                    }}
                    disabled={
                      submitting
                      // || !values.project_name
                    }
                  >
                    Postcode Lookup
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    // color="primary"
                    onClick={() => {
                      setExpanded(!expanded);
                    }}
                    disabled={
                      submitting
                      // || !values.project_name
                    }
                  >
                    Enter Manually
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              {loading ? (
                <Loading />
              ) : data ? (
                <Field name="project_address">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                      label="Select Address"
                      fullWidth
                      size="small"
                      // required
                      variant="outlined"
                      style={{ paddingBottom: "7px" }}
                    >
                      {data?.findAddress?.map((address) => (
                        <MenuItem value={address}>{address.address_1}</MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              ) : (
                <></>
              )}

              <Accordion expanded={expanded}>
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{ display: "none" }}
                ></AccordionSummary>
                <AccordionDetails style={{ padding: "0" }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Field name="project_address.address_1">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Project Address 1"
                            fullWidth
                            required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="project_address.address_2">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Project Address 2"
                            fullWidth
                            // required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="project_address.city">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="City"
                            fullWidth
                            required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          {/* 
          <Grid container style={{ marginTop: "calc(5% + 130px)" }}>
            <Grid item xs={12}>
              <Divider
                style={{
                  // marginTop: "25px",
                  marginBottom: "12px",
                  marginLeft: "-12px",
                  marginRight: "-24px",
                  backgroundColor: "#C3CAD8",
                }}
              />
            </Grid>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "13px",
                marginBottom: "12px",
                marginLeft: "24px",
                marginRight: "24px",
              }}
            >
              <Button
                // disabled={true}
                onClick={() => {
                  pageChange(-2);
                }}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                // onClick={next}

                disabled={
                  submitting
                  // || !values.project_name
                }
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </Grid> */}

          <PageSwitch
            disabled={
              submitting ||
              (page === 1 && !values.companyId) ||
              (page === 0 && !values.companyId)
            }
            onBackClick={() => {
              pageChange(-2);
            }}
            submit
          />
        </Box>
      )}
    </FormSpy>
  );
}
