import React from "react";

import DesignCostsWizardStep1 from "./DesignCostsWizardStep1";

const DesignCostsModal = (props) => {
  return (
    <DesignCostsWizardStep1
      project={props.project}
      onSaveClicked={props.onSaveClicked}
    />
  );
};

export default DesignCostsModal;
