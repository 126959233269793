import { Field } from "react-final-form";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Search } from "@material-ui/icons";
import { ThemeProvider } from "@material-ui/styles";
import Loading from "../../reusable/Loading";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Breadcrumbs,
} from "@material-ui/core";
import { Form } from "react-final-form";
import { createTheme } from "@material-ui/core";
import { GET_UPDATES } from "../../queries/Project";

import { TextFieldAdapter } from "../Customers/Companies";
import { secondaryColor } from "../../assets/ThemeStyling";
import { Link } from "react-router-dom";
import {
  clientLabel,
  dateLabel,
  projectLabel,
} from "../../assets/GlobalLabels";
import { UserContext } from "../../utils/PageWrapper";
import { UPDATE_USER_PREFERENCES } from "../../queries/User";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 100,
        },
        "&:nth-child(2)": {
          width: 200,
        },
        "&:nth-child(4)": {
          width: 200,
        },
        "&:nth-child(5)": {
          width: 100,
        },
      },
    },
  },
});

const initialStartDate = moment().subtract(3, "months");

export default function Updates() {
  const user = useContext(UserContext);
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);
  const [firstLoad, setFirstLoad] = useState(true);
  const [variables, setVariables] = useState({ start_date: initialStartDate });
  const onSubmit = (values) => {
    setVariables(values);
    getUpdates({ variables: values });
  };

  const [
    getUpdates,
    { loading: getUpdatesLoading, error: getUpdatesError, data },
  ] = useLazyQuery(GET_UPDATES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
  });

  // console.log("data", data);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
      getUpdates({ variables: variables });
    }
  }, [firstLoad, variables, getUpdates, setFirstLoad]);

  let view = <Loading></Loading>;

  if (getUpdatesError) {
    view = (
      <Typography variant="body1">
        Failed to get the updates, please try again.
      </Typography>
    );
  }

  if (data && data.getProjectUpdates) {
    const { getProjectUpdates } = data;
    //format data
    const formattedCustomersArray = getProjectUpdates.map((update) => {
      return [
        update.userInfo[0].first_name + " " + update.userInfo[0].last_name,
        update.project_job_no,
        update.the_update,
        update.companies_name,
        update.date_time_added,
      ];
    });

    const columns = [
      {
        name: "User",
      },
      {
        name: projectLabel,
        options: {
          customBodyRenderLite: (dataIndex) => {
            const update = getProjectUpdates[dataIndex];
            return (
              <Link
                style={{ color: secondaryColor }}
                to={"/projects/single/" + update.project_id}
              >
                {update.project_job_no + " " + update.project_name}
              </Link>
            );
          },
        },
      },
      {
        name: "Update",
      },
      {
        name: clientLabel,
      },
      {
        name: dateLabel,
        options: {
          customBodyRender: (value) => {
            return moment(value).format("DD/MM/YYYY hh:mm");
          },
        },
      },
    ];

    const options = {
      selectableRows: "none",
      print: false,
      download: false,
      responsive: "standard",
      search: false,
      filter: false,
      elevation: 0,
      viewColumns: false,
      sortOrder: {
        name: "Date",
        direction: "desc",
      },
      rowsPerPageOptions: [100, 200, 300],
      rowsPerPage: user?.preferences?.updatesRowsPerPage,

      onChangeRowsPerPage: (rowsPerPage) => {
        if (user.preferences == null) {
          user.preferences = {};
        }
        user.preferences.updatesRowsPerPage = rowsPerPage;
        updateUserPreferences({
          variables: {
            _id: user._id,
            preferences: user.preferences,
          },
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      },
    };

    view = (
      <div
        style={{
          paddingLeft: "1em",
          paddingRight: "1em",
          width: "100%",
          borderTop: "1px solid #E7EAEF",
        }}
      >
        <ThemeProvider theme={columnWidthTheme}>
          <MUIDataTable
            data={formattedCustomersArray}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <>
      <Grid container spacing={3}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ fontSize: "14px", padding: "12px" }}
        >
          <Typography color="text.primary" style={{ fontSize: "14px" }}>
            Updates
          </Typography>

          <Typography color="text.primary" style={{ fontSize: "14px" }}>
            Update Search
          </Typography>
        </Breadcrumbs>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography component="h1" variant="h4" gutterBottom>
              Updates
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Card>
        <CardContent style={{ paddingLeft: "0", paddingRight: "0" }}>
          <Form
            initialValues={{ start_date: initialStartDate }}
            onSubmit={onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} style={{}}>
                <Grid container spacing={3} style={{ marginBottom: "1em" }}>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "2em",
                    }}
                  >
                    <Button
                      fullWidth
                      type="submit"
                      loading={getUpdatesLoading}
                      disabled={submitting || getUpdatesLoading}
                    >
                      <Search style={{ fill: secondaryColor }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Field name="start_date">
                        {({ input, meta }) => (
                          <DatePicker
                            clearable={true}
                            format="DD/MM/YYYY"
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value ? input.value : null}
                            onBlur={input.onBlur}
                            fullWidth
                            required={false}
                            disablePast={false}
                            autoOk={true}
                            disabled={false}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                            label="From"
                            leftArrowIcon={<KeyboardArrowLeft />}
                            inputVariant="outlined"
                            rightArrowIcon={<KeyboardArrowRight />}
                          />
                        )}
                      </Field>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Field name="end_date">
                        {({ input, meta }) => (
                          <DatePicker
                            clearable={true}
                            format="DD/MM/YYYY"
                            name={input.name}
                            onChange={input.onChange}
                            value={input.value ? input.value : null}
                            onBlur={input.onBlur}
                            fullWidth
                            required={false}
                            disablePast={false}
                            autoOk={true}
                            disabled={false}
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                            label="To"
                            leftArrowIcon={<KeyboardArrowLeft />}
                            inputVariant="outlined"
                            rightArrowIcon={<KeyboardArrowRight />}
                          />
                        )}
                      </Field>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      name="the_update"
                      component={TextFieldAdapter}
                      label="Update"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Field
                      name="companies_name"
                      component={TextFieldAdapter}
                      label={clientLabel}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          />
          <Grid container>
            <Grid item xs={12} style={{ padding: "0" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: data ? "1px" : "350px",
                }}
              >
                {view}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
