import { openSnackbar } from "../../reusable/Notifier";

export default function transformMatterportlink(url) {
  const regex = /https:\/\/my\.matterport\.com\/show\/\?m=([a-zA-Z0-9]{11})/;

  const match = url.match(regex);

  if (match && match[1]) {
    return `https://my.matterport.com/api/v2/player/models/${match[1]}/thumb/`;
  } else {
    throw new Error("Invalid URL format. URL must be a Matterport project.");
  }
}
