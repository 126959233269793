import React from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import { Form, Field } from "react-final-form";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import Cookies from "js-cookie";

import {
  TextField,
  Typography,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

import { Input } from "@material-ui/icons";
import { emailLabel } from "../../assets/GlobalLabels";

const LOGIN = gql`
  mutation loginUser(
    $email: String!
    $password: String!
    $rememberMe: Boolean
  ) {
    loginUser(email: $email, password: $password, rememberMe: $rememberMe) {
      _id
    }
  }
`;

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default function Login({ history }) {
  function goto(returnTo) {
    if (returnTo == null || returnTo.length === 0 || returnTo === "/login") {
      history.push("/projects");
    } else {
      history.push(returnTo);
    }
  }
  const returnTo = getParameterByName("returnTo");
  const [loginUser, { loading, client }] = useMutation(LOGIN, {
    onCompleted: () => {
      client.resetStore();
      //goto(returnTo)
    },
  });

  //redirect user to home page if they try to login whilst already logged in

  if (Cookies.get("app")) {
    goto(returnTo);
  }

  return (
    <Form
      onSubmit={async ({ email, password, rememberMe }) => {
        const response = await loginUser({
          variables: {
            email: email,
            password: password,
            rememberMe: rememberMe || false,
          },
        }).catch((error) => {
          let message=JSON.stringify(error)
          if(error!=null && error.graphQLErrors!=null && error.graphQLErrors[0]!=null){
            message=error.graphQLErrors[0].message
          }
          return {
            email: message,
            password: message,
          };
        });

        if (!response.data) return response;
      }}
      validate={(values) => {
        const errors = {};

        const requiredFields = ["email", "password"];

        requiredFields.forEach((requiredField) => {
          if (!values[requiredField]) {
            errors[requiredField] = "Required";
          }
        });

        if (
          values.email &&
          !validator.isEmail(values.email) &&
          !values.email.includes(".con") &&
          !values.email.includes(".coma")
        ) {
          errors.email = "Invalid Email";
        }

        return errors;
      }}
      render={({
        handleSubmit,
        pristine,
        submitting,
        invalid,
        dirtySinceLastSubmit,
      }) => (
        <form
          onSubmit={handleSubmit}
          style={{ maxWidth: "600px", margin: "auto" }}
          noValidate
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h2"
                gutterBottom
                align="center"
              >
                Login
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="email">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="email"
                    label={emailLabel}
                    fullWidth
                    required
                    type="email"
                    variant="outlined"
                    color="secondary"
                    error={
                      (meta.error && meta.touched) ||
                      (!!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting)
                    }
                    helperText={
                      meta.error && meta.touched
                        ? meta.error
                        : !!meta.submitError &&
                          !dirtySinceLastSubmit &&
                          !submitting
                        ? meta.submitError
                        : ""
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="password">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    name="password"
                    label="Password"
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    color="secondary"
                    error={
                      (meta.error && meta.touched) ||
                      (!!meta.submitError &&
                        !dirtySinceLastSubmit &&
                        !submitting)
                    }
                    helperText={
                      meta.error && meta.touched
                        ? meta.error
                        : !!meta.submitError &&
                          !dirtySinceLastSubmit &&
                          !submitting
                        ? meta.submitError
                        : ""
                    }
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Field name="rememberMe" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "rememberMe" }}
                        {...input}
                        value="rememberMe"
                      />
                    }
                    label="Remember Me"
                  />
                )}
              </Field>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  pristine || (invalid && !dirtySinceLastSubmit) || loading
                }
                type="submit"
              >
                Login
                <Input
                  style={{
                    marginLeft: "10px",
                    fontSize: 20,
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Button
                component={Link}
                to="forgotten-password"
                fullWidth
                variant="outlined"
              >
                Forgotten Password
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
}
