import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { UserContext } from "../../utils/PageWrapper";

export default function Home({ history }) {
  const user = useContext(UserContext);

  if (user == null) {
    return <Redirect to="/login" />;
  } else {
    return <Redirect to="/projects" />;
  }
}
