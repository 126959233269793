import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Typography, Grid, Card, Button, TextField } from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { GET_PROJECT_ACTIVITIES } from "../../../queries/Project";
import ProjectActivityTable from "../ProjectActivityTable";
import { Search } from "@material-ui/icons";
import { Field, Form } from "react-final-form";
import { secondaryColor } from "../../../assets/ThemeStyling";
import { detailsLabel } from "../../../assets/GlobalLabels";
const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={({ target: { value } }) => input.onChange(value)}
    errorText={meta.touched ? meta.error : ""}
    variant="outlined"
    color="secondary"
    fullWidth
  />
);
export default function ProjectActivity({ project }) {
  const [variables, setVariables] = useState({ content: "" });
  const [
    getActivities,
    { loading: updatesLoading, error: updatesError, data: updatesData },
  ] = useLazyQuery(GET_PROJECT_ACTIVITIES, {
    fetchPolicy: "network-only", //if we don't do this then when we add an update from the summary tab it doesn't show up
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    variables: {
      projectId: project._id,
      content: variables.content,
    },
  });

  useEffect(() => {
    getActivities();
  }, [getActivities]);

  if (updatesError) {
    return <ErrorMessage message={updatesError} />;
  }

  if (updatesLoading || updatesData == null) {
    return <Loading />;
  }

  return (
    <Card style={{ padding: "0", marginTop: "12px", width: "100%" }}>
      <Grid
        item
        xs
        style={{
          marginBottom: "16px",
          padding: "26px",
          // borderBottom: "1px solid #E7EAEF",
          backgroundColor: "#4B4F54",
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          Project Activity
          {/* <Tooltip title="Add" placement="top">
                  <IconButton
                  onClick={() => {
          
                  }}
                  >
                    <AddCircleIcon style={{ fill: primaryColor }} />
                  </IconButton>
                </Tooltip> */}
        </Typography>
      </Grid>

      <Form
        initialValues={variables}
        onSubmit={(values) => {
          setVariables(values);
          getActivities();
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit} style={{}}>
            <Grid
              container
              style={{
                borderBottom: "1px solid #E7EAEF",
                paddingBottom: "1em",
              }}
            >
              <Grid
                item
                xs={1}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Button
                  fullWidth
                  type="submit"
                  // loading={getCustomersLoading}
                  //disabled={submitting || pristine}
                >
                  <Search style={{ fill: secondaryColor }} />
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name="content"
                  component={TextFieldAdapter}
                  label={detailsLabel}
                />
              </Grid>
            </Grid>
          </form>
        )}
      ></Form>

      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <ProjectActivityTable dates={updatesData.getActivities} />
      </div>
    </Card>
  );
}
