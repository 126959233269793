import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";

import { Typography, Grid, Card, IconButton, Tooltip } from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";

import { openDialog } from "../../../reusable/ScrollablePopup";
import ProjectUsersTable from "../ProjectUsersTable";
import ProjectPeopleAddUserModal from "../ProjectPeopleAddUserModal";
import ProjectPeopleAddContactModal from "../ProjectPeopleAddContactModal";
import ProjectClientContactsTable from "../ProjectClientContactsTable";

import { primaryColor } from "../../../assets/ThemeStyling";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const GET_CONTACTS = gql`
  query getProjectContacts($project_id: ID!) {
    getProjectContacts(project_id: $project_id) {
      _id
      role
      contact {
        contact_name
        contact_email
        contact_number
      }
      company {
        name
      }
    }
  }
`;

const GET_PROJECT_USERS = gql`
  query getProjectUsers($_id: String!) {
    getProjectUsers(_id: $_id) {
      _id
      lead
      role
      user {
        _id
        email
        first_name
        last_name
        mobile
      }
    }
  }
`;

export default function ProjectPeople(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const [
    getContacts,
    { loading: contactsLoading, error: contactsError, data: contactsData },
  ] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    variables: {
      project_id: props.project._id,
    },
  });

  const [
    getUsers,
    { loading: usersLoading, error: usersError, data: usersData },
  ] = useLazyQuery(GET_PROJECT_USERS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    variables: {
      _id: props.project._id,
    },
  });

  useEffect(() => {
    getUsers();
    getContacts();
  }, [getUsers, getContacts]);

  if (usersError || contactsError) {
    return <ErrorMessage error={usersError || contactsError} />;
  }

  if (
    usersLoading ||
    usersData == null ||
    contactsLoading ||
    contactsData == null
  ) {
    return <Loading />;
  }

  return (
    <>
      <Card
        style={{
          marginBottom: "24px",
          marginTop: "12px",
          padding: "0",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              padding: "18px 24px",
              // borderBottom: "1px solid #E7EAEF",
              backgroundColor: "#4B4F54",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
              }}
            >
              Project Staff
              <Tooltip title="Add" placement="top">
                <IconButton
                  onClick={() => {
                    setAnchorEl(null);
                    openDialog({
                      content: (
                        <ProjectPeopleAddUserModal
                          project_id={props.project._id}
                          projectId={props.project.project_id}
                          projectUsers={usersData}
                          onAdded={() => {
                            getUsers();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <AddCircleIcon style={{ fill: primaryColor }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <ProjectUsersTable
            dates={usersData.getProjectUsers}
            onRemoved={() => {
              getUsers();
            }}
          />
        </div>
      </Card>

      <Card style={{ marginBottom: "24px", padding: "0", width: "100%" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              padding: "18px 24px",
              // borderBottom: "1px solid #E7EAEF",
              backgroundColor: "#4B4F54",
            }}
          >
            <Typography
              variant="h5"
              style={{
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                color: "white",
              }}
            >
              Client Contacts
              <Tooltip title="Add" placement="top">
                <IconButton
                  onClick={() => {
                    setAnchorEl(null);
                    openDialog({
                      content: (
                        <ProjectPeopleAddContactModal
                          project={props.project}
                          onAdded={() => {
                            getContacts();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <AddCircleIcon style={{ fill: primaryColor }} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <ProjectClientContactsTable
            dates={contactsData.getProjectContacts}
            onRemoved={() => {
              getContacts();
            }}
          />
        </div>
      </Card>
    </>
  );
}
