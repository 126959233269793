import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import { openDialog } from "../../reusable/ScrollablePopup";
import { secondaryColor } from "../../assets/ThemeStyling";
import { actionsLabel } from "../../assets/GlobalLabels";
import {
  Typography,
  Grid,
  IconButton,
  Card,
  Tooltip,
  createTheme,
} from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MatterPortLinkModal from "./MatterportLinkModal";
import { primaryColor } from "../../assets/ThemeStyling";
import { useMutation } from "@apollo/client";
import { DELETE_MATTERPORT_LINK } from "../../queries/MatterportLinks";
import { openSnackbar } from "../../reusable/Notifier";
import ConfirmationModal from "../../reusable/ConfirmationModal";
import { ThemeProvider } from "@material-ui/styles";
import CustomSearch from "../../reusable/CustomSerchInput";
import archive from "./archive.png";
//format links to be used in MUIdatatable, called in setTableData()
function formatMatteportLinks(links) {
  return links.map((linkObject) => {
    //format the date for the table
    const updated_at = new Date(parseInt(linkObject.updatedAt));
    const dateOptions = { month: "short", day: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit" };
    const formattedDate = `${updated_at.toLocaleDateString(
      undefined,
      dateOptions
    )} ${updated_at.toLocaleTimeString(undefined, timeOptions)}`;

    return {
      _id: linkObject._id,
      project_id: linkObject.project_id,
      name: linkObject.name,
      link: linkObject.link,
      image: linkObject.image,
      updated_at: formattedDate,
    };
  });
}

//match default styling provided by sort behvior in MUI data tables
const columnHeadersTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: "0.91rem",
        fontWeight: 400,
      },
    },
  },
});

const customSearchTheme = createTheme({
  overrides: {
    MUIDataTableToolbar: {
      iconActive: {
        display: "none",
      },
    },
  },
});

export default function ProjectMatterportLinksTable({ project }) {
  // declare variables
  const [tableData, setTableData] = useState([]);
  const [searchText, setSearchText] = useState("");

  //delete link
  const [deleteMatterportLink, { loading }] = useMutation(
    DELETE_MATTERPORT_LINK,
    {
      onError: () => {
        openSnackbar({
          message: "Error: Failed to delete Link",
          type: "error",
        });
      },
      onCompleted: (data) => {
        //update the tabledate with the return value of the resolver, to instantly update UI
        const updatedLinks = data.deleteMatterportLink;
        setTableData(formatMatteportLinks(updatedLinks));
        openSnackbar({
          message: "Success: Link deleted",
          type: "success",
        });
      },
    }
  );

  const handleDeleteMatterportLink = async (_id, project_id) => {
    try {
      await deleteMatterportLink({
        variables: { _id: _id, project_id: project_id },
      });
    } catch (error) {
      console.log("Error deleting link:", error);
    }
  };

  //initialise links table with exisiting data
  useEffect(() => {
    if (project.matterportLinks) {
      setTableData(formatMatteportLinks(project.matterportLinks));
    }
  }, [project.matterportLinks]);

  //determines inbuilt features of the table
  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: true,
    searchText: searchText,
    customSearchRender: (value, handleSearch) => (
      <CustomSearch
        searchText={value}
        onSearchChange={(newValue) => {
          setSearchText(newValue);
          handleSearch(newValue);
        }}
      />
    ),
    searchOpen: true,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: "Date",
      direction: "asc",
    },
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    searchPlaceholder: "Search",
  };

  //below is the format of the table colums.
  // used in MUI datatable
  const columnsArray = [
    {
      name: "Name",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value, tableMeta) => {
          const link = tableMeta.rowData[3]; //grabs the link from MUI object
          return (
            <a href={link} target="_blank">
              <Typography style={{ color: secondaryColor }}>{value}</Typography>
            </a>
          );
        },
      },
    },
    {
      name: "Last Updated",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value) => <span>{value}</span>,
      },
    },
    {
      name: "Image",
      options: {
        sort: false,
        display: "true",
        customBodyRender: (value, tableMeta) => {
          const link = tableMeta.rowData[3];

          return (
            <span>
              <a href={link} target="_blank">
                <img
                  src={value}
                  alt="thumbnail"
                  style={{ width: "12rem", borderRadius: "12px" }}
                  onError={(error) => {
                    error.target.onerror = null;
                    error.target.src = archive;
                  }}
                />
              </a>
            </span>
          );
        },
      },
    },
    {
      name: actionsLabel,
      options: {
        sort: false,
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          const currentLink = tableData[dataIndex];
          return (
            <>
              <Tooltip title="Edit Link" placement="top">
                <IconButton
                  onClick={() => {
                    openDialog({
                      content: (
                        <MatterPortLinkModal
                          tableData={tableData}
                          currentLink={currentLink}
                          setTableData={setTableData}
                        />
                      ),
                    });
                  }}
                >
                  <CreateIcon style={{ fill: secondaryColor }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Link" placement="top">
                <IconButton
                  onClick={() => {
                    openDialog({
                      content: (
                        <ConfirmationModal
                          title={"Are you sure?"}
                          message={currentLink.name}
                          argsArray={[currentLink._id, currentLink.project_id]}
                          onConfirm={handleDeleteMatterportLink}
                          confirmButtonName={"Delete"}
                        />
                      ),
                    });
                  }}
                >
                  <DeleteIcon style={{ fill: secondaryColor }} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <Card style={{ padding: "0" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            padding: "18px 24px",
            // borderBottom: "1px solid #E7EAEF",
            backgroundColor: "#4B4F54",
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            Matterport Links
            <Tooltip title="Add Link" placement="top">
              <AddCircleIcon
                sx={{
                  color: primaryColor,
                  ":hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  openDialog({
                    content: (
                      <MatterPortLinkModal
                        tableData={tableData}
                        setTableData={setTableData}
                        project_id={project._id}
                      />
                    ),
                  });
                }}
              >
                <CreateIcon style={{ fill: primaryColor }} />
              </AddCircleIcon>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
      <ThemeProvider theme={columnHeadersTheme}>
        <ThemeProvider theme={customSearchTheme}>
          <MUIDataTable
            data={tableData.map((item) => [
              item.name,
              item.updated_at,
              item.image,
              item.link,
              item._id,
              item.project_id,
            ])}
            columns={columnsArray}
            options={options}
          />
        </ThemeProvider>
      </ThemeProvider>
    </Card>
  );
}

export { formatMatteportLinks }; //exported for use in modal where update and create is used
