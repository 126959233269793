import React from "react";

import UnsecureRoute from "../utils/UnsecureRoute";
import Login from "../pages/Login/Login";
import ResetPassword from "../pages/Login/ResetPassword";
import ForgottenPassword from "../pages/Login/ForgottenPassword";

const LoginRoutes = [
  <UnsecureRoute path="/login" component={Login} title="Login" />,
  <UnsecureRoute
    path="/forgotten-password"
    component={ForgottenPassword}
    title="Forgotten Password"
  />,
  <UnsecureRoute
    path="/reset-password/:reset_token"
    component={ResetPassword}
    title="Reset Password"
  />,
];

export default LoginRoutes;
