import gql from "graphql-tag";

export const GET_UI_LIST_ENTRIES = gql`
  query entries($type: String) {
    entries(type: $type) {
      _id
      name
      value
      type
    }
  }
`;
