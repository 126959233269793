import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import NumberFormat from "react-number-format";
import MomentUtils from "@date-io/moment";
import React from "react";

import { Field, Form, FormSpy } from "react-final-form";

import { closeDialog } from "../../reusable/ScrollablePopup";
import {
  Grid,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
} from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { practicalCompletionLabel } from "../../assets/GlobalLabels";

export default function ProjectDetailsWizardStep1({ project, onSaveClicked }) {
  const handleSubmit = () => {};
  return (
    <Form initialValues={project} onSubmit={handleSubmit}>
      {({
        handleSubmit,
        submitting,
        values,
        // form: {
        //   mutators: { push, pop },
        // },
      }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Project Overview
            </Typography>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Field name="practical_completion" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "practical_completion" }}
                      {...input}
                      value="practical_completion"
                    />
                  }
                  label={""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={11}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="practical_completion_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    clearable={true}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label={practicalCompletionLabel}
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Field name="hs_returned" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "hs_returned" }}
                      {...input}
                      value="hs_returned"
                    />
                  }
                  label={""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={11}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="hs_returned_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    clearable={true}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label="H&amp;S Returned"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Field name="final_accounts_yesno" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "final_accounts_yesno" }}
                      {...input}
                      value="final_accounts_yesno"
                    />
                  }
                  label={""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="final_accounts_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    clearable={true}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label="Final Accounts Agreed"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={6}>
            <Field name="final_accounts">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label="Final Accounts Amount"
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Field name="om_manuals" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "om_manuals" }}
                      {...input}
                      value="om_manuals"
                    />
                  }
                  label={""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={11}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="om_manuals_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    clearable={true}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label="O&amp;M Manuals Issued"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Field name="retention_yesno" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "retention_yesno" }}
                      {...input}
                      value="retention_yesno"
                    />
                  }
                  label={""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={5}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="retention_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    clearable={true}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label="Retention Release Date"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={6}>
            <Field name="retention">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label="Retention Release Amount"
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const floatValues = ["final_accounts", "retention"];
                for (let i = 0; i < floatValues.length; i++) {
                  let field = floatValues[i];
                  if (typeof values[field] === "string") {
                    values[field] = parseFloat(values[field].replace(/,/g, ""));
                  }
                }
                onSaveClicked(values);
              }}
              disabled={submitting}
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
}
