import React from "react";
import {
  projectSearchDefaultValue,
  projectSearchKey,
} from "../reusable/useSessionStorage";

import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Dropdown from "../uicomponents/Dropdown";
import styled from "styled-components";

import { Input, Settings } from "@material-ui/icons";

import Cookies from "js-cookie";

import logo from "../assets/images/cp-type.svg";
import avatar from "../assets/images/default-avatar 2.png";
import Icon from "@mdi/react";
import { mdiMenuDown } from "@mdi/js";

import { Link, useLocation, useHistory } from "react-router-dom";
import FlexBox from "../uicomponents/FlexBox";

const NavLink = styled(Link)`
  &:hover {
    background-color: #34373a;
  }
  color: #ffffff;
  padding: 24px 16px;
`;

const NavDropdownButton = styled(Button)`
  padding: 8px;
  text-transform: none;
  width: 100%;
  height: 100%;
`;

const NavMenuItem = styled(MenuItem)`
  padding: 0;
  &:hover {
    background-color: rgba(19, 68, 180, 0.1);
  }
`;

const NavBox = styled(FlexBox)`
  cursor: pointer;
  &:hover {
    background-color: #34373a;
  }
  padding: 24px 8px;
`;

export default function Navbar({ user }) {
  const location = useLocation();

  return (
    <AppBar
      position="fixed"
      style={{
        zIndex: 999,
        backgroundColor: "#4B4F54",
        display: "flex",

        alignItems: "center",
      }}
    >
      <Toolbar
        disableGutters
        style={{
          padding: "0 10px",
          width: "100%",
          maxWidth: "1194px",
        }}
      >
        <Link
          to="/"
          onClick={() => {
            sessionStorage.setItem(
              projectSearchKey,
              JSON.stringify(projectSearchDefaultValue)
            );
          }}
          style={{
            backgroundImage: "url(" + logo + ")",
            height: "40px",
            width: "70px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            marginLeft: "10px",
            marginRight: "8px",
            borderRight: "1px solid #5B5F64",
            paddingRight: "134px",
          }}
        />

        <NavLink
          to="/projects"
          style={{
            backgroundColor: location.pathname.includes("/projects")
              ? "#34373A"
              : "",
          }}
        >
          <Typography variant="body1">Projects</Typography>
        </NavLink>

        <NavLink
          to="/updates"
          style={{
            backgroundColor: location.pathname.includes("/updates")
              ? "#34373A"
              : "",
          }}
        >
          <Typography variant="body1">Updates</Typography>
        </NavLink>
        <NavLink
          to="/clients"
          style={{
            backgroundColor: location.pathname.includes("/clients")
              ? "#34373A"
              : "",
          }}
        >
          <Typography variant="body1">Clients</Typography>
        </NavLink>

        <NavLink
          to="/contacts"
          style={{
            backgroundColor: location.pathname.includes("/contacts")
              ? "#34373A"
              : "",
          }}
        >
          <Typography variant="body1">Contacts</Typography>
        </NavLink>

        {window.location.host !== "cbgw.daffodil-apps.co.uk" &&
          window.location.host !== "app.concordeprojectportal.co.uk" && (
            <Typography variant="h5" style={{ marginLeft: "20px" }}>
              DEVELOPMENT BUILD.
            </Typography>
          )}

        <div
          style={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {user && user.role_id > 1 && (
            <Link to="/admin-settings">
              <Tooltip title="Settings" placement="bottom">
                <IconButton color="primary">
                  <Settings
                    style={{
                      transform: "scale(1.4)",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Link>
          )}
          <Dropdown anchor={"right"}>
            <NavBox
              dropdownTrigger
              style={{
                cursor: "pointer",
              }}
            >
              {user.avatar.length > 0 ? (
                <img
                  src={"data:image/png;base64," + user.avatar}
                  alt="avatar"
                  style={{ height: "29px", marginRight: "12px" }}
                />
              ) : (
                <img
                  src={avatar}
                  alt="avatar"
                  style={{ marginRight: "12px" }}
                />
              )}
              <Typography variant="body1">
                {user.first_name + " " + user.last_name}
              </Typography>

              <Icon path={mdiMenuDown} title="Dropdown" size="24px" />
            </NavBox>

            <NavMenuItem>
              <NavDropdownButton
                component={Link}
                to={"/user/" + user._id}
                style={{ justifyContent: "left" }}
              >
                <Settings
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  Your Account
                </Typography>
              </NavDropdownButton>
            </NavMenuItem>

            <NavMenuItem>
              <NavDropdownButton
                onClick={() => {
                  Cookies.remove("app");
                }}
                component={Link}
                to="/login"
                style={{ justifyContent: "left" }}
              >
                <Input
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
                <Typography variant="body1" style={{ marginLeft: "10px" }}>
                  Logout
                </Typography>
              </NavDropdownButton>
            </NavMenuItem>
          </Dropdown>
        </div>
      </Toolbar>
    </AppBar>
  );
}
