import React from "react";
import AuthenticatedRoute from "../utils/AuthenticatedRoute";

import People from "../pages/Contacts/People";

const ContactRoutes = [
  <AuthenticatedRoute
    path="/contacts"
    exact
    component={People}
    title="Clients"
  />,
];

export default ContactRoutes;
