import React, { useContext } from "react";
import gql from "graphql-tag";
import {
  Typography,
  Grid,
  Card,
  Box,
  IconButton,
  Tooltip,
} from "@material-ui/core";

import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { useMutation, useQuery } from "@apollo/client";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { GET_UPDATES, labelColour } from "../../../queries/Project";
import { format } from "date-fns";
import { formatDistanceToNow } from "date-fns";
import { openDialog } from "../../../reusable/ScrollablePopup";
import ProjectUpdatesModal from "../ProjectUpdatesModal";
import { UserContext } from "../../../utils/PageWrapper";
import { openSnackbar } from "../../../reusable/Notifier";
import { closeModal } from "../../../reusable/Popup";
import { primaryColor } from "../../../assets/ThemeStyling";

export const CREATE_UPDATE = gql`
  mutation addProjectUpdate(
    $project_job_no: Int!
    $project_mongo_id: ID!
    $the_update: String!
    $user_id: Int!
    $recipients: [String]
    $newRecipients: [String]
  ) {
    addProjectUpdate(
      project_job_no: $project_job_no
      project_mongo_id: $project_mongo_id
      the_update: $the_update
      user_id: $user_id
      recipients: $recipients
      newRecipients: $newRecipients
    ) {
      the_update
      date_time_added
      recipients
      userInfo {
        first_name
        last_name
      }
    }
  }
`;

export function ProjectUpdate({ update, length, i }) {
  return (
    <Box
      style={{
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
      }}
    >
      <Typography variant="body2" style={{ color: labelColour }}>
        {`${format(
          new Date(update.date_time_added),
          "dd/MM/yyyy"
        )} - ${formatDistanceToNow(new Date(update.date_time_added), {
          addSuffix: true,
        })}`}
      </Typography>

      <Typography
        variant="body1"
        style={{
          wordWrap: "break-word",
          marginTop: "8px",
          marginBottom: "8px",
          whiteSpace: "pre-wrap",
        }}
      >
        {update.the_update}
      </Typography>

      {!!update.recipients.length && (
        <Grid>
          <Typography variant="body2" style={{ color: labelColour }}>
            Additional Recipients:
          </Typography>
          {update.recipients.map((recipient) => (
            <Typography variant="body2" color="secondary">
              {recipient}
            </Typography>
          ))}
        </Grid>
      )}

      <Grid container justifyContent="flex-end">
        <Typography
          variant="body2"
          color="secondary"
          style={{ marginBottom: "8px" }}
        >{`${update.userInfo[0].first_name} ${update.userInfo[0].last_name}`}</Typography>
      </Grid>

      {length - 1 !== i && (
        <div
          style={{
            borderBottom: "1px solid #E7EAEF",
          }}
        ></div>
      )}
    </Box>
  );
}

export default function ProjectUpdates({ project }) {
  const user = useContext(UserContext);

  const {
    loading: updatesLoading,
    error: updatesError,
    data: updatesData,
  } = useQuery(GET_UPDATES, {
    fetchPolicy: "network-only", //if we don't do this then when we add an update from the summary tab it doesn't show up
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    variables: {
      project_job_no: project.job_no,
    },
  });
  // console.log("prj updates project", project);
  // console.log("updates", updatesData);

  const [
    createUpdate,
    {
      loading: createUpdateLoading,
      error: createUpdateError,
      data: createUpdateData,
    },
  ] = useMutation(CREATE_UPDATE, {
    refetchQueries: [
      {
        query: GET_UPDATES,

        variables: {
          project_job_no: project.job_no,
        },
      },
    ],
    onError: () => {
      openSnackbar({
        message: "Error adding update, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Update added successfully",
        type: "success",
      });
      closeModal();
    },
  });

  const handleSubmit = (values) => {
    createUpdate({
      variables: {
        project_job_no: project.job_no,
        project_mongo_id: project._id,
        the_update: values.the_update,
        user_id: user.user_id,
        recipients: values.recipients
          ? values.recipients
              .filter((recipient) => recipient._id)
              .map((recipient) => recipient.emailAddress)
          : [],
        newRecipients: values.recipients
          ? values.recipients.filter(
              (recipient) => typeof recipient === "string"
            )
          : [],
      },
    });
  };

  if (updatesError) {
    return <ErrorMessage message={updatesError} />;
  }

  if (updatesLoading || updatesData == null) {
    return <Loading />;
  }

  return (
    <Card
      style={{
        marginBottom: "24px",
        marginTop: "12px",
        padding: "0",
        width: "100%",
      }}
    >
      <Grid
        item
        xs
        style={{
          marginBottom: "24px",
          padding: "18px 24px",
          // borderBottom: "1px solid #E7EAEF",
          backgroundColor: "#4B4F54",
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          Project Updates
          <Tooltip title="Add" placement="top">
            <IconButton
              onClick={() => {
                openDialog({
                  content: <ProjectUpdatesModal handleSubmit={handleSubmit} />,
                });
              }}
            >
              <AddCircleIcon style={{ fill: primaryColor }} />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      {updatesData.getProjectUpdates.length > 0 ? (
        <>
          {[...updatesData.getProjectUpdates]
            .reverse()
            .map((update, i, { length }) => {
              return (
                <ProjectUpdate
                  key={update._id}
                  update={update}
                  length={length}
                  i={i}
                />
              );
            })}
        </>
      ) : (
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            textAlign: "center",
            padding: "1em",
          }}
        >
          No Updates
        </Typography>
      )}
    </Card>
  );
}
