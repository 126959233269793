import React from "react";

import MUIDataTable from "mui-datatables";
import moment from "moment";

import { createTheme } from "@material-ui/core";

import { ThemeProvider } from "@material-ui/styles";
import { detailsLabel } from "../../assets/GlobalLabels";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 200,
        },

        "&:nth-child(3)": {
          width: 200,
        },
      },
    },
  },
});

export default function ProjectActivityTable({ dates }) {
  const tableDataArray = [];

  dates.forEach((date) => {
    const data = [
      date.time,
      date.content,
      date.user?.first_name
        ? date.user.first_name + " " + date.user.last_name
        : "Unknown User",
    ];

    tableDataArray.push(data);
  });

  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: "Date/Time",
      direction: "desc",
    },
  };

  const columnsArray = [
    {
      name: "Date/Time",
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value) => {
          return moment(value, "x").format("DD/MM/YY - HH:mm");
        },
      },
    },
    {
      name: detailsLabel,
      options: {
        sort: true,
        display: true,
      },
    },
    {
      name: "User",
      options: {
        sort: true,
        display: true,
      },
    },
  ];

  return (
    <ThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        data={tableDataArray}
        columns={columnsArray}
        options={options}
      />
    </ThemeProvider>
  );
}
