import React, { useState } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useQuery } from "@apollo/client";

import { addMonths } from "date-fns";
import { Field, Form } from "react-final-form";
import {
  filterHidden,
  filterHiddenFromActivityReports,
  GET_USERS,
  sortByName,
} from "../../queries/User";
import { openSnackbar } from "../../reusable/Notifier";

import { Button, Grid, TextField } from "@material-ui/core";
import { Accordion, AccordionDetails } from "@mui/material";
import DatePicker from "../../uicomponents/DatePicker";
import MenuItem from "../../uicomponents/MenuItem";
import Typography from "../../uicomponents/Typography";

const GET_ACTIVITIES_CSV = gql`
  query getActivitiesDownloadUrl(
    $dateFrom: DateTime!
    $dateTo: DateTime!
    $user: String
  ) {
    getActivitiesDownloadUrl(dateFrom: $dateFrom, dateTo: $dateTo, user: $user)
  }
`;

export default function ActivitiesDownload() {
  const [expanded, setExpanded] = useState(false);

  const { loading: usersLoading, data: usersData } = useQuery(GET_USERS);

  const [getActivitiesDownloadUrl, { loading: activitiesCsvLoading }] =
    useLazyQuery(GET_ACTIVITIES_CSV, {
      onError: () => {
        openSnackbar({
          message: "Failed to get download, please try again.",
          type: "error",
        });
      },
      onCompleted: ({ getActivitiesDownloadUrl }) => {
        window.location.href = `${process.env.REACT_APP_API_URL}/download-csv/${getActivitiesDownloadUrl}`;
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    });

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      style={{
        marginLeft: "auto",
        backgroundColor: "inherit",
        border: "none",
      }}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
      elevation={0}
    >
      <div
        style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}
      >
        <Button
          variant="contained"
          style={{
            marginBottom: "1rem",
          }}
          color="primary"
          onClick={() => setExpanded(!expanded)}
        >
          download CSV
        </Button>
      </div>

      <AccordionDetails
        style={{
          padding: "0",
          border: "none",
        }}
      >
        <Form
          onSubmit={({ dateFrom, dateTo, user_id }, form) => {
            getActivitiesDownloadUrl({
              variables: {
                dateFrom,
                dateTo,
                user: user_id,
              },
            });

            form.reset();
          }}
          initialValues={{
            dateFrom: addMonths(new Date(), -1),
            dateTo: new Date(),
          }}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            values: { dateFrom },
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <DatePicker
                    label="From"
                    required
                    name="dateFrom"
                    showYearDropdown
                    maxDate={new Date()}
                  />
                </Grid>

                <Grid item xs={3}>
                  <DatePicker
                    label="To"
                    required
                    name="dateTo"
                    showYearDropdown
                    minDate={dateFrom}
                    maxDate={new Date()}
                  />
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="label">Select user</Typography>
                  <Field name="user_id">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label="User"
                        fullWidth
                        // required
                        variant="outlined"
                        style={{ backgroundColor: "white", marginTop: "7px" }}
                      >
                        <MenuItem value="">None</MenuItem>
                        {usersData.users
                          .filter(filterHidden)
                          .filter(filterHiddenFromActivityReports)
                          .sort(sortByName)
                          .map((user) => (
                            <MenuItem key={user._id} value={user._id}>
                              {`${user.first_name} ${user.last_name}`}
                            </MenuItem>
                          ))}
                      </TextField>
                    )}
                  </Field>
                </Grid>

                <Grid
                  item
                  xs={3}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={submitting}
                    loading={activitiesCsvLoading || usersLoading}
                  >
                    Download
                  </Button>
                </Grid>

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </Grid>
            </form>
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
}
