import React from "react";

import AuthenticatedRoute from "../utils/AuthenticatedRoute";

import User from "../pages/User/User";

const UserRoutes = [
  <AuthenticatedRoute
    path="/user/:userId"
    exact
    component={User}
    title="User"
  />,
];

export default UserRoutes;
