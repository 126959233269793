import React, { useState } from "react";

import {
  Typography,
  Grid,
  Breadcrumbs,
  Menu,
  IconButton,
} from "@material-ui/core";

import ProjectsSearch from "./ProjectsSearch";
import NewProjectModal from "./NewProjectModal";

import MenuItem from "../../uicomponents/MenuItem";

import { MoreVert } from "@material-ui/icons";
import { primaryColor } from "../../assets/ThemeStyling";

export default function Projects({ history }) {
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <Grid container spacing={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ fontSize: "14px", padding: "12px 12px 10px 12px " }}
      >
        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          Projects
        </Typography>

        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          Project Search
        </Typography>
      </Breadcrumbs>

      <Grid
        item
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        xs={12}
      >
        <Typography component="h1" variant="h4">
          Projects
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <NewProjectModal />
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVert style={{ fill: primaryColor }} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
          >
            
            <MenuItem
              onClick={() => {
                history.push("/projectsganttchart");
                setAnchorEl(null);
              }}
            >
              Project Gantt Charts
            </MenuItem>
          </Menu>
          
        </div>
      </Grid>

      <Grid item xs={12}>
        <ProjectsSearch />
      </Grid>
    </Grid>
  );
}
