import React from "react";

import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import ExistingCompanies from "./ExistingCompanies";

import Wizard from "../../uicomponents/Wizard";

import CompanyDetails from "./CompanyDetails";

import { openDialog } from "../../reusable/ScrollablePopup";
import ScopeOfWorks from "./ScopeOfWorks";
import NameLocation from "./NameLocation";
import InternalContacts from "./InternalContacts";
import ClientContacts from "./ClientContacts";

import Confirmation from "./Confirmation";
import { useStore } from "./WizPageStore";
import { WizardPage } from "../../uicomponents/WizardPage";

const NewProjectModal = () => {
  const reset = useStore((state) => state.reset);

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => {
        reset();
        openDialog({
          content: (
            <Wizard
              steps={[
                "Select Client",
                "Name & Location",
                "Scope of Works",
                "Internal Contacts",
                "Client Contacts",
                "Confirmation",
              ]}
            >
              <WizardPage>
                <ExistingCompanies />
              </WizardPage>

              <WizardPage>
                <CompanyDetails />
              </WizardPage>

              <WizardPage>
                <NameLocation />
              </WizardPage>

              <WizardPage>
                <ScopeOfWorks />
              </WizardPage>

              <WizardPage>
                <InternalContacts />
              </WizardPage>

              <WizardPage>
                <ClientContacts />
              </WizardPage>

              <WizardPage>
                <Confirmation />
              </WizardPage>
            </Wizard>
          ),
        });
      }}
    >
      <Add style={{ marginRight: "10px" }} />
      New Project
    </Button>
  );
};

export default NewProjectModal;
