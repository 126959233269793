import { createTheme } from "@material-ui/core/styles";

export const primaryColor = "#F1B434";
export const secondaryColor = "#BB4430";

const theme = createTheme({
  palette: {
    primary: { main: primaryColor, contrastText: "#ffffff" },
    secondary: { main: secondaryColor },
    text: {
      secondary: "#68727E",
    },
    action: {
      active: secondaryColor,
      focus: secondaryColor,
      selected: secondaryColor,
      // hover: "rgba(19,68,180,0.1)",
      // disabled: "#9B9B9B",
    },
  },
  shadows: ["none"],
  typography: { h5: { fontFamily: "Poppins, Roboto" } },

  overrides: {
    MuiCard: {
      root: {
        padding: "15px",
        border: "1px solid #DFE3EB",
        borderRadius: "4px",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "8px",
      },
    },
    MuiInputBase: {
      root: {
        "&$disabled": {
          backgroundColor: "rgba(35,44,152, 0.05)",
          borderRadius: "4px",
        },
      },
    },
    MuiMobileStepper: {
      root: {
        backgroundColor: "#fff",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiButton: {
      sizeSmall: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      label: {
        fontFamily: "Poppins, Helvetica, Arial, Sans-Serif",
        fontWeight: 500,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: "4px",
      },
    },
    MuiTypography: {
      h4: {
        fontFamily: "Poppins",
        fontWeight: 600,
      },
    },
    MuiTable: {
      root: {
        outline: "none",
      },
    },
    MuiDrawer: {
      paper: {
        overflowX: "hidden",
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },
    MUIDataTable: {
      shadows: ["none"],
      paper: {
        border: "1px solid #DFE3EB",
        borderRadius: "4px",
        padding: "24px",
      },
      responsiveScroll: {
        maxHeight: "auto",
      },
      responsiveScrollFullHeight: {
        zIndex: 1,
      },
    },
    MUIDataTableHeadCell: {
      root: {
        "&:first-child": {
          paddingLeft: "0",
        },
        "&:last-child": {
          paddingRight: "0",
        },
      },
    },
    MUIDataTableBodyCell: {
      root: {
        "&:first-child": {
          paddingLeft: "0",
        },
        "&:last-child": {
          paddingRight: "0",
        },
      },
    },
    MUIDataTablePagination: {
      tableCellContainer: {
        borderBottom: "none",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "white",
      },
      toolbarBtnSelected: {
        color: "white",
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: "white",
      },
    },
    MuiGridListTile: {
      root: {
        width: "auto !important",
      },
    },
    MuiSnackbarContent: {
      root: {
        padding: "0",
      },
      message: {
        padding: "0",
      },
    },
  },
});

export default theme;
