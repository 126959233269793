import React from "react";

import NewProjectDateWizardStep1 from "./NewProjectDateWizardStep1";

import NewProjectDateWizardStep2 from "./NewProjectDateWizardStep2";

const NewProjectDateModal = (props) => {
  return props.value ? (
    <NewProjectDateWizardStep2
      project_id={props.project_id}
      onAdded={props.onAdded}
      value={props.value}
      _id={props._id}
    />
  ) : (
    <NewProjectDateWizardStep1
      project_id={props.project_id}
      onAdded={props.onAdded}
    />
  );
};

export default NewProjectDateModal;
