import React, { Fragment, useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import MUIDataTable from "mui-datatables";

import { useMutation, useQuery } from "@apollo/client";
import { GET_CONTACTS, contactRoles } from "../../queries/Customer";
import Loading from "../../reusable/Loading";
import { openSnackbar } from "../../reusable/Notifier";

import {
  Grid,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
  MenuItem,
  createTheme,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { Delete } from "@material-ui/icons";
import { Field, Form, FormSpy, useForm, useFormState } from "react-final-form";
import { useStore } from "./WizPageStore";
import { Accordion } from "@material-ui/core";
import { AccordionSummary } from "@material-ui/core";
import { AccordionDetails } from "@material-ui/core";
import { CREATE_CONTACT } from "../../queries/Customer";
import PageSwitch from "./PageSwitch";
import { Box } from "@mui/system";
import { primaryColor, secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  contactNumberLabel,
  emailAddressLabel,
  nameLabel,
  roleLabel,
} from "../../assets/GlobalLabels";

const columnWidthTheme = createTheme({
  palette: {
    primary: { main: primaryColor, contrastText: "#ffffff" },
    secondary: { main: secondaryColor },
    text: {
      secondary: "#68727E",
    },
    action: {
      active: secondaryColor,
      focus: secondaryColor,
      selected: secondaryColor,
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 180,
        },
        "&:nth-child(2)": {
          width: 180,
        },
        "&:nth-child(3)": {
          width: 80,
        },
        "&:nth-child(4)": {
          width: 100,
        },
        "&:nth-child(5)": {
          width: 60,
        },
      },
    },
  },
});

const CustomFooter = (props) => {
  // const handleChange = (event, value) => {
  //   props.changePage(value);
  // };
  return (
    <></>
    // <Grid container justifyContent="center">
    //   <Pagination
    //     count={Math.floor(props.rowCount / 10)}
    //     page={props.page}
    //     onChange={handleChange}
    //     showFirstButton
    //     showLastButton
    //     style={{ marginTop: "12px" }}
    //   />
    // </Grid>
  );
};

<Field name="addressPostcode">
  {({ input, meta }) => (
    <TextField
      {...input}
      label="Postcode"
      fullWidth
      // required
      variant="outlined"
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : ""}
    />
  )}
</Field>;

// contact_name: String
// contact_email: String
// contact_position: String
// contact_number: String
// contact_company: Int

export default function ClientContacts() {
  const formState = useFormState();
  const formHook = useForm();

  const { loading, error, data } = useQuery(GET_CONTACTS, {
    variables: {
      contact_company: formState.values.companyId,
    },
  });

  const [
    newContact,
    {
      loading: newContactLoading,
      error: newContactError,
      data: newContactData,
    },
  ] = useMutation(CREATE_CONTACT, {
    refetchQueries: [
      {
        query: GET_CONTACTS,

        variables: {
          contact_company: formState.values.companyId,
        },
      },
    ],
    onError: () => {
      openSnackbar({
        message: "Error adding contact, please try again",
        type: "error",
      });
    },
    onCompleted: (data) => {
      openSnackbar({
        message: "Contact added successfully",
        type: "success",
      });
      if (data?.newContact) {
        data.newContact.contact_role = data.newContact.contact_position;
        formHook.mutators.push("projectContacts", data.newContact);
      }
    },
  });

  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // console.log("contacts data", data?.contacts);
  // console.log("newContactData", newContactData);
  // console.log("formState", formState);
  // console.log("formHook", formHook);

  // let view = (
  //   <Typography variant="body1" style={{ color: "grey" }}>
  //     Search for a company or create a new one
  //   </Typography>
  // );

  // if (loading) {
  //   view = <Loading></Loading>;
  // }

  // if (error) {
  //   view = (
  //     <Typography variant="body1">
  //       Failed to get companies, please try again.
  //     </Typography>
  //   );
  // }

  return (
    <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
      {({
        values,
        pristine,
        submitting,
        form: {
          mutators: { resetProjectSelectedValues2 },
        },
      }) => (
        <Box
          //main container
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            //content container
            container
            spacing={3}
            style={{ flex: "1 0 auto" }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                Project Contacts - Client
              </Typography>
            </Grid>

            {loading || newContactLoading ? (
              <Loading />
            ) : (
              <Grid item xs={12}>
                <FieldArray name="projectContacts">
                  {({ fields }) => (
                    <Grid container spacing={2}>
                      {!!data.contacts.length && (
                        <>
                          <Grid item xs={5}>
                            <Field name="selected_contact2">
                              {({ input, meta }) => (
                                <TextField
                                  {...input}
                                  select
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ""
                                  }
                                  label="Select Contact"
                                  fullWidth
                                  size="small"
                                  // required
                                  variant="outlined"
                                  // style={{ paddingBottom: "15px" }}
                                >
                                  {data.contacts?.map((contact) => (
                                    <MenuItem value={contact._id}>
                                      {contact.contact_name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                          </Grid>

                          <Grid item xs={5}>
                            <Field name="selected_role2">
                              {({ input, meta }) => (
                                <TextField
                                  {...input}
                                  select
                                  error={meta.error && meta.touched}
                                  helperText={
                                    meta.error && meta.touched ? meta.error : ""
                                  }
                                  label="Select Role"
                                  fullWidth
                                  size="small"
                                  // required
                                  variant="outlined"
                                  // style={{ paddingBottom: "15px" }}
                                >
                                  {contactRoles.map((role) => (
                                    <MenuItem value={role}>{role}</MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Field>
                          </Grid>

                          <Grid
                            item
                            xs={2}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Add" placement="top">
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={
                                  !data.contacts?.length ||
                                  !values.selected_contact2 ||
                                  !values.selected_role2
                                }
                                onClick={() => {
                                  fields.push({
                                    ...data.contacts?.find(
                                      (contact) =>
                                        contact._id === values.selected_contact2
                                    ),
                                    contact_role: values.selected_role2,
                                  });
                                  resetProjectSelectedValues2();
                                }}
                              >
                                Add
                              </Button>
                            </Tooltip>
                          </Grid>
                        </>
                      )}

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChange("panel1")}
                        >
                          <Tooltip title="Add" placement="top">
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                height: "40px",
                              }}
                              // onClick={() => {}}
                            >
                              <AccordionSummary
                                // expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                create new contact
                              </AccordionSummary>
                            </Button>
                          </Tooltip>

                          <AccordionDetails
                            style={{
                              padding: "0",
                              paddingTop: "16px",
                            }}
                          >
                            <Form
                              onSubmit={() => {}}
                              initialValues={{}}
                              validate={(values) => {
                                const errors = {};

                                if (!values.new_contact_first_name) {
                                  errors.new_contact_first_name = "Required";
                                }
                                if (!values.new_contact_last_name) {
                                  errors.new_contact_last_name = "Required";
                                }
                                if (!values.new_contact_job_role) {
                                  errors.new_contact_job_role = "Required";
                                }

                                return errors;
                              }}
                              render={({
                                handleSubmit,
                                form,
                                submitting,
                                pristine,
                                values: innerFormValues,
                                valid,
                              }) => {
                                return (
                                  <form id="myForm" onSubmit={handleSubmit}>
                                    {
                                      <>
                                        <Grid container spacing={3}>
                                          <Grid item xs={6}>
                                            <Field name="new_contact_first_name">
                                              {({ input, meta }) => (
                                                <TextField
                                                  {...input}
                                                  label="First Name"
                                                  fullWidth
                                                  size="small"
                                                  required
                                                  variant="outlined"
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  helperText={
                                                    meta.error && meta.touched
                                                      ? meta.error
                                                      : ""
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </Grid>

                                          <Grid item xs={6}>
                                            <Field name="new_contact_last_name">
                                              {({ input, meta }) => (
                                                <TextField
                                                  {...input}
                                                  label="Last Name"
                                                  fullWidth
                                                  size="small"
                                                  required
                                                  variant="outlined"
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  helperText={
                                                    meta.error && meta.touched
                                                      ? meta.error
                                                      : ""
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </Grid>

                                          <Grid item xs={6}>
                                            <Field name="new_contact_job_role">
                                              {({ input, meta }) => (
                                                <TextField
                                                  {...input}
                                                  label="Job Role"
                                                  fullWidth
                                                  size="small"
                                                  required
                                                  variant="outlined"
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  helperText={
                                                    meta.error && meta.touched
                                                      ? meta.error
                                                      : ""
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </Grid>

                                          <Grid item xs={6}>
                                            <Field name="new_contact_contact_number">
                                              {({ input, meta }) => (
                                                <TextField
                                                  {...input}
                                                  label={contactNumberLabel}
                                                  fullWidth
                                                  size="small"
                                                  // required
                                                  variant="outlined"
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  helperText={
                                                    meta.error && meta.touched
                                                      ? meta.error
                                                      : ""
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </Grid>

                                          <Grid item xs={6}>
                                            <Field name="new_contact_contact_email">
                                              {({ input, meta }) => (
                                                <TextField
                                                  {...input}
                                                  label={emailAddressLabel}
                                                  fullWidth
                                                  size="small"
                                                  // required
                                                  variant="outlined"
                                                  error={
                                                    meta.error && meta.touched
                                                  }
                                                  helperText={
                                                    meta.error && meta.touched
                                                      ? meta.error
                                                      : ""
                                                  }
                                                />
                                              )}
                                            </Field>
                                          </Grid>

                                          <Grid
                                            item
                                            xs={6}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Button
                                              variant="contained"
                                              color="primary"
                                              onClick={() => {
                                                if (valid) {
                                                  newContact({
                                                    variables: {
                                                      contact_name: `${innerFormValues.new_contact_first_name} ${innerFormValues.new_contact_last_name}`,
                                                      contact_email:
                                                        innerFormValues.new_contact_contact_email,
                                                      contact_position:
                                                        innerFormValues.new_contact_job_role,
                                                      contact_number:
                                                        innerFormValues.new_contact_contact_number,
                                                      contact_company:
                                                        values.companyId,
                                                    },
                                                  });

                                                  form.reset();
                                                }
                                              }}
                                              // disabled={
                                              //   submitting ||
                                              //   (page === 1 && !values.companyId) ||
                                              //   (page === 0 && !values.companyId)
                                              // }
                                              type="submit"
                                            >
                                              create
                                            </Button>

                                            <Button
                                              // disabled={true}
                                              style={{ marginLeft: "24px" }}
                                              onClick={() => {
                                                form.reset();
                                                setExpanded(false);
                                              }}
                                              variant="outlined"
                                            >
                                              cancel
                                            </Button>
                                          </Grid>
                                        </Grid>

                                        <pre>
                                          {/* {JSON.stringify(innerFormValues, 0, 2)} */}
                                        </pre>
                                      </>
                                    }
                                  </form>
                                );
                              }}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>

                      <Fragment>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              minHeight: data.contacts ? "1px" : "350px",
                            }}
                          >
                            <div style={{ width: "100%", height: "100%" }}>
                              {values.projectContacts &&
                              values.projectContacts[0] ? (
                                <ThemeProvider theme={columnWidthTheme}>
                                  <MUIDataTable
                                    // title="Existing Contacts"

                                    components={{
                                      TableFooter: CustomFooter,
                                    }}
                                    data={fields.map((name, index) => {
                                      // console.log("contact", name);
                                      const remove = (
                                        <Tooltip
                                          title="Remove"
                                          placement="top"
                                          style={{
                                            display: "flex",

                                            justifyContent: "center",
                                          }}
                                        >
                                          <IconButton
                                            aria-label="Remove"
                                            onClick={() => fields.remove(index)}
                                          >
                                            <Delete />
                                          </IconButton>
                                        </Tooltip>
                                      );

                                      return [
                                        values.projectContacts[index]
                                          ?.contact_name,
                                        values.projectContacts[index]
                                          ?.contact_role,
                                        values.projectContacts[index]
                                          ?.contact_number,
                                        values.projectContacts[index]
                                          ?.contact_email,

                                        remove,
                                      ];
                                    })}
                                    columns={[
                                      nameLabel,
                                      roleLabel,
                                      contactNumberLabel,
                                      emailAddressLabel,
                                      actionsLabel,
                                    ]}
                                    options={{
                                      selectableRows: "none",
                                      print: false,
                                      download: false,
                                      responsive: "standard",
                                      search: false,
                                      filter: false,
                                      elevation: 0,
                                      viewColumns: false,
                                    }}
                                  />{" "}
                                </ThemeProvider>
                              ) : (
                                <Typography
                                  variant="body1"
                                  style={{
                                    color: "grey",
                                    marginLeft: "12px",
                                    marginTop: "12px",
                                  }}
                                >
                                  Please add a contact.
                                </Typography>
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Fragment>
                    </Grid>
                  )}
                </FieldArray>
              </Grid>
            )}
          </Grid>

          <PageSwitch disabled={submitting} onClick={next} />
        </Box>
      )}
    </FormSpy>
  );
}
