import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { GET_COMPANY, UPDATE_COMPANY } from "../../queries/Customer";
import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import { useMutation, useQuery } from "@apollo/client";
import { closeDialog } from "../../reusable/ScrollablePopup";
import { openSnackbar } from "../../reusable/Notifier";
import { clientNameLabel } from "../../assets/GlobalLabels";

const handleSubmit = () => {};

const EditClientModal = ({ clientId }) => {
  const [updateCompany, { updateLoading, updateError, updateData }] =
    useMutation(UPDATE_COMPANY, {
      onCompleted: () => {
        openSnackbar({
          message: "Client has been updated.",
          type: "success",
        });
        closeDialog();
      },
      onError: (error) => {
        console.log(error);
        openSnackbar({
          message: "Failed to update the client.",
          type: "error",
        });
      },
    });

  const { loading, error, data } = useQuery(GET_COMPANY, {
    variables: { companyId: clientId },
  });

  if (loading || data == null) {
    return <Loading />;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <Card
      style={{
        padding: "0",
        border: "none",
        boxShadow: "none",
        borderRadius: "8px",
      }}
    >
      <Form
        initialValues={{
          ...data.getCompany,
          archived: data.getCompany.status_text === "Archived",
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          submitting,
          values,
          // form: {
          //   mutators: { push, pop },
          // },
        }) => (
          <>
            <Grid
              container
              spacing={3}
              justify="center"
              style={{ padding: "2em" }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    paddingLeft: "0.5em",
                  }}
                >
                  Edit Client
                </Typography>
              </div>
              <Grid item xs={12}>
                <Field name="name">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label={clientNameLabel}
                      fullWidth
                      required={true}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Field name="date_acquired">
                    {({ input, meta }) => (
                      <DatePicker
                        format="DD/MM/YYYY"
                        name={input.name}
                        onChange={input.onChange}
                        value={input.value ? input.value : null}
                        onBlur={input.onBlur}
                        fullWidth
                        required
                        disablePast={false}
                        autoOk={true}
                        disabled={false}
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                        label="Date Acquired"
                        leftArrowIcon={<KeyboardArrowLeft />}
                        inputVariant="outlined"
                        rightArrowIcon={<KeyboardArrowRight />}
                      />
                    )}
                  </Field>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12}>
                <Field name="archived" type="checkbox">
                  {({ input }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: "archived" }}
                          {...input}
                          value="archived"
                        />
                      }
                      label="Archived"
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <Divider
                  style={{
                    // marginTop: "25px",
                    marginBottom: "12px",
                    marginLeft: "-12px",
                    marginRight: "-24px",
                    backgroundColor: "#C3CAD8",
                  }}
                />
              </Grid>
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "13px",
                  marginBottom: "12px",
                  paddingLeft: "0.75em",
                  paddingRight: "0.75em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    values._id = clientId;
                    values.status_text = values.archived
                      ? "Archived"
                      : "Active";
                    updateCompany({ variables: values });
                  }}
                  disabled={
                    submitting ||
                    values.name == null ||
                    values.name.length === 0
                  }
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </Card>
  );
};

export default EditClientModal;
