import React from "react";

import {
  Button,
  Grid,
  Card,
  Divider,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { labelColour } from "../../queries/Project";
import { Field, Form } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={({ target: { value } }) => input.onChange(value)}
    errorText={meta.touched ? meta.error : ""}
    fullWidth
    variant="outlined"
    color="secondary"
  />
);

const ProjectScopeModal = (props) => {
  return (
    <Form initialValues={props.project} onSubmit={() => {}}>
      {({
        handleSubmit,
        submitting,
        values,
        // form: {
        //   mutators: { push, pop },
        // },
      }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Project Scope
            </Typography>
            <Typography
              variant="body1"
              style={{ color: labelColour, fontSize: "14px" }}
            >
              Select all Services Concorde BGW are responsible for
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Field name="services_design" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "services_design" }}
                      {...input}
                      value="services_design"
                    />
                  }
                  label="Design"
                />
              )}
            </Field>

            <Field name="services_qs" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "services_qs" }}
                      {...input}
                      value="services_qs"
                    />
                  }
                  label="Project QS"
                />
              )}
            </Field>

            <Field name="services_ff" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "services_ff" }}
                      {...input}
                      value="services_ff"
                    />
                  }
                  label={"F&F"}
                />
              )}
            </Field>
            <Field name="services_build" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "services_build" }}
                      {...input}
                      value="services_build"
                    />
                  }
                  label="Build"
                />
              )}
            </Field>

            <Field name="services_hs" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ name: "services_hs" }}
                      {...input}
                      value="services_hs"
                    />
                  }
                  label={"Health & Safety"}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field
              name="scope_of_works"
              component={TextFieldAdapter}
              multiline
              minRows={16}
              label="Scope of works"
              placeholder="Please outline the scope of works"
            />
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                props.onSaveClicked(values);
              }}
              variant="contained"
              color="primary"
              disabled={
                submitting ||
                values.scope_of_works == null ||
                values.scope_of_works.length === 0
              }
              type="submit"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
};

export default ProjectScopeModal;
