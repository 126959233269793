import { Delete } from "@material-ui/icons";
import arrayMutators from "final-form-arrays";
import React from "react";
import NumberFormat from "react-number-format";
import { FieldArray } from "react-final-form-arrays";
import { Field, Form } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";
import {
  Grid,
  Divider,
  Typography,
  Button,
  IconButton,
  TextField,
  Tooltip,
  InputAdornment,
} from "@material-ui/core";
import { TextFieldAdapter } from "./NewProjectDateWizardStep2";
import { designFeesLabel } from "../../assets/GlobalLabels";
export default function DesignCostsWizardStep1({ project, onSaveClicked }) {
  return (
    <Form mutators={arrayMutators} initialValues={project} onSubmit={() => {}}>
      {({
        handleSubmit,
        submitting,
        values,
        form: {
          mutators: { push, pop },
        },
      }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Design Costs
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Field name="design_budget">
              {({ input, meta }) => (
                <NumberFormat
                  {...input}
                  onFocus={(event) => {
                    event.target.select();
                    input.onFocus(event);
                  }}
                  thousandSeparator={true}
                  customInput={TextField}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    ),
                  }}
                  label={designFeesLabel}
                  displayType="tel"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <FieldArray name="ffEntries">
            {({ fields }) => (
              <>
                {fields.map((entry, index) => {
                  return (
                    <Grid key={index} item xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <Field
                            value={entry.name}
                            name={"ffEntries[" + index + "].name"}
                            component={TextFieldAdapter}
                            label="F&F Cost Name"
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Field name={"ffEntries[" + index + "].value"}>
                            {({ input, meta }) => (
                              <NumberFormat
                                {...input}
                                onFocus={(event) => {
                                  event.target.select();
                                  input.onFocus(event);
                                }}
                                thousandSeparator={true}
                                value={values.ffEntries[index].value}
                                customInput={TextField}
                                variant="outlined"
                                fullWidth
                                margin="dense"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      £
                                    </InputAdornment>
                                  ),
                                }}
                                label="Value"
                                displayType="tel"
                                decimalScale={2}
                                fixedDecimalScale
                                required
                                error={meta.error && meta.touched}
                                helperText={
                                  meta.error && meta.touched ? meta.error : ""
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={1}>
                          <Tooltip
                            title="Remove"
                            placement="top"
                            style={{
                              display: "flex",

                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              aria-label="Remove"
                              onClick={() => fields.remove(index)}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      fields.push({ name: "", value: 0 });
                    }}
                  >
                    Add F&amp;F Cost
                  </Button>
                </Grid>
              </>
            )}
          </FieldArray>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={submitting || values.design_budget == null}
              type="submit"
              onClick={() => {
                if (typeof values.design_budget === "string") {
                  values.design_budget = parseFloat(
                    values.design_budget.replace(/,/g, "")
                  );
                }
                if (values.ffEntries != null) {
                  for (let i = 0; i < values.ffEntries.length; i++) {
                    if (typeof values.ffEntries[i].value === "string") {
                      values.ffEntries[i].value = parseFloat(
                        values.ffEntries[i].value.replace(/,/g, "")
                      );
                    }
                  }
                }
                onSaveClicked(values);
              }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
}
