import React, { useState, Fragment } from "react";

import MUIDataTable from "mui-datatables";

import gql from "graphql-tag";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  createTheme,
} from "@material-ui/core";
import { openSnackbar } from "../../reusable/Notifier";
import { MoreVert } from "@material-ui/icons";
import { useMutation } from "@apollo/client";

import { ThemeProvider } from "@material-ui/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  contactNumberLabel,
  emailAddressLabel,
  leadLabel,
  nameLabel,
  projectRoleLabel,
} from "../../assets/GlobalLabels";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 200,
        },
        "&:nth-child(2)": {
          width: 50,
        },
        "&:nth-child(3)": {
          width: 140,
        },
        "&:nth-child(4)": {
          width: 140,
        },
        "&:nth-child(5)": {
          width: 140,
        },
        "&:nth-child(6)": {
          width: 50,
        },
      },
    },
  },
});

// const theme = createMuiTheme({
//   overrides: {
//     MuiTable: {
//       root: {
//         border: [[0, "solid", "#d3d3d3"]],
//       },
//     },
//   },
// });

const REMOVE_PROJECT_USER = gql`
  mutation removeProjectUser($_id: String!) {
    removeProjectUser(_id: $_id)
  }
`;

export default function ProjectUsersTable({ dates, onRemoved }) {
  const [
    removeProjectUser,
    {
      loading: removeProjectDateLoading,
      error: removeProjectDateError,
      data: removeProjectDateData,
    },
  ] = useMutation(REMOVE_PROJECT_USER, {
    onError: () => {
      openSnackbar({
        message: "Error removing user, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "User removed successfully",
        type: "success",
      });
      onRemoved();
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const tableDataArray = [];

  dates.forEach((date) => {
    const data = [
      date.user.first_name + " " + date.user.last_name,

      date.lead,
      date.role,
      date.user.mobile,

      date.user.email,

      date._id,
    ];

    tableDataArray.push(data);
  });

  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: emailAddressLabel,
      direction: "asc",
    },
  };

  const columnsArray = [
    {
      name: nameLabel,
    },
    {
      name: leadLabel,
      options: {
        customBodyRender: (value) => {
          if (value) {
            return <TaskAltIcon style={{ color: secondaryColor }} />;
          } else {
            return "";
          }
        },
      },
    },
    {
      name: projectRoleLabel,
    },
    {
      name: contactNumberLabel,
    },
    {
      name: emailAddressLabel,
      options: {
        sort: true,
        display: "true",
        customBodyRender: (value) => {
          return (
            <a style={{ color: secondaryColor }} href={`mailto:${value}`}>
              {value}
            </a>
          );
        },
      },
    },
    {
      name: actionsLabel,
      options: {
        sort: false,
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Tooltip title={actionsLabel} placement="top">
                <IconButton
                  data-index={dataIndex}
                  aria-label="View"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        data={tableDataArray}
        columns={columnsArray}
        options={options}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            const value =
              tableDataArray[anchorEl.dataset.index][
                tableDataArray[anchorEl.dataset.index].length - 1
              ];
            if (window.confirm("Are you sure you want to remove this user?")) {
              removeProjectUser({
                variables: {
                  _id: value,
                },
              });
            }
            setAnchorEl(null);
          }}
        >
          Remove Staff
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}
