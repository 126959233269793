import React from "react";
import { Field, Form } from "react-final-form";
import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from "@material-ui/core";

import { Loading } from "@web-applications/daffodil-component-library";
import { useMutation, useQuery } from "@apollo/client";
import { closeDialog } from "../../reusable/ScrollablePopup";
import { openSnackbar } from "../../reusable/Notifier";
import { GET_COMPANIES, CREATE_CONTACT } from "../../queries/Customer";
import { emailLabel, nameLabel } from "../../assets/GlobalLabels";
const handleSubmit = () => {};
const NewClientModal = ({ clientId }) => {
  const {
    loading: getCustomersLoading,
    error: getCustomersError,
    data,
  } = useQuery(GET_COMPANIES);

  const [
    newContact,
    {
      loading: newContactLoading,
      error: newContactError,
      data: newContactData,
    },
  ] = useMutation(CREATE_CONTACT, {
    onError: () => {
      openSnackbar({
        message: "Error adding contact, please try again",
        type: "error",
      });
    },
    onCompleted: (data) => {
      openSnackbar({
        message: "Contact added successfully",
        type: "success",
      });
      closeDialog();
    },
  });

  if (data == null || getCustomersLoading == true) {
    return <Loading />;
  }

  let companies = [...data.companies].sort((a, b) => {
    return a?.name?.localeCompare(b?.name);
  });

  return (
    <Card
      style={{
        padding: "0",
        border: "none",
        boxShadow: "none",
        borderRadius: "8px",
      }}
    >
      <Form initialValues={{}} onSubmit={handleSubmit}>
        {({
          handleSubmit,
          submitting,
          values,
          // form: {
          //   mutators: { push, pop },
          // },
        }) => (
          <>
            <Grid
              container
              spacing={3}
              justify="center"
              style={{ padding: "2em" }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    paddingLeft: "0.5em",
                  }}
                >
                  New Contact
                </Typography>
              </div>
              <Grid item xs={12}>
                <Field name="contact_company">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                      label="Select Client"
                      fullWidth
                      size="small"
                      // required
                      variant="outlined"
                      // style={{ paddingBottom: "15px" }}
                    >
                      {companies.map((company) => (
                        <MenuItem key={company._id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="contact_name">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label={nameLabel}
                      fullWidth
                      required={true}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field name="contact_email">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label={emailLabel}
                      fullWidth
                      required={false}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field name="contact_number">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label="Number"
                      fullWidth
                      required={false}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12}>
                <Field name="contact_position">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label="Position"
                      fullWidth
                      required={false}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <Divider
                  style={{
                    // marginTop: "25px",
                    marginBottom: "12px",
                    marginLeft: "-12px",
                    marginRight: "-24px",
                    backgroundColor: "#C3CAD8",
                  }}
                />
              </Grid>
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "13px",
                  marginBottom: "12px",
                  paddingLeft: "0.75em",
                  paddingRight: "0.75em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    values.contact_company =
                      parseInt(values.contact_company) + "";
                    newContact({ variables: values });
                  }}
                  disabled={
                    submitting ||
                    values.contact_name == null ||
                    values.contact_name.length === 0
                  }
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </Card>
  );
};

export default NewClientModal;
