import React from "react";

import AuthenticatedRoute from "../utils/AuthenticatedRoute";

import Home from "../pages/Dashboard/Home";

const HomeRoutes = [
  <AuthenticatedRoute path="/" exact component={Home} title="Dashboard" />,
];

export default HomeRoutes;
