import React from "react";
import { emailValidation, labelColour } from "../../queries/Project";
import {
  Button,
  Grid,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";

import { Field, Form } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import AsynchronousAutocomplete from "../../uicomponents/Autocomplete";

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={({ target: { value } }) => input.onChange(value)}
    errorText={meta.touched ? meta.error : ""}
    fullWidth
    variant="outlined"
    color="secondary"
  />
);

const ProjectUpdatesModal = (props) => {
  return (
    <Form
      onSubmit={(values, form) => {
        props.handleSubmit(values);
        form.reset();
        closeDialog();
      }}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push, pop },
        },
        submitting,
        values,
        meta,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{
              padding: "12px",
              margin: "0px",
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                Project Update
              </Typography>
              <Typography
                variant="body1"
                style={{ color: labelColour, fontSize: "14px" }}
              >
                Add a project update
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="the_update"
                component={TextFieldAdapter}
                multiline
                minRows={16}
                label="Update text"
                placeholder="Please outline the project update"
              />
            </Grid>

            <Grid item xs={12}>
              <div
                className="buttons"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "1.4rem",
                }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  style={{
                    fontWeight: 600,
                    fontFamily: "Poppins",
                  }}
                >
                  Additional Recipients
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => push("recipients", undefined)}
                  type="button"
                >
                  Add Recipient
                </Button>
              </div>
              <FieldArray name="recipients">
                {({ fields }) =>
                  fields.map((recipient, index) => (
                    <div
                      key={recipient}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "1rem",
                      }}
                    >
                      <Field
                        name={`${recipient}`}
                        component={AsynchronousAutocomplete}
                        validate={emailValidation}
                        label="Recipient Email"
                        placeholder="Email"
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => fields.remove(index)}
                        type="button"
                        size="small"
                        style={{
                          marginLeft: "1rem",
                          maxHeight: "40px",
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))
                }
              </FieldArray>
            </Grid>

            <Grid item xs={12}>
              <Divider
                style={{
                  marginLeft: "-24px",
                  marginRight: "-24px",
                  backgroundColor: "#C3CAD8",
                }}
              />
            </Grid>

            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  closeDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={submitting || !values.the_update}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>

          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
  );
};

export default ProjectUpdatesModal;
