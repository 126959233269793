import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  TextField,
  Divider,
} from "@material-ui/core";
import arrayMutators from "final-form-arrays";
import { Delete } from "@material-ui/icons";
import { closeDialog } from "../../reusable/ScrollablePopup";
import { Form, Field, useField, FormSpy } from "react-final-form";
import FlexBox from "../../uicomponents/FlexBox";
import CloseIcon from "@material-ui/icons/Close";
import { FieldArray } from "react-final-form-arrays";
import { useMutation } from "@apollo/client";
import {
  CREATE_MATTERPORT_LINK,
  UPDATE_MATTERPORT_LINK,
} from "../../queries/MatterportLinks";
import { openSnackbar } from "../../reusable/Notifier";
import { formatMatteportLinks } from "./ProjectMatterportLinksTable";
import transformMatterportlink from "./transformMatterportlink";
import { isRequiredArgument } from "graphql";

export default function MatterPortLinkModal({
  tableData,
  project_id,
  currentLink,
  setTableData,
}) {
  //set initial values to empty strings for create modal
  const [initialValues, setInitialValues] = useState({
    matterportLinks: [{ name: "", link: "" }],
  });

  //create Matterport Link******
  const [createMatterportLink] = useMutation(CREATE_MATTERPORT_LINK, {
    onError: (error) => {
      openSnackbar({
        message: `Error: Failed to create links: ${error.message}`,
        type: "error",
      });
    },
    onCompleted: (data) => {
      //update the tabledate with the return value of the resolver, to instantly update UI
      const updatedLinks = data.createMatterportLink;
      setTableData(formatMatteportLinks(updatedLinks));
      openSnackbar({
        message: "Success: Link(s) Added",
        type: "success",
      });
      closeDialog();
    },
  });

  //prepare values and call create mutation
  const handleCreateMatterportLink = async (values) => {
    try {
      //use values from formArray
      const links = values.matterportLinks.map((link) => {
        const imageUrl = transformMatterportlink(link.link);
        if (!imageUrl) {
          openSnackbar({
            message: "Invalid URL format. Must be a valid Matterport Project",
            type: "error",
          });
        }

        return {
          project_id,
          name: link.name,
          link: link.link,
          image: transformMatterportlink(link.link),
        };
      });
      await Promise.all(
        links.map((link) => createMatterportLink({ variables: link }))
      );
    } catch (error) {
      openSnackbar({
        message: `Error: ${error.message}`,
        type: "error",
      });
    }
  };
  //END****create Matterport Link******END
  //update Matterport Link
  //set Values to be edited
  useEffect(() => {
    if (currentLink) {
      setInitialValues({
        matterportLinks: [
          {
            name: currentLink.name,
            link: currentLink.link,
          },
        ],
      });
    }
  }, [currentLink]);

  const [updateMatterportLink] = useMutation(UPDATE_MATTERPORT_LINK, {
    onError: (error) => {
      openSnackbar({
        message: `Error: ${error.message}`,
        type: "error",
      });
    },
    onCompleted: (data) => {
      const updatedLinks = data.updateMatterportLink;
      setTableData(formatMatteportLinks(updatedLinks));
      openSnackbar({
        message: "Success: Link Updated",
        type: "success",
      });
      closeDialog();
    },
  });

  const handleUpdateMatterportLink = async (values) => {
    try {
      const formData = values.matterportLinks[0];
      const updateSubmissionData = {
        project_id: currentLink.project_id,
        _id: currentLink._id,
        name: formData.name,
        link: formData.link,
        image: transformMatterportlink(formData.link),
      };
      updateMatterportLink({
        variables: updateSubmissionData,
      });
    } catch (error) {
      openSnackbar({
        message: `Error: ${error.message}`,
        type: "error",
      });
    }
  };

  //determine the onsubmit funtion
  function determineSubmission(values) {
    if (currentLink) {
      handleUpdateMatterportLink(values);
    } else {
      handleCreateMatterportLink(values);
    }
  }

  //front end validation
  const validate = (values, tableData) => {
    const errors = { matterportLinks: [] };

    values.matterportLinks.forEach((link, index) => {
      const linkErrors = {};
      //the presence of current link means we are in edit, here we rely on backend validation
      if (!currentLink) {
        if (tableData.some((columnData) => columnData.name === link.name)) {
          linkErrors.name = "Duplicate Name";
        }

        if (tableData.some((columnData) => columnData.link === link.link)) {
          linkErrors.link = "Duplicate Link";
        }
      }
      if (!link.link) {
        linkErrors.link = "Required";
      }

      if (!link.name) {
        linkErrors.name = "Required";
      }

      if (Object.keys(linkErrors).length > 0) {
        errors.matterportLinks[index] = linkErrors;
      }
    });
    console.log(errors);
    return errors;
  };

  return (
    <Card
      style={{
        padding: "2rem",
        margin: "0px",
        width: "100%",
        overflowY: "auto",
        maxWidth: "800px",
      }}
    >
      <Grid container spacing={3}>
        <FlexBox
          justifyContent="space-between"
          style={{ margin: "1rem", width: "100%" }}
        >
          {!currentLink ? (
            <Typography variant="h4">Add Links</Typography>
          ) : (
            <Typography variant="h4">Edit Link</Typography>
          )}
          <CloseIcon
            style={{
              color: "black",
              cursor: "pointer",
            }}
            onClick={() => {
              closeDialog();
            }}
          />
        </FlexBox>
      </Grid>
      <Grid item xs={12}>
        <Divider
          style={{
            marginTop: "1rem",
            marginBottom: "2rem",
            marginLeft: "-24px",
            marginRight: "24px",
            backgroundColor: "#C3CAD8",
          }}
        />
      </Grid>
      <Form
        initialValues={initialValues}
        validate={(values) => validate(values, tableData)}
        onSubmit={determineSubmission} //create or update decison based on props
        mutators={{ ...arrayMutators }}
        render={({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <FieldArray name="matterportLinks">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => {
                            return (
                              <Grid key={index} item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={11} md={4}>
                                    <Field
                                      name={`${name}.name`}
                                      component={"input"}
                                    >
                                      {({ input, meta }) => (
                                        <TextField
                                          {...input}
                                          label="Friendly Name"
                                          variant="outlined"
                                          style={{
                                            width: "100%",
                                          }}
                                          error={meta.error && meta.touched}
                                          helperText={
                                            meta.error && meta.touched
                                              ? meta.error
                                              : ""
                                          }
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={11} md={7}>
                                    <Field
                                      name={`${name}.link`}
                                      component={"input"}
                                    >
                                      {({ input, meta }) => (
                                        <TextField
                                          {...input}
                                          label="Matterport Link"
                                          variant="outlined"
                                          style={{
                                            width: "100%",
                                          }}
                                          error={meta.error && meta.touched}
                                          helperText={
                                            meta.error && meta.touched
                                              ? meta.error
                                              : ""
                                          }
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  {index !== 0 && (
                                    <Grid item xs={1}>
                                      <Tooltip
                                        title="Remove"
                                        placement="top"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        onClick={() => {
                                          fields.remove(index);
                                        }}
                                      >
                                        <IconButton aria-label="Remove">
                                          <Delete />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                  <Grid item xs={12}>
                                    {fields.length > 1 && (
                                      <Divider
                                        style={{
                                          marginTop: "1rem",
                                          marginBottom: "1rem",
                                          marginLeft: "-24px",
                                          marginRight: "24px",
                                          backgroundColor: "#C3CAD8",
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                          {!currentLink && (
                            <Grid item xs={12}>
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => {
                                  fields.push({
                                    name: "",
                                    link: "",
                                  });
                                }}
                              >
                                Add Another
                              </Button>
                            </Grid>
                          )}
                        </>
                      )}
                    </FieldArray>
                    <Grid
                      item
                      xs
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        paddingLeft: "12px",
                        paddingRight: "12px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          closeDialog();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            </form>
          );
        }}
      />
    </Card>
  );
}
