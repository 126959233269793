import React from 'react'
import { rgba } from 'polished'
import styled from 'styled-components'

const StyledTypography = styled.div`
  display: ${({ as }) => (as === 'a' ? 'inline-block' : 'block')};
  font-family: ${({ variant }) => {
    //calculate font family to use
    switch (variant) {
      case 'h1':
      case 'h2':
      case 'bodySmall':
      case 'label':
      case 'caption':
      case 'button':
      default:
        return 'Roboto'
    }
  }};
  color: ${({ colour = 'black', variant, as }) => {
    if (as === 'a') return '#F59300'
    if (variant === 'label') return rgba(colour, 0.54)

    return colour
  }};
  margin: 0;
  text-align: ${({ center }) => {
    if (center) return 'center'
  }};
  text-decoration: none;
  font-size: ${({ variant }) => {
    //calculate font family to use
    switch (variant) {
      case 'h1':
        return '24px'

      case 'h2':
        return '20px'

      case 'bodySmall':
      default:
        return '16px'

      case 'label':
      case 'caption':
      case 'button':
        return '14px'
    }
  }};
  font-weight: ${({ variant, bold }) => {
    switch (variant) {
      case 'h1':
      case 'h2':
      case 'label':
      case 'button':
        if (bold) return '600'
        return '500'

      case 'bodySmall':
      case 'caption':
      default:
        if (bold) return '500'
        return '400'
    }
  }};
  cursor: ${({ onClick }) => {
    if (onClick) return 'pointer'
  }};
  line-height: ${({ variant }) => {
    //calculate font family to use
    switch (variant) {
      case 'h1':
        return '28px'

      case 'h2':
      case 'caption':
        return '24px'

      case 'bodySmall':
      default:
        return '19px'

      case 'label':
        return '16px'

      case 'button':
        return '15px'
    }
  }};
`

export default function Typography({
  children,
  center,
  component,
  bold,
  ...rest
}) {
  //calculate component
  const { variant } = rest
  let tag = component

  if (!tag) {
    switch (variant) {
      case 'h1':
      case 'h2':
      case 'label':
      default:
        tag = variant
        break

      case 'bodySmall':
      case 'caption':
      case 'button':
        tag = 'p'
        break
    }
  }

  return (
    <StyledTypography
      as={tag}
      center={center ? 1 : 0}
      bold={bold ? 1 : 0}
      {...rest}
    >
      {children}
    </StyledTypography>
  )
}
