import React from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  GET_CONTACT,
  UPDATE_CONTACT,
  GET_COMPANIES,
} from "../../queries/Customer";

import { Field, Form } from "react-final-form";

import {
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@material-ui/core";

import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";

import { closeDialog } from "../../reusable/ScrollablePopup";
import { openSnackbar } from "../../reusable/Notifier";
import { emailLabel, nameLabel } from "../../assets/GlobalLabels";

const handleSubmit = () => {};

const EditContactModal = ({ clientId }) => {
  const {
    loading: getCustomersLoading,
    error: getCustomersError,
    data: dataCompanies,
  } = useQuery(GET_COMPANIES);
  const [updateContact, { loading: updateLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      onCompleted: () => {
        openSnackbar({
          message: "Client has been updated.",
          type: "success",
        });
        closeDialog();
      },
      onError: () => {
        openSnackbar({
          message: "Failed to update the client.",
          type: "error",
        });
      },
    }
  );

  const { loading, error, data } = useQuery(GET_CONTACT, {
    variables: { contactId: clientId },
  });

  if (error || getCustomersError) {
    return <ErrorMessage error={error ? error : getCustomersError} />;
  }

  // console.log(
  //   "Loading=" +
  //     loading +
  //     " data=" +
  //     data +
  //     " getcustomersLoading=" +
  //     getCustomersLoading +
  //     " dataCompanies=" +
  //     dataCompanies
  // );
  if (loading || data == null || getCustomersLoading || dataCompanies == null) {
    return <Loading />;
  }

  let companies = [...dataCompanies.companies].sort((a, b) => {
    return a?.name?.localeCompare(b?.name);
  });

  return (
    <Card
      style={{
        padding: "0",
        border: "none",
        boxShadow: "none",
        borderRadius: "8px",
      }}
    >
      <Form
        initialValues={{
          ...data.getContact,
          archived: data.getContact.contact_status === "Archived",
        }}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          submitting,
          values,
          // form: {
          //   mutators: { push, pop },
          // },
        }) => (
          <>
            <Grid
              container
              spacing={3}
              justify="center"
              style={{ padding: "2em" }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    paddingLeft: "0.5em",
                  }}
                >
                  Edit Contact
                </Typography>
              </div>
              <Grid item xs={12}>
                <Field name="contact_company">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      select
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                      label="Select Client"
                      fullWidth
                      size="small"
                      // required
                      variant="outlined"
                      // style={{ paddingBottom: "15px" }}
                    >
                      {companies.map((company) => (
                        <MenuItem key={company._id} value={company.id}>
                          {company.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="contact_name">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label={nameLabel}
                      fullWidth
                      required={true}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="contact_email">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label={emailLabel}
                      fullWidth
                      required={false}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="contact_number">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label="Number"
                      fullWidth
                      required={false}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="contact_position">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      // inputRef={(input) => input && input.focus()}
                      label="Position"
                      fullWidth
                      required={false}
                      size="small"
                      variant="outlined"
                      error={meta.error && meta.touched}
                      helperText={meta.error && meta.touched ? meta.error : ""}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="archived" type="checkbox">
                  {({ input }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          inputProps={{ name: "archived" }}
                          {...input}
                          value="archived"
                        />
                      }
                      label="Archived"
                    />
                  )}
                </Field>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <Divider
                  style={{
                    // marginTop: "25px",
                    marginBottom: "12px",
                    marginLeft: "-12px",
                    marginRight: "-24px",
                    backgroundColor: "#C3CAD8",
                  }}
                />
              </Grid>
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "13px",
                  marginBottom: "12px",
                  paddingLeft: "0.75em",
                  paddingRight: "0.75em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    values._id = clientId;
                    values.contact_company = parseInt(values.contact_company);
                    values.contact_status = values.archived
                      ? "Archived"
                      : "Active";
                    updateContact({ variables: values });
                  }}
                  disabled={
                    submitting ||
                    updateLoading ||
                    values.contact_name == null ||
                    values.contact_name.length === 0
                  }
                  type="submit"
                >
                  {updateLoading ? <>Saving...</> : <>Save</>}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </Card>
  );
};

export default EditContactModal;
