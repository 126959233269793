import React from "react";

import ProjectCostsWizardStep1 from "./ProjectCostsWizardStep1";
const ProjectCostsModal = ({ project, onSaveClicked }) => {
  return (
    <ProjectCostsWizardStep1 project={project} onSaveClicked={onSaveClicked} />
  );
};

export default ProjectCostsModal;
