import React from "react";

import { Modal } from "@material-ui/core";

let openModalFn;
let closeModalFn;

class Popup extends React.Component {
  state = {
    open: false,
    content: "",
  };

  componentDidMount() {
    openModalFn = this.openModal;
    closeModalFn = this.closeModal;
  }

  openModal = ({ content }) => {
    this.setState({
      open: true,
      content,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
      content: "",
    });
  };

  render() {
    return (
      <Modal
        aria-labelledby="Modal"
        aria-describedby="Modal"
        open={this.state.open}
        disableBackdropClick
        disableEscapeKeyDown
        disableAutoFocus
      >
        <div
          style={{
            position: "absolute",
            top: `10%`,
            left: `50%`,
            transform: `translate(-50%, -10%)`,
            width: "90%",
            maxWidth: "900px",
          }}
        >
          {this.state.content}
        </div>
      </Modal>
    );
  }
}

export function openModal({ content }) {
  openModalFn({ content });
}

export function closeModal() {
  closeModalFn();
}

export default Popup;
