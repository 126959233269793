import React from "react";

import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router";

const StyledTabs = styled.div`
  .tabs-wrapper {
    display: flex;
    margin-right: auto;
    margin-bottom: 12px;
    /* padding: 12px 12px 0px 12px; */

    .tab {
      padding-bottom: 16px;
      font-weight: 500;
      cursor: pointer;
      transition: all ease-in-out 150ms;
      border-bottom: 2px solid transparent;
      margin-right: 20px;

      &.active,
      &:hover {
        border-bottom: 2px solid rgb(243, 146, 0);
      }
    }
  }
`;

export default function ModeSelect() {
  const history = useHistory();
  // console.log("history", history);

  return (
    <StyledTabs>
      <div className="tabs-wrapper">
        <div
          onClick={() => {
            history.push("/admin-settings/users");
          }}
          className={
            history.location.pathname === "/admin-settings/users"
              ? "tab active"
              : "tab"
          }
        >
          <Typography
            component="h1"
            variant="h4"
            color={
              history.location.pathname === "/admin-settings/users"
                ? "secondary"
                : "textSecondary"
            }
          >
            Users
          </Typography>
        </div>

        <div
          onClick={() => {
            history.push("/admin-settings/users/activity");
          }}
          className={
            history.location.pathname === "/admin-settings/users/activity"
              ? "tab active"
              : "tab"
          }
        >
          <Typography
            component="h1"
            variant="h4"
            color={
              history.location.pathname === "/admin-settings/users/activity"
                ? "secondary"
                : "textSecondary"
            }
          >
            Activity
          </Typography>
        </div>
      </div>
    </StyledTabs>
  );
}
