import React from "react";

import { Dialog } from "@material-ui/core";

let openDialogFn;
let closeDialogFn;

class ScrollablePopup extends React.Component {
  state = {
    open: false,
    content: "",
  };

  componentDidMount() {
    openDialogFn = this.openDialog;
    closeDialogFn = this.closeDialog;
  }

  openDialog = ({ content }) => {
    this.setState({
      open: true,
      content,
    });
  };

  closeDialog = () => {
    this.setState({
      open: false,
      content: "",
    });
  };

  render() {
    return (
      <Dialog
        modal={true}
        open={this.state.open}
        autoDetectWindowHeight={true}
        autoScrollBodyContent={true}
        repositionOnUpdate={true}
        // contentStyle={{ width: "90%", height: "90%" }}
        PaperProps={{
          style: {
            maxWidth: "1100px",
          },
        }}
      >
        {this.state.content}
      </Dialog>
    );
  }
}

export function openDialog({ content }) {
  openDialogFn({ content });
}

export function closeDialog() {
  closeDialogFn();
}

export default ScrollablePopup;
