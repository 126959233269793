import React from "react";
import AuthenticatedRoute from "../utils/AuthenticatedRoute";
import Companies from "../pages/Customers/Companies";

const CustomerRoutes = [
  <AuthenticatedRoute
    path="/clients"
    exact
    component={Companies}
    title="Clients"
  />,
];

export default CustomerRoutes;
