import React, { useEffect, useState } from "react";
import { Typography, MenuItem, TextField, Card, Grid } from "@material-ui/core";

import SmallFormPopover from "../../../reusable/SmallFormPopover";

import { statusLabel } from "../../../assets/GlobalLabels";
import { Field } from "react-final-form";

const ourLabelColour = "#BB4430";
const ourLabelStyle = { color: ourLabelColour };

export default function ProjectOverview({
  project,
  updateProject,
  property,
  service,
  statuses,
}) {
  return (
    <Card style={{ padding: "0", marginBottom: "1.5rem" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            padding: "26px 24px",
            // borderBottom: "1px solid #E7EAEF",
            backgroundColor: "#4B4F54",
          }}
        >
          <Typography
            variant="h5"
            style={{
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: "white",
            }}
          >
            {`Project Overview - ${service}`}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ padding: "1rem" }}>
          <div>
            <Typography variant="body2" style={ourLabelStyle} gutterBottom>
              Department Status
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <SmallFormPopover
                arrowIcon
                initialValues={{
                  status_text: project[property],
                }}
                onSubmit={(values) => {
                  updateProject({
                    variables: {
                      [property]: values.status_text,
                      _id: project?._id,
                    },
                  });
                }}
                content={
                  <Field name="status_text">
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        select
                        error={meta.error && meta.touched}
                        helperText={
                          meta.error && meta.touched ? meta.error : ""
                        }
                        label={statusLabel}
                        fullWidth
                        required
                        variant="outlined"
                      >
                        {/* will use status prop or default
                        temp until all lists arrive */}
                        {statuses.map((role) => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </Field>
                }
              />
              <Typography variant="body1">
                {project[property] ? project[property] : "Not set"}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}
