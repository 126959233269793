import React from "react";
import { useMutation } from "@apollo/client";
import { Field, Form } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";
import {
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";

import { UPDATE_PROJECT_NAME } from "../../queries/Project";
import { openSnackbar } from "../../reusable/Notifier";

export default function ProjectUpdateNameModal({
  project: { project_name, _id },
}) {
  const [updateProjectName] = useMutation(UPDATE_PROJECT_NAME, {
    onCompleted: () => {
      openSnackbar({
        message: `Project name has been updated.`,
        type: "success",
      });
      closeDialog();
    },
    onError: () => {
      openSnackbar({
        message: "Problem updating project name.",
        type: "error",
      });
    },
  });

  return (
    <Form
      initialValues={{ project_name: project_name }}
      validate={(values) => {
        const errors = {};

        if (!values.project_name) {
          errors.address_1 = "Required";
        }

        return errors;
      }}
      onSubmit={(values) => {
        updateProjectName({
          variables: { project_name: values.project_name, _id: _id },
        });
      }}
    >
      {({ handleSubmit, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={3}
            style={{
              padding: "12px",
              margin: "0px",
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                Project Name
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Field name="project_name">
                {({ input, meta }) => (
                  <TextField
                    {...input}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    label="Project Name"
                    fullWidth
                    required
                    variant="outlined"
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Divider
                style={{
                  marginLeft: "-24px",
                  marginRight: "-24px",
                  backgroundColor: "#C3CAD8",
                }}
              />
            </Grid>

            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  closeDialog();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={submitting}
                type="submit"
              >
                Save
              </Button>
            </Grid>

            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </Grid>
        </form>
      )}
    </Form>
  );
}
