import React, { useContext } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";

import {
  filterHidden,
  GET_USERS,
  sortByName,
  UPDATE_USER_PREFERENCES,
} from "../../queries/User";
import { UserContext } from "../../utils/PageWrapper";

import { Link } from "react-router-dom";
import useSessionStorage from "../../reusable/useSessionStorage";
import ModeSelect from "./ModeSelect";
import { format, parse, set, subMonths } from "date-fns";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core/styles";
import { useHistoryState } from "../../queries/Project";
import { Field, Form } from "react-final-form";

import ActivitiesDownload from "./ActivitiesDownload";
import MUIDataTable from "mui-datatables";
import Loading from "../../reusable/Loading";
import {
  Typography,
  Grid,
  Button,
  CardContent,
  Card,
  Breadcrumbs,
  TextField,
} from "@material-ui/core";
import { nameLabel, projectLabel } from "../../assets/GlobalLabels";
import { secondaryColor } from "../../assets/ThemeStyling";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Search,
} from "@material-ui/icons";
import { TextFieldAdapter } from "../Customers/Companies";
import MenuItem from "../../uicomponents/MenuItem";

const GET_ALL_ACTIVITIES = gql`
  query getAllActivities(
    $start_date: String
    $end_date: String
    $project_name: String
    $project_job_no: String
    $companies_name: String
    $user_id: String
  ) {
    getAllActivities(
      start_date: $start_date
      end_date: $end_date
      project_name: $project_name
      project_job_no: $project_job_no
      companies_name: $companies_name
      user_id: $user_id
    ) {
      _id
      time
      content
      project_name
      project_job_no
      companies_name

      project {
        _id
      }

      user {
        _id
        first_name
        last_name
      }
    }
  }
`;

const startDate = set(subMonths(new Date(), 3), {
  hours: 0,
  minutes: 0,
  seconds: 0,
});
const endDate = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });

export default function Activity() {
  const [value, setValue] = useSessionStorage("activitiesSearch", {
    variables: {
      start_date: startDate,
      end_date: endDate,
    },
  });

  const user = useContext(UserContext);

  const {
    loading: usersLoading,
    error: usersError,
    data: usersData,
  } = useQuery(GET_USERS);

  const { loading, error, data, refetch } = useQuery(GET_ALL_ACTIVITIES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      start_date: startDate,
      end_date: endDate,
    },
  });

  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);
  const [tableCurrentPage, setTableCurrentPage] = useHistoryState(
    "tableCurrentPage",
    0
  );

  let activities;

  if (data?.getAllActivities) activities = data?.getAllActivities;

  if (loading || usersLoading) {
    return <Loading />;
  }

  if (error || usersError) {
    return <Loading />;
  }

  const formattedActivities = [];

  activities.forEach((activity) => {
    const data = [
      {
        columnDetails: {
          name: "Date/Time",
          options: {
            filter: false,
            sort: true,
            download: true,
          },
        },
        columnData: format(parse(activity.time, "T", new Date()), "PPp"),
      },
      {
        columnDetails: {
          name: "Details",
          options: {
            filter: false,
            sort: false,
            download: true,
          },
        },
        columnData: activity.content,
      },
      {
        columnDetails: {
          name: "User",
          options: {
            filter: true,
            sort: true,
            download: true,
          },
        },
        columnData: `${activity.user?.first_name} ${activity.user?.last_name}`,
      },
      {
        columnDetails: {
          name: "Client",
          options: {
            filter: true,
            sort: true,
            download: true,
          },
        },
        columnData: activity.companies_name
          ? activity.companies_name
          : "Not Set",
      },
      {
        columnDetails: {
          name: projectLabel,
          options: {
            customBodyRenderLite: (dataIndex) => {
              const activity = activities[dataIndex];
              if (!activity.project_job_no && !activity.project_name) {
                return "Not Set";
              } else {
                return (
                  <Link
                    style={{ color: secondaryColor }}
                    to={"/projects/single/" + activity.project_job_no}
                  >
                    {activity.project_job_no + " " + activity.project_name}
                  </Link>
                );
              }
            },
          },
        },
      },
    ];

    formattedActivities.push(data);
  });

  const columnsArray = [];
  const activitiesData = [];

  formattedActivities.forEach((columns, index) => {
    const columnData = [];

    columns.forEach((column) => {
      columnData.push(column.columnData);
    });

    activitiesData.push(columnData);

    //only create column titles on first pass
    if (index === 0) {
      columns.forEach((column) => {
        columnsArray.push(column.columnDetails);
      });
    }
  });

  const options = {
    filterType: "checkbox",
    selectableRows: "none",

    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,

    responsive: "standard",
    downloadOptions: {
      filename: "Activity.csv",
    },
    sortOrder: {
      name: nameLabel,
      direction: "asc",
    },
    onChangePage: (currentPage) => {
      setTableCurrentPage(currentPage);
    },
    page: tableCurrentPage,
    onChangeRowsPerPage: (rowsPerPage) => {
      if (user.preferences == null) {
        user.preferences = {};
      }
      user.preferences.usersRowsPerPage = rowsPerPage;
      updateUserPreferences({
        variables: {
          _id: user._id,
          preferences: user.preferences,
        },
      });
    },
    rowsPerPage:
      user?.preferences?.usersRowsPerPage >= 10
        ? user?.preferences?.usersRowsPerPage
        : 10,
  };

  return (
    <Grid container spacing={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{ fontSize: "14px", padding: "12px" }}
      >
        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          User Management
        </Typography>

        <Typography color="text.primary" style={{ fontSize: "14px" }}>
          Activities Search
        </Typography>
      </Breadcrumbs>

      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <ModeSelect />

          <ActivitiesDownload />
        </div>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent style={{ paddingLeft: "0", paddingRight: "0" }}>
            <Form
              initialValues={value.variables}
              onSubmit={(values) => {
                setValue({ variables: values });
                refetch({
                  start_date: values.start_date,
                  end_date: values.end_date,
                  project_name: values.project_name,
                  project_job_no: values.project_job_no,
                  companies_name: values.companies_name,
                  user_id: values.user_id,
                });
              }}
              render={({
                handleSubmit,

                submitting,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3} style={{ marginBottom: "1em" }}>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "2em",
                      }}
                    >
                      <Button
                        fullWidth
                        type="submit"
                        loading={loading}
                        disabled={submitting || loading}
                      >
                        <Search style={{ fill: secondaryColor }} />
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Field name="start_date">
                          {({ input, meta }) => (
                            <DatePicker
                              clearable={true}
                              format="DD/MM/YYYY"
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value ? input.value : null}
                              onBlur={input.onBlur}
                              fullWidth
                              required={false}
                              disablePast={false}
                              autoOk={true}
                              disabled={false}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                              label="From"
                              leftArrowIcon={<KeyboardArrowLeft />}
                              inputVariant="outlined"
                              rightArrowIcon={<KeyboardArrowRight />}
                            />
                          )}
                        </Field>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Field name="end_date">
                          {({ input, meta }) => (
                            <DatePicker
                              clearable={true}
                              format="DD/MM/YYYY"
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value ? input.value : null}
                              onBlur={input.onBlur}
                              fullWidth
                              required={false}
                              disablePast={false}
                              autoOk={true}
                              disabled={false}
                              error={meta.error && meta.touched}
                              helperText={
                                meta.error && meta.touched ? meta.error : ""
                              }
                              label="To"
                              leftArrowIcon={<KeyboardArrowLeft />}
                              inputVariant="outlined"
                              rightArrowIcon={<KeyboardArrowRight />}
                            />
                          )}
                        </Field>
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={1}>
                      <Field
                        name="companies_name"
                        component={TextFieldAdapter}
                        label="Client"
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Field
                        name="project_name"
                        component={TextFieldAdapter}
                        label="Project Name"
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Field
                        name="project_job_no"
                        component={TextFieldAdapter}
                        label="Job Number"
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <Field name="user_id">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            select
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                            label="User"
                            fullWidth
                            // required
                            variant="outlined"
                          >
                            {usersData.users
                              .filter(filterHidden)
                              .sort(sortByName)
                              .map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  {`${user.first_name} ${user.last_name}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </form>
              )}
            />

            <ThemeProvider
              theme={(outerTheme) => ({
                ...outerTheme,
                overrides: {
                  MUIDataTable: {
                    paper: {
                      border: "none",
                    },
                  },
                  MuiTableCell: {
                    footer: {
                      border: "none",
                    },
                  },
                },
              })}
            >
              <MUIDataTable
                title=""
                data={activitiesData}
                columns={columnsArray}
                options={options}
              />
            </ThemeProvider>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
