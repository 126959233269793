import { FilterList } from "@material-ui/icons";
import { mdiPlus } from "@mdi/js";
import ModeIcon from "@mui/icons-material/Mode";
import React, { useState, useContext, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import Icon from "@mdi/react";
import { openDialog } from "../../reusable/ScrollablePopup";
import { Search } from "@material-ui/icons";
import Loading from "../../reusable/Loading";
import { ThemeProvider } from "@material-ui/styles";
import {
  Popover,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  Tooltip,
  createTheme,
  Breadcrumbs,
} from "@material-ui/core";

import { labelColour } from "../../queries/Project";
import { Field, Form } from "react-final-form";
import NewContactModal from "./NewContactModal";
import EditContactModal from "./EditContactModal";
import { secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  clientLabel,
  emailLabel,
  nameLabel,
  statusLabel,
} from "../../assets/GlobalLabels";
import useSessionStorage from "../../reusable/useSessionStorage";
import { UPDATE_USER_PREFERENCES } from "../../queries/User";
import { UserContext } from "../../utils/PageWrapper";
//csv imports
import DownloadCsv from "../../reusable/DownloadCsv";
import SupportButton from "../../reusable/SupportButton";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 160,
        },
        "&:nth-child(2)": {
          width: 120,
        },
        "&:nth-child(3)": {
          width: 160,
        },
        "&:nth-child(4)": {
          width: 200,
        },
        "&:nth-child(5)": {
          width: 200,
        },
        "&:nth-child(6)": {
          width: 100,
        },
        "&:nth-child(7)": {
          width: 100,
        },
      },
    },
  },
});

const GET_CONTACTS = gql`
  query contacts(
    $contact_name: String
    $companies_name: String
    $statusActive: Boolean
  ) {
    contacts(
      contact_name: $contact_name
      companies_name: $companies_name
      statusActive: $statusActive
    ) {
      _id
      contact_name
      contact_email
      contact_position
      contact_number
      contact_company
      contact_type
      companies_name
      contact_status
    }
  }
`;

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={({ target: { value } }) => input.onChange(value)}
    errorText={meta.touched ? meta.error : ""}
    variant="outlined"
    color="secondary"
    fullWidth
  />
);

export default function Contacts() {
  // csv
  const [csvData, SetCsvData] = useState();

  // csv end
  const user = useContext(UserContext);
  const [updateUserPreferences, { loading: updateUserPreferencesLoading }] =
    useMutation(UPDATE_USER_PREFERENCES);
  const [value, setValue] = useSessionStorage("contactsearch", {});

  const { loading, error, data } = useQuery(GET_CONTACTS, {
    variables: {
      ...value,
      statusActive: user.preferences.contactsFilters?.Active,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [filterOpen, setFilterOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //csv
  useEffect(() => {
    if (data?.contacts) {
      SetCsvData(data.contacts);
    }
  }, [data]);

  let view = (
    <Typography variant="body1" style={{ color: "grey" }}>
      Search for a person or create a new one
    </Typography>
  );

  if (loading || updateUserPreferencesLoading || !data.contacts) {
    view = <Loading></Loading>;
  }

  if (error) {
    view = (
      <Typography variant="body1">
        Failed to get people, please try again.
      </Typography>
    );
  }

  if (data?.contacts) {
    const { contacts } = data;
    //format data
    const formattedContactsArray = contacts.map((contact) => {
      return [
        contact.contact_name,
        contact.companies_name,
        contact.contact_position,

        <a
          style={{ color: secondaryColor }}
          href={`mailto:${contact.contact_email}`}
        >
          {contact.contact_email}
        </a>,

        contact.contact_number,
        contact.contact_status,
        contact._id,
      ];
    });
    const columns = [
      nameLabel,
      clientLabel,
      "Job Title",
      emailLabel,
      "Contact No.",
      {
        name: statusLabel,
        options: {
          customBodyRender: (status) => {
            let backgroundColor = "#66BC0C";
            if (status == null || status.length === 0) {
              status = "Active";
            }
            switch (status) {
              case "Prospect":
                backgroundColor = "#F6992B";
                break;
              case "Archived":
                backgroundColor = "#e02020";
                break;
              default:
                break;
            }
            return (
              <div
                style={{
                  backgroundColor: backgroundColor,
                  borderRadius: "4px",
                  width: "auto",
                  textAlign: "center",
                  paddingTop: "0.5em",
                  paddingBottom: "0.5em",
                  color: "",
                  fontSize: "16px",
                  fontWeight: "normal",
                  textTransform: "none",
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                }}
              >
                {status}
              </div>
            );
          },
        },
      },
      {
        name: actionsLabel,
        options: {
          customBodyRender: (_id) => {
            return (
              <IconButton
                onClick={() => {
                  openDialog({
                    content: <EditContactModal clientId={_id} />,
                  });
                }}
              >
                <ModeIcon style={{ fill: secondaryColor }}></ModeIcon>
              </IconButton>
            );
          },
        },
      },
    ];

    const options = {
      selectableRows: "none",
      print: false,
      download: false,
      responsive: "standard",
      search: false,
      filter: false,
      elevation: 0,
      viewColumns: false,
      sortOrder: {
        name: nameLabel,
        direction: "asc",
      },
    };

    view = (
      <div
        style={{
          width: "100%",
          height: "100%",
          paddingLeft: "1em",
          paddingRight: "1em",
          borderTop: "1px solid #E7EAEF",
        }}
      >
        <ThemeProvider theme={columnWidthTheme}>
          <MUIDataTable
            data={formattedContactsArray}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false);
          setAnchorEl(null);
        }}
      >
        <div style={{ padding: "20px", minWidth: "300px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="button">Filter</Typography>
            <Button
              color="primary"
              onClick={() => {
                // reset filters
                updateUserPreferences({
                  variables: {
                    _id: user._id,
                    preferences: {
                      ...user.preferences,
                      contactsFilters: {
                        Active: true,
                      },
                    },
                  },
                });
              }}
            >
              Reset
            </Button>
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.preferences.contactsFilters?.Active}
                  onChange={() => {
                    updateUserPreferences({
                      variables: {
                        _id: user._id,
                        preferences: {
                          ...user.preferences,
                          contactsFilters: {
                            Active: !user.preferences.contactsFilters?.Active,
                          },
                        },
                      },
                    });
                  }}
                  value="myProjects"
                  color="secondary"
                />
              }
              label="Active"
            />
          </FormGroup>
        </div>
      </Popover>
      <Grid container spacing={3}>
        <Breadcrumbs
          aria-label="breadcrumb"
          style={{ fontSize: "14px", padding: "12px" }}
        >
          <Typography color="text.primary" style={{ fontSize: "14px" }}>
            Contacts
          </Typography>

          <Typography color="text.primary" style={{ fontSize: "14px" }}>
            Contact Search
          </Typography>
        </Breadcrumbs>

        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <Typography component="h1" variant="h4" gutterBottom>
              Contacts
            </Typography>
            <div
              style={{
                display: "flex",
                marginBottom: "16px",
                justifyContent: "space-between",
              }}
            >
              <SupportButton text="Download Data">
                <DownloadCsv csvData={csvData} />
              </SupportButton>
              <Button
                variant="contained"
                style={{
                  marginLeft: "8%",
                  width: "13rem",
                }}
                color="primary"
                onClick={() => {
                  openDialog({
                    content: <NewContactModal />,
                  });
                }}
              >
                <Icon
                  path={mdiPlus}
                  title="Close"
                  size="24px"
                  color="white"
                  style={{ marginRight: "10px" }}
                />
                New Contact
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <Card>
        <CardContent>
          <Form
            onSubmit={(values) => setValue(values)}
            initialValues={value}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit} style={{}}>
                <Grid container spacing={3} style={{}}>
                  <Grid
                    item
                    xs={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "2em",
                    }}
                  >
                    <Button
                      fullWidth
                      type="submit"
                      // loading={getCustomersLoading}
                      disabled={submitting || pristine}
                    >
                      <Search style={{ fill: secondaryColor }} />
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Field
                      name="contact_name"
                      component={TextFieldAdapter}
                      label={nameLabel}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Field
                      name="companies_name"
                      component={TextFieldAdapter}
                      label={clientLabel}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title="Filter" placement="bottom">
                      <IconButton
                        onClick={(event) => {
                          setFilterOpen(true);
                          setAnchorEl(event.currentTarget);
                        }}
                        id="filter-button"
                      >
                        <FilterList />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </form>
            )}
          />
          <Grid container spacing={3}>
            <Grid item xs={1}>
              &nbsp;
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body1" style={{ color: labelColour }}>
                Applied Filters:
                {user.preferences.contactsFilters?.Active && (
                  <Button
                    onClick={() => {
                      updateUserPreferences({
                        variables: {
                          _id: user._id,
                          preferences: {
                            ...user.preferences,
                            contactsFilters: {
                              Active: false,
                            },
                          },
                        },
                      });
                    }}
                  >
                    Status: Active
                  </Button>
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ padding: "0" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: data ? "1px" : "350px",
              }}
            >
              {view}
            </div>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
