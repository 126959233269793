import React, { useState, Fragment } from "react";

import MUIDataTable from "mui-datatables";

import gql from "graphql-tag";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  createTheme,
} from "@material-ui/core";
import { openSnackbar } from "../../reusable/Notifier";
import { MoreVert } from "@material-ui/icons";
import { useMutation } from "@apollo/client";

import { ThemeProvider } from "@material-ui/styles";
import { secondaryColor } from "../../assets/ThemeStyling";
import {
  actionsLabel,
  contactNumberLabel,
  emailAddressLabel,
  nameLabel,
  projectRoleLabel,
} from "../../assets/GlobalLabels";

const columnWidthTheme = createTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        "&:nth-child(1)": {
          width: 200,
        },
        "&:nth-child(2)": {
          width: 180,
        },
        "&:nth-child(3)": {
          width: 180,
        },
        "&:nth-child(4)": {
          width: 140,
        },
        "&:nth-child(5)": {
          width: 140,
        },
        "&:nth-child(6)": {
          width: 50,
        },
      },
    },
  },
});

// const theme = createMuiTheme({
//   overrides: {
//     MuiTable: {
//       root: {
//         border: [[0, 'solid', '#d3d3d3']],
//       },
//     },
//   },
// });

const REMOVE_PROJECT_CONTACT = gql`
  mutation removeProjectContact($_id: ID!) {
    removeProjectContact(_id: $_id)
  }
`;

export default function ProjectClientContactsTable({ dates, onRemoved }) {
  const [
    removeProjectContact,
    {
      loading: removeProjectDateLoading,
      error: removeProjectDateError,
      data: removeProjectDateData,
    },
  ] = useMutation(REMOVE_PROJECT_CONTACT, {
    onError: () => {
      openSnackbar({
        message: "Error removing contact, please try again",
        type: "error",
      });
    },
    onCompleted: () => {
      openSnackbar({
        message: "Contact removed successfully",
        type: "success",
      });
      onRemoved();
    },
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const tableDataArray = [];

  dates.forEach((date) => {
    const data = [
      date.contact.contact_name,
      date.company.name,
      date.role,
      date.contact.contact_number,

      <a
        style={{ color: secondaryColor }}
        href={`mailto:${date.contact.contact_email}`}
      >
        {date.contact.contact_email}
      </a>,

      date._id,
    ];

    tableDataArray.push(data);
  });

  const options = {
    filter: false,
    selectableRows: "none",
    print: false,
    responsive: "standard",
    download: false,
    search: false,
    elevation: 0,
    viewColumns: false,
    sortOrder: {
      name: emailAddressLabel,
      direction: "desc",
    },
  };

  const columnsArray = [
    { name: nameLabel },
    { name: "Company" },
    { name: projectRoleLabel },
    { name: contactNumberLabel },
    {
      name: emailAddressLabel,
      options: {
        sort: true,
        display: "true",
      },
    },
    {
      name: actionsLabel,
      options: {
        sort: false,
        display: "true",
        customBodyRenderLite: (dataIndex) => {
          return (
            <>
              <Tooltip title={actionsLabel} placement="top">
                <IconButton
                  data-index={dataIndex}
                  aria-label="View"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={columnWidthTheme}>
      <MUIDataTable
        data={tableDataArray}
        columns={columnsArray}
        options={options}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={(e) => {
            const value =
              tableDataArray[anchorEl.dataset.index][
                tableDataArray[anchorEl.dataset.index].length - 1
              ];
            if (
              window.confirm("Are you sure you want to remove this contact?")
            ) {
              removeProjectContact({
                variables: {
                  _id: value,
                },
              });
            }
            setAnchorEl(null);
          }}
        >
          Remove Contact
        </MenuItem>
      </Menu>
    </ThemeProvider>
  );
}
