import gql from "graphql-tag";

export const CREATE_MATTERPORT_LINK = gql`
  mutation createMatterportLink(
    $project_id: ID!
    $name: String!
    $link: String!
    $image: String
  ) {
    createMatterportLink(
      project_id: $project_id
      name: $name
      link: $link
      image: $image
    ) {
      _id
      project_id
      name
      link
      image
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_MATTERPORT_LINK = gql`
  mutation deleteMatterportLink($_id: ID!, $project_id: ID!) {
    deleteMatterportLink(_id: $_id, project_id: $project_id) {
      _id
      project_id
      name
      link
      image
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_MATTERPORT_LINK = gql`
  mutation updateMatterportLink(
    $_id: ID!
    $project_id: ID!
    $name: String!
    $link: String!
    $image: String
  ) {
    updateMatterportLink(
      _id: $_id
      project_id: $project_id
      name: $name
      link: $link
      image: $image
    ) {
      _id
      project_id
      name
      link
      image
      updatedAt
      createdAt
    }
  }
`;
