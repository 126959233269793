import React, { useContext } from "react";
import { Field } from "react-final-form";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Grid,
  MenuItem,
} from "@material-ui/core";
import { emailLabel } from "../../assets/GlobalLabels";
import { UserContext } from "../../utils/PageWrapper";

export default function UserEdit({ archived, submitting }) {
  const user = useContext(UserContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={6}>
            <Field name="firstName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="First Name"
                  fullWidth
                  required
                  disabled={archived}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="lastName">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Last Name"
                  fullWidth
                  required
                  disabled={archived}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="roleId">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  label="Portal Permissions"
                  fullWidth
                  required
                  disabled={archived}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                >
                  <MenuItem value={1}>User</MenuItem>
                  <MenuItem value={4}>Admin</MenuItem>
                  {user && user.role_id > 4 && (
                    <MenuItem value={10}>Super Admin</MenuItem>
                  )}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={6}>
            <Field name="email">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label={emailLabel}
                  fullWidth
                  required
                  // disabled={window.location.pathname.includes("/users/edit/")}
                  type="email"
                  variant="outlined"
                  error={
                    (meta.error && meta.touched) ||
                    (!!meta.submitError && !submitting)
                  }
                  helperText={
                    meta.error && meta.touched
                      ? meta.error
                      : !!meta.submitError && !submitting
                      ? meta.submitError
                      : ""
                  }
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={6}>
            <Field name="mobile">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Mobile"
                  fullWidth
                  required={false}
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={6}></Grid>

          <Grid item xs={12} md={6}>
            <Field name="hideFromAddToProject" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="hideFromAddToProject" />}
                  label="Hide From Add To Project"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={6}>
            <Field name="receive_activity_alert_emails" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...input}
                      value="receive_activity_alert_emails"
                    />
                  }
                  label="Receive Activity Alert Emails"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={6}>
            <Field name="receive_all_project_emails" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox {...input} value="receive_all_project_emails" />
                  }
                  label="Receive all project update emails"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={6}>
            <Field name="do_not_show_on_activity_reports" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...input}
                      value="do_not_show_on_activity_reports"
                    />
                  }
                  label="Do not show on activity reports"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_foreman" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_foreman" />}
                  label="Is Site Foreman?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_designer" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_designer" />}
                  label="Is Designer?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_acct_manager" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_acct_manager" />}
                  label="Is Contract Manager?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_qs" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_qs" />}
                  label="Is QS?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_project_manager" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_project_manager" />}
                  label="Is Project Manager?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_coordinator" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_coordinator" />}
                  label="Is Project Coordinator?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_health_and_safety" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_health_and_safety" />}
                  label="Is Health And Safety?"
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12} md={4}>
            <Field name="is_furniture" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={<Checkbox {...input} value="is_furniture" />}
                  label="Is Furniture?"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={12} md={4}>
            &nbsp;
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
