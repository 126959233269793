import React from "react";
import validator from "validator";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Field, Form, FormSpy, useFormState } from "react-final-form";
import createDecorator from "final-form-calculate";
import { labelColour } from "../../queries/Project";
import {
  Grid,
  Divider,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { useStore } from "./WizPageStore";
import PageSwitch from "./PageSwitch";
import { Box } from "@mui/system";

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={({ target: { value } }) => input.onChange(value)}
    errorText={meta.touched ? meta.error : ""}
    fullWidth
    variant="outlined"
    color="secondary"
  />
);

export default function ScopeOfWorks() {
  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);
  return (
    <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
      {({ values, pristine, submitting, form }) => (
        <Box
          //main container
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Grid
            //content container
            container
            spacing={3}
            style={{ flex: "1 0 auto" }}
          >
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h5"
                style={{
                  fontWeight: 600,
                  fontFamily: "Poppins",
                }}
              >
                Scope Of Works
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ paddingBottom: "0" }}>
              <Typography variant="body1" style={{ color: labelColour }}>
                Select all Services Concorde BGW are responsible for
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Field name="services_design" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "services_design" }}
                        {...input}
                        value="services_design"
                      />
                    }
                    label="Design"
                  />
                )}
              </Field>
              <Field name="services_build" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "services_build" }}
                        {...input}
                        value="services_build"
                      />
                    }
                    label="Build"
                  />
                )}
              </Field>
            </Grid>

            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Field name="services_qs" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "services_qs" }}
                        {...input}
                        value="services_qs"
                      />
                    }
                    label="Project QS"
                  />
                )}
              </Field>

              <Field name="services_hs" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "services_hs" }}
                        {...input}
                        value="services_hs"
                      />
                    }
                    label={"Health & Safety"}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={4}>
              <Field name="services_ff" type="checkbox">
                {({ input }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ name: "services_ff" }}
                        {...input}
                        value="services_ff"
                      />
                    }
                    label={"F&F"}
                  />
                )}
              </Field>
            </Grid>

            <Grid item xs={12}>
              <Field
                name="scope_of_works"
                component={TextFieldAdapter}
                multiline
                minRows={14}
                label="Scope of works"
                placeholder="Please outline the scope of works"
              />
            </Grid>
          </Grid>

          {/* <Grid container style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Divider
                style={{
                  // marginTop: "25px",
                  marginBottom: "12px",
                  marginLeft: "-12px",
                  marginRight: "-24px",
                  backgroundColor: "#C3CAD8",
                }}
              />
            </Grid>
            <Grid
              item
              xs
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "13px",
                marginBottom: "12px",
                marginLeft: "24px",
                marginRight: "24px",
              }}
            >
              <Button
                // disabled={true}
                onClick={previous}
                variant="outlined"
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={next}
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </Grid> */}

          <PageSwitch disabled={submitting} submit />
        </Box>
      )}
    </FormSpy>
  );
}
