import create from "zustand";

export const useStore = create((set) => ({
  page: 0,

  next: () => set((state) => ({ page: state.page + 1 })),
  previous: () => set((state) => ({ page: state.page - 1 })),

  pageChange: (offset) => set((state) => ({ page: state.page + offset })),

  reset: () => set({ page: 0 }),
}));
