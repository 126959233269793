import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  Box,
  Button,
  createTheme,
  Divider,
  MuiThemeProvider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { closeDialog } from "../reusable/ScrollablePopup";
import "./wizard.css";
import { styled } from "@material-ui/core";

import { useStore } from "../pages/Projects/WizPageStore";
import { primaryColor, secondaryColor } from "../assets/ThemeStyling";

const getMuiTheme = () =>
  createTheme({
    palette: {
      primary: { main: primaryColor, contrastText: "#ffffff" },
      secondary: { main: secondaryColor },
      text: {
        secondary: "#68727E",
      },
      action: {
        active: secondaryColor,
        focus: secondaryColor,
        selected: secondaryColor,
      },
    },
    shadows: ["none"],
    overrides: {
      MuiButton: {
        label: {
          fontFamily: "Poppins, Helvetica, Arial, Sans-Serif",
          fontWeight: 500,
        },
      },

      MuiStepConnector: {
        root: {
          display: "none",
        },
      },

      MuiStepLabel: {
        label: {
          color: "white !important",
          fontSize: "16px",
          fontFamily: "Poppins",
          fontWeight: "400 !important",
          marginLeft: "5px",
        },
      },
    },
  });

const CustomStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  zIndex: 1,
  color: "#fff",

  fontFamily: "Roboto",
  fontSize: "15px",
}));

function CustomStepIcon(props) {
  const { active, completed, className } = props;
  // console.log("icon", props);

  return (
    <CustomStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <TaskAltIcon
          className="CustomStepIcon-completedIcon"
          style={{
            fill: secondaryColor,
            // border: "none",
            // borderRadius: "50%",
            transform: "scale(1.1)",
          }}
        />
      ) : active ? (
        <div
          className="CustomStepIcon-circle"
          style={{
            color: secondaryColor,

            width: "27px",
            height: "27px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",

            border: "3px solid #BB4430",
            borderRadius: "50%",
          }}
        >
          {props.icon}
        </div>
      ) : (
        <div
          className="CustomStepIcon-circle"
          style={{
            border: "3px solid #F6D285",
            borderRadius: "50%",

            width: "27px",
            height: "27px",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.icon}
        </div>
      )}
    </CustomStepIconRoot>
  );
}

CustomStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const Wizard = (props) => {
  // const [values, setValues] = useState();
  const [title, setTitle] = useState(props.title || "New Project Setup");
  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);
  const reset = useStore((state) => state.reset);

  const activePage = React.Children.toArray(props.children)[page];

  // let nextPageText;

  // if (page === 6) {
  //   nextPageText = "create project";
  // } else if (page === 7) {
  //   nextPageText = "close";
  // } else {
  //   nextPageText = "next";
  // }

  // const validate = (values) => {
  //   const activePage = React.Children.toArray(props.children)[props.children];
  //   return activePage.validate ? activePage.validate(values) : {};
  // };

  const handleSubmit = (values) => {
    if (page === 0) {
      pageChange(2);
    } else {
      next();
    }
    // const { page } = state;
    // const submitPage = page === React.Children.count(children) - 2;
    // if (submitPage) {
    //   onSubmit(values);
    //   next(values);
    // } else {
    //   next(values);
    // }
  };

  // console.log("wiz page", page);
  // console.log("wiz props", props);

  // const isLastPage = page === React.Children.count(children) - 1;
  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <Form
        initialValues={props.initialValues || {}}
        validate={(values) => {
          const errors = {};

          if (page === 0 && !values.companyId) {
            errors.search_name = "Please select a client";
          }
          if (page === 2 && !values.project_name) {
            errors.project_name = "Required";
          }
          if (page === 2 && !values.project_address?.address_1) {
            errors.address_1 = "Required";
          }
          if (page === 2 && !values.project_address?.city) {
            errors.city = "Required";
          }
          if (page === 2 && !values.project_address?.postcode) {
            errors.postcode = "Required";
          }

          // if (!values.project_name) {
          //   errors.project_name = "Required";
          // }

          return errors;
        }}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
          setCompanyDetails: (args, state, utils) => {
            utils.changeValue(state, "companyId", () => args[0]);
            utils.changeValue(state, "companies_name", () => args[1]);
            utils.changeValue(state, "selectedIndexes", () => args[2]);
          },
          setJobNo: (args, state, utils) => {
            utils.changeValue(state, "job_no", () => args[0]);
          },
          setProjectContact: (args, state, utils) => {
            utils.changeValue(state, "projectContacts", () => args[0]);
          },
          resetProjectSelectedValues: (_args, state, utils) => {
            utils.changeValue(state, "selected_role", () => "");
            utils.changeValue(state, "selected_contact", () => "");
          },
          resetProjectSelectedValues2: (_args, state, utils) => {
            utils.changeValue(state, "selected_role2", () => "");
            utils.changeValue(state, "selected_contact2", () => "");
          },
        }}
      >
        {({ handleSubmit, submitting, values, form }) => (
          <form
            onSubmit={handleSubmit}
            // onKeyPress={(e) => {
            //   if (e.key === "Enter") {
            //     e.preventDefault();
            //   }
            // }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: primaryColor,
                  padding: "24px",
                  minWidth: "285px",
                }}
              >
                <Box style={{ flex: "1 0 auto" }}>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "white",
                      fontWeight: 600,
                      fontFamily: "Poppins",
                      paddingLeft: "12px",
                    }}
                  >
                    {title}
                  </Typography>

                  <Stepper
                    activeStep={page === 0 ? page : page - 1}
                    orientation="vertical"
                    style={{
                      backgroundColor: primaryColor,
                      paddingLeft: "12px",
                    }}
                  >
                    {props.steps.map((label) => {
                      return (
                        <Step
                          key={label}
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <StepLabel StepIconComponent={CustomStepIcon}>
                            {label}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>

                <Divider
                  style={{
                    marginBottom: "25px",
                    marginLeft: "-24px",
                    marginRight: "-24px",
                    backgroundColor: "#F6D285",
                  }}
                />

                <Button
                  variant="outlined"
                  style={{
                    color: "#FFFFFF",
                    marginTop: "auto",
                    flexShrink: "0",
                    borderColor: "#FFFFFF",
                    marginLeft: "12px",
                    marginRight: "12px",
                  }}
                  onClick={() => {
                    closeDialog();
                    reset();
                  }}
                >
                  Cancel
                </Button>
              </Box>

              <Box
                style={{
                  padding: "24px",
                  minWidth: "570px",
                }}
              >
                {activePage}
              </Box>
            </Box>

            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      </Form>
    </MuiThemeProvider>
  );
};

export default Wizard;
