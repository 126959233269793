import React, { useRef } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { GET_ADDITIONAL_RECIPIENTS } from "../queries/Project";

import { Typography } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { CircularProgress, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { openSnackbar } from "../reusable/Notifier";
import { primaryColor, secondaryColor } from "../assets/ThemeStyling";

import styled from "styled-components";

const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));

const DELETE_RECIPIENT = gql`
  mutation deleteAdditionalRecipient($_id: ID!) {
    deleteAdditionalRecipient(_id: $_id)
  }
`;

const AsynchronousAutocomplete = ({ input, meta, label }) => {
  const [
    fetchRecipients,
    { loading: additionalRecipientsLoading, data: additionalRecipientsData },
  ] = useLazyQuery(GET_ADDITIONAL_RECIPIENTS, {
    onError: () => {
      openSnackbar({
        message: "Failed to find recipients, please try again.",
        type: "error",
      });
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [deleteRecipient, { loading: deleteRecipientLoading }] = useMutation(
    DELETE_RECIPIENT,
    {
      refetchQueries: [
        {
          query: GET_ADDITIONAL_RECIPIENTS,
        },
      ],
      onError: () => {
        openSnackbar({
          message: "Error deleting recipient, please try again",
          type: "error",
        });
      },
      onCompleted: () => {
        openSnackbar({
          message: "Recipient deleted successfully",
          type: "success",
        });
      },
    }
  );

  //debounce
  const timeout = useRef();

  const doTheFetch = (variables) => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      fetchRecipients(variables);
    }, 1000);
  };

  return (
    <Autocomplete
      freeSolo
      filterOptions={(x) => x}
      value={input.value.emailAddress}
      onChange={(_event, value) => {
        input.onChange(value);
      }}
      inputValue={input.value.emailAddress}
      onInputChange={(_event, value) => {
        input.onChange(value);
        // console.log(value);
        doTheFetch({
          variables: {
            filter: value.emailAddress ? value.emailAddress : value,
          },
        });
      }}
      id="async-autocomplete"
      options={additionalRecipientsData?.getAdditionalRecipients || []}
      sx={{ width: "100%" }}
      // isOptionEqualToValue={(option, value) =>
      //   option.emailAddress === value.emailAddress
      // }
      getOptionLabel={(option) => option.emailAddress}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {option.emailAddress}
          </Typography>

          {!option.isUser && (
            <Tooltip title="Delete" placement="top">
              <DeleteIcon
                onClick={(event) => {
                  event.stopPropagation();
                  deleteRecipient({ variables: { _id: option._id } });
                }}
                style={{ fill: secondaryColor }}
              />
            </Tooltip>
          )}
        </li>
      )}
      renderInput={(params) => (
        <CssTextField
          {...params}
          required
          size="small"
          focusColor={primaryColor}
          label={label}
          error={meta.error && meta.touched}
          helperText={meta.error && meta.touched ? meta.error : ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {additionalRecipientsLoading || deleteRecipientLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{ marginRight: "6px" }}
                  />
                ) : (
                  <div style={{ width: "20px" }}>
                    {params.InputProps.endAdornment}
                  </div>
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AsynchronousAutocomplete;
