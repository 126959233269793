import React from "react";
import { useMutation } from "@apollo/client";

import { closeDialog } from "../../reusable/ScrollablePopup";
import { Grid, Divider, Typography, Button } from "@material-ui/core";

import { openSnackbar } from "../../reusable/Notifier";
import { UPDATE_EGNYTE_LINKS } from "../../queries/Project";

export default function ProjectUpdateNameModal({ job_no }) {
  const [updateEgnyteLinks, { loading }] = useMutation(UPDATE_EGNYTE_LINKS, {
    onCompleted: (data) => {
      openSnackbar({
        message: `Egnyte links updated.`,
        type: "success",
      });
    },
    onError: (error) => {
      openSnackbar({
        message: error.message,
        type: "error",
      });
    },
  });

  return (
    <Grid
      container
      spacing={3}
      style={{
        padding: "12px",
        margin: "0px",
        width: "100%",
        maxWidth: "800px",
      }}
    >
      <Grid item xs={12}>
        <Typography
          component="h2"
          variant="h5"
          style={{
            fontWeight: 600,
            fontFamily: "Poppins",
          }}
        >
          Update Egnyte Links
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography>
          Are you sure you want to update the Egnyte links for this project?
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Divider
          style={{
            marginLeft: "-24px",
            marginRight: "-24px",
            backgroundColor: "#C3CAD8",
          }}
        />
      </Grid>

      <Grid
        item
        xs
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "12px",
          paddingRight: "12px",
        }}
      >
        <Button variant="outlined" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => {
            updateEgnyteLinks({
              variables: { job_no: job_no },
            });
          }}
        >
          Yes
        </Button>
      </Grid>
    </Grid>
  );
}
