import gql from "graphql-tag";

export const GET_CONTACT = gql`
  query getContact($contactId: ID!) {
    getContact(contactId: $contactId) {
      _id
      contact_name
      contact_email
      contact_number
      contact_position
      contact_company
      contact_status
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $_id: String!
    $contact_company: Int!
    $contact_name: String!
    $contact_email: String
    $contact_number: String
    $contact_position: String
    $contact_status: String
  ) {
    updateContact(
      _id: $_id
      contact_company: $contact_company
      contact_name: $contact_name
      contact_email: $contact_email
      contact_number: $contact_number
      contact_position: $contact_position
      contact_status: $contact_status
    ) {
      _id
      contact_company
      contact_name
      contact_email
      contact_number
      contact_position
      contact_status
    }
  }
`;

export const CREATE_CONTACT = gql`
  mutation newContact(
    $contact_name: String!
    $contact_email: String
    $contact_number: String
    $contact_position: String
    $contact_company: String
  ) {
    newContact(
      contact_name: $contact_name
      contact_email: $contact_email
      contact_number: $contact_number
      contact_position: $contact_position
      contact_company: $contact_company
    ) {
      _id
      contact_name
      contact_email
      contact_position
      contact_number
      contact_company
    }
  }
`;

export const GET_COMPANY = gql`
  query getCompany($companyId: ID!) {
    getCompany(companyId: $companyId) {
      _id
      name
      date_acquired
      status_text
    }
  }
`;

export const GET_COMPANIES = gql`
  query companies($name: String, $statusActive: Boolean) {
    companies(name: $name, statusActive: $statusActive) {
      _id
      id
      name
      date_acquired
      status_text
    }
  }
`;

export const contactRoles = [
  "Account Manager",
  "Director",
  "Project Manager",
  "Operations Manager",
  "Buildings Manager",
  "Sales Manager",
  "Health And Safety",
  "Other",
];

export const GET_CONTACTS = gql`
  query contacts($contact_company: String) {
    contacts(contact_company: $contact_company) {
      _id
      contact_name
      contact_position
      contact_number
      contact_email
    }
  }
`;

export const GET_CUSTOMERS_ORDERS = gql`
  query customer($_id: String!) {
    customer(_id: $_id) {
      _id
      first_name
      last_name
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query customers($from: String, $to: String) {
    customers(from: $from, to: $to) {
      _id
      first_name
      last_name
      title
      email
      address_line_1
      address_line_2
      address_city
      address_county
      address_postcode
      phone
      mobile
      preferred_contact
      customer_type
      created_date
    }
  }
`;

export const GET_CUSTOMER = gql`
  query customer($_id: String!) {
    customer(_id: $_id) {
      _id
      first_name
      last_name
      title
      email
      address_line_1
      address_line_2
      address_city
      address_county
      address_postcode
      phone
      mobile
      preferred_contact
      customer_type
      created_date
      marketing_news
      marketing_offers
      created_by {
        _id
        first_name
        last_name
        role_id
      }
      updated_date
      updated_by {
        _id
        first_name
        last_name
        role_id
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany(
    $_id: ID!
    $name: String
    $date_acquired: DateTime
    $status_text: String
  ) {
    updateCompany(
      _id: $_id
      name: $name
      date_acquired: $date_acquired
      status_text: $status_text
    ) {
      _id
      name
      date_acquired
      status_text
    }
  }
`;

export const ADD_COMPANY = gql`
  mutation addCompany(
    $name: String!
    $date_acquired: DateTime
    $status_text: String
  ) {
    addCompany(
      name: $name
      date_acquired: $date_acquired
      status_text: $status_text
    ) {
      _id
      name
      date_acquired
      status_text
    }
  }
`;

export const GET_AGENT_CUSTOMERS = gql`
  query customers($created_by: String, $from: String, $to: String) {
    customers(created_by: $created_by, from: $from, to: $to) {
      _id
      created_date
    }
  }
`;

export const GET_STORES_CUSTOMERS = gql`
  query customers($manager_id: String!, $from: String, $to: String) {
    customers(manager_id: $manager_id, from: $from, to: $to) {
      _id
      created_date
    }
  }
`;

export const ADD_CUSTOMER_NOTE = gql`
  mutation ($customer_id: String!, $content: String!, $agent_id: String!) {
    addCustomerNote(
      customer_id: $customer_id
      content: $content
      agent_id: $agent_id
    ) {
      error {
        message
      }
      customer_note {
        _id
        customer {
          _id
          first_name
          last_name
        }
        content
        created_date
        created_by {
          _id
          first_name
          last_name
        }
      }
    }
  }
`;
