import React from "react";

import ProjectOverviewWizardStep1 from "./ProjectOverviewWizardStep1";
const ProjectOverviewModal = ({ project, onSaveClicked }) => {
  return (
    <ProjectOverviewWizardStep1
      project={project}
      onSaveClicked={onSaveClicked}
    />
  );
};

export default ProjectOverviewModal;
