import React, { useContext, useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, Query } from "@apollo/react-components";
import { useParams } from "react-router-dom";
import { Form } from "react-final-form";
import validator from "validator";

import { UserContext } from "../../utils/PageWrapper";
import UserEdit from "./UserEdit";
import { openSnackbar } from "../../reusable/Notifier";
import Loading from "../../reusable/Loading";
import ActivityTable from "../../reusable/ActivityTable";
import { closeModal, openModal } from "../../reusable/Popup";
import Error from "../../uicomponents/Error";
import FileUpload from "../../utils/FileUpload";

import {
  UPDATE_USER,
  GET_USER,
  ARCHIVE_USER,
  UNARCHIVE_USER,
  GET_USERS,
  FORGOT_PASSWORD,
  USER_UPLOAD_AVATAR,
} from "../../queries/User";

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  CardActions,
  IconButton,
  CardHeader,
} from "@material-ui/core";

import { Clear, Save } from "@material-ui/icons";

const GET_ACTIVITIES = gql`
  query getActivities($userId: ID) {
    getActivities(userId: $userId) {
      _id
      time
      content
      user {
        _id
        first_name
        last_name
      }
    }
  }
`;

const REMOVE_AVATAR = gql`
  mutation removeAvatarUser($userId: String!) {
    removeAvatarUser(userId: $userId) {
      _id
    }
  }
`;

function SendPasswordReset({ email }) {
  return (
    <Mutation mutation={FORGOT_PASSWORD}>
      {(forgotPassword) => (
        <Button
          variant="contained"
          style={{
            marginLeft: "auto",
            backgroundColor: "red",
            color: "white",
          }}
          color="secondary"
          onClick={() => {
            openModal({
              content: (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="Close Pop-up"
                        onClick={closeModal}
                      >
                        <Clear />
                      </IconButton>
                    }
                    title="Are You Sure?"
                  />
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      Are you sure you want to send this user a password reset
                      email?
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        forgotPassword({
                          variables: {
                            admin: true,
                            email: email,
                          },
                        })
                          .then(() => {
                            openSnackbar({
                              message: "Reset password email sent to " + email,
                              type: "success",
                            });
                            closeModal();
                          })
                          .catch((error) => {
                            openSnackbar({
                              message:
                                "Failed to send reset password email, please try again",
                              type: "error",
                            });
                            closeModal();
                          });
                      }}
                    >
                      Continue
                    </Button>
                  </CardActions>
                </Card>
              ),
            });
          }}
        >
          Send Password Reset
        </Button>
      )}
    </Mutation>
  );
}

function RemoveAvatar({ userId, setAvatar }) {
  return (
    <Mutation mutation={REMOVE_AVATAR}>
      {(removeAvatarUser) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            openModal({
              content: (
                <Card>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="Close Pop-up"
                        onClick={closeModal}
                      >
                        <Clear />
                      </IconButton>
                    }
                    title="Are You Sure?"
                  />
                  <CardContent>
                    <Typography variant="body1" gutterBottom>
                      Are you sure you want to delete an avatar for this user?
                    </Typography>
                  </CardContent>
                  <CardActions
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        removeAvatarUser({
                          variables: {
                            userId: userId,
                          },
                        })
                          .then(() => {
                            setAvatar("");
                            openSnackbar({
                              message: "User's avatar removed",
                              type: "success",
                            });
                            closeModal();
                          })
                          .catch((error) => {
                            openSnackbar({
                              message:
                                "Failed removing the user's avatar, please try again",
                              type: "error",
                            });
                            closeModal();
                          });
                      }}
                    >
                      Continue
                    </Button>
                  </CardActions>
                </Card>
              ),
            });
          }}
        >
          Remove User Avatar
        </Button>
      )}
    </Mutation>
  );
}

export default function UserView() {
  const currentUser = useContext(UserContext);
  const params = useParams();
  const { userId } = params;
  const [avatar, setAvatar] = useState("");
  const [uploadAvatar] = useMutation(USER_UPLOAD_AVATAR, {
    onError: (err) => {
      console.log(err);
    },
    onCompleted: (args) => {
      setAvatar(args.userUploadAvatar.avatar);
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      const { updateUser } = data;

      openSnackbar({
        message:
          updateUser.first_name +
          " " +
          updateUser.last_name +
          " has been successfully updated",
        type: "success",
      });
    },
  });

  const uploadOrderFile = (args) => {
    //right we can call a mutation to set this as the avatar for the user
    uploadAvatar({
      variables: {
        _id: args.id,
        userId: userId,
      },
    });
  };

  const {
    loading: activitiesLoading,
    error: activitiesError,
    data: activitiesData,
  } = useQuery(GET_ACTIVITIES, {
    variables: { userId: userId },
  });

  if (activitiesLoading) return <Loading />;

  if (activitiesError) return <Error />;

  const activities = activitiesData.getActivities || [];
  return (
    <Query
      query={GET_USER}
      variables={{
        userId: userId,
      }}
    >
      {({ loading, error, data }) => {
        if (error) {
          return null;
        }

        if (loading) {
          return <Loading />;
        }

        const { user } = data;

        if (user.avatar && user.avatar.length > 0) {
          setAvatar(user.avatar);
        }

        return (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography component="h1" variant="h4" gutterBottom>
                {`Edit User - ${user.first_name} ${user.last_name}`}
              </Typography>
              {userId !== currentUser._id && (
                <>
                  {user.archived ? (
                    <Mutation
                      mutation={UNARCHIVE_USER}
                      refetchQueries={[
                        {
                          query: GET_USERS,
                        },
                      ]}
                    >
                      {(unArchiveUser) => (
                        <Button
                          variant="contained"
                          style={{
                            marginLeft: "auto",
                            backgroundColor: "red",
                            color: "white",
                          }}
                          color="secondary"
                          onClick={() => {
                            unArchiveUser({
                              variables: {
                                userId: userId,
                              },
                            })
                              .then(({ data }) => {
                                const { unArchiveUser } = data;

                                openSnackbar({
                                  message:
                                    unArchiveUser.first_name +
                                    " " +
                                    unArchiveUser.last_name +
                                    " has been successfully unarchived",
                                  type: "success",
                                });
                                closeModal();
                              })
                              .catch(() => {
                                openSnackbar({
                                  message:
                                    "Failed to unarchive user, please try again",
                                  type: "error",
                                });
                                closeModal();
                              });
                          }}
                        >
                          Unarchive User
                        </Button>
                      )}
                    </Mutation>
                  ) : (
                    <>
                      <SendPasswordReset email={user.email} />
                      <Mutation
                        mutation={ARCHIVE_USER}
                        refetchQueries={[
                          {
                            query: GET_USERS,
                          },
                        ]}
                      >
                        {(archiveUser) => (
                          <Button
                            variant="contained"
                            style={{
                              marginLeft: "auto",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            color="secondary"
                            onClick={() => {
                              openModal({
                                content: (
                                  <Card>
                                    <CardHeader
                                      action={
                                        <IconButton
                                          aria-label="Close Pop-up"
                                          onClick={closeModal}
                                        >
                                          <Clear />
                                        </IconButton>
                                      }
                                      title="Are You Sure?"
                                    />
                                    <CardContent>
                                      <Typography variant="body1" gutterBottom>
                                        Archiving a user can be undone.
                                      </Typography>
                                    </CardContent>
                                    <CardActions
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </Button>

                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                          archiveUser({
                                            variables: {
                                              userId: userId,
                                            },
                                          })
                                            .then(({ data }) => {
                                              const { archiveUser } = data;

                                              openSnackbar({
                                                message:
                                                  archiveUser.first_name +
                                                  " " +
                                                  archiveUser.last_name +
                                                  " has been successfully archived",
                                                type: "success",
                                              });
                                              closeModal();
                                            })
                                            .catch((error) => {
                                              openSnackbar({
                                                message:
                                                  "Failed to archive user, please try again",
                                                type: "error",
                                              });
                                              closeModal();
                                            });
                                        }}
                                      >
                                        Continue
                                      </Button>
                                    </CardActions>
                                  </Card>
                                ),
                              });
                            }}
                          >
                            Archive User
                          </Button>
                        )}
                      </Mutation>
                    </>
                  )}
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Form
                    initialValues={{
                      ...user,
                      firstName: user.first_name,
                      lastName: user.last_name,
                      roleId: user.role_id,
                      receive_all_project_emails:
                        user.receive_all_project_emails ? true : false,
                      receive_activity_alert_emails:
                        user.receive_activity_alert_emails ? true : false,
                      do_not_show_on_activity_reports:
                        user.do_not_show_on_activity_reports ? true : false,
                    }}
                    onSubmit={async (values) => {
                      const response = await updateUser({
                        variables: { ...values, userId: user._id },
                      }).catch((error) => {
                        if (
                          error?.graphQLErrors[0]?.message ===
                          "Email already used."
                        )
                          return {
                            email: "Email already used.",
                          };
                        else {
                          openSnackbar({
                            message: "Failed to update user, please try again.",
                            type: "error",
                          });
                        }
                      });

                      if (!response?.data) return response;
                    }}
                    validate={(values) => {
                      const errors = {};

                      const requiredFields = [
                        "firstName",
                        "lastName",
                        "email",
                        "roleId",
                      ];

                      requiredFields.forEach((requiredField) => {
                        if (!values[requiredField]) {
                          errors[requiredField] = "Required";
                        }
                      });

                      if (
                        values.email &&
                        !validator.isEmail(values.email) &&
                        !values.email.includes(".con") &&
                        !values.email.includes(".coma")
                      ) {
                        errors.email = "Invalid Email";
                      }

                      return errors;
                    }}
                    render={({
                      handleSubmit,
                      pristine,
                      invalid,
                      submitting,
                      values,
                      error,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <UserEdit
                          archived={user.archived}
                          submitting={submitting}
                        />

                        {!user.archived && (
                          <Grid container spacing={3} justifyContent="center">
                            <Grid item xs={12}>
                              <Button
                                variant="contained"
                                color="primary"
                                disabled={pristine || invalid || submitting}
                                type="submit"
                              >
                                <Save style={{ marginRight: "10px" }} />
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        )}

                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="body1">
                    Use the button below to upload a new avatar.
                  </Typography>

                  <FileUpload uploadFileFunction={uploadOrderFile} />

                  {avatar.length > 0 ?? (
                    <img
                      src={"data:image/png;base64," + avatar}
                      alt="avatar"
                      style={{ height: "29px", marginRight: "12px" }}
                    />
                  )}

                  <RemoveAvatar userId={userId} setAvatar={setAvatar} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <ActivityTable title="Activity" activities={activities} />
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
}
