import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import React from "react";
import { Field, Form } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";
import {
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { projectStatuses } from "../../queries/Project";
import { statusLabel } from "../../assets/GlobalLabels";

export default function ProjectDetailsWizardStep1({ project, onSaveClicked }) {
  return (
    <Form
      initialValues={project}
      onSubmit={() => {}}
      validate={(values) => {
        const errors = {};

        if (!values.address_1) {
          errors.address_1 = "Required";
        }
        if (!values.city) {
          errors.city = "Required";
        }
        if (!values.postcode) {
          errors.postcode = "Required";
        }

        // if (!values.start_on_site) {
        //   errors.start_on_site = "Required";
        // }
        // if (!values.est_handover_date) {
        //   errors.postcode = "Required";
        // }
        if (!values.project_status_text) {
          errors.project_status_text = "Required";
        }

        return errors;
      }}
    >
      {({
        handleSubmit,
        submitting,
        values,
        // form: {
        //   mutators: { push, pop },
        // },
      }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Project Details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Field name="project_status_text">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                  label={statusLabel}
                  fullWidth
                  required
                  variant="outlined"
                  // style={{ paddingBottom: "15px" }}
                >
                  {projectStatuses.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="start_on_site">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    clearable={true}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label="Start on Site"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="est_handover_date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required={false}
                    clearable={true}
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    /*maxDate={moment(values.depositDate).add(
                    1,
                    values.orderType === 'weekly' ? 'weeks' : 'months'
                  )}
                  minDate={moment(values.depositDate).add(1, 'days')}*/
                    label="Handover"
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <Field name="address_1">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Address 1"
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="address_2">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Address 2"
                  fullWidth
                  // required
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="city">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="City"
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Field name="postcode">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  label="Postcode"
                  fullWidth
                  required
                  size="small"
                  variant="outlined"
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={submitting}
              type="submit"
              onClick={() => {
                onSaveClicked(values);
              }}
            >
              Save
            </Button>
          </Grid>

          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </Grid>
      )}
    </Form>
  );
}
