import React, { Fragment } from "react";
import { Form } from "react-final-form";
import { Mutation, ApolloConsumer } from "@apollo/react-components";

import validator from "validator";
import { GET_USERS, NEW_USER } from "../../queries/User";

import { Typography, Card, CardContent, Grid, Button } from "@material-ui/core";
import { openSnackbar } from "../../reusable/Notifier";
import UserEdit from "./UserEdit";

import { Save } from "@material-ui/icons";

export default function NewUser({ history }) {
  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Typography component="h1" variant="h4">
          New User
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Mutation
              mutation={NEW_USER}
              refetchQueries={[
                {
                  query: GET_USERS,
                },
              ]}
            >
              {(newUser) => (
                <Fragment>
                  <ApolloConsumer>
                    {(client) => (
                      <Form
                        onSubmit={(values) => {
                          newUser({
                            variables: values,
                          })
                            .then(({ data }) => {
                              const { newUser } = data;

                              openSnackbar({
                                message:
                                  newUser.first_name +
                                  " " +
                                  newUser.last_name +
                                  " has been successfully created",
                                type: "success",
                              });

                              history.push(
                                `/admin-settings/users/edit/${newUser._id}`
                              );
                            })
                            .catch(({ message }) => {
                              openSnackbar({
                                message: message.replace("GraphQL error: ", ""),
                                type: "error",
                              });
                            });
                        }}
                        validate={(values) => {
                          const errors = {};

                          const requiredFields = [
                            "firstName",
                            "lastName",
                            "email",
                            "roleId",
                          ];

                          requiredFields.forEach((requiredField) => {
                            if (!values[requiredField]) {
                              errors[requiredField] = "Required";
                            }
                          });

                          if (
                            values.email &&
                            !validator.isEmail(values.email) &&
                            !values.email.includes(".con") &&
                            !values.email.includes(".coma")
                          ) {
                            errors.email = "Invalid Email";
                          }

                          return errors;
                        }}
                        render={({
                          handleSubmit,
                          pristine,
                          invalid,
                          submitting,
                          values,
                        }) => (
                          <form onSubmit={handleSubmit} noValidate>
                            <UserEdit />
                            <Grid container spacing={3} justifyContent="center">
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={pristine || invalid || submitting}
                                  type="submit"
                                >
                                  <Save style={{ marginRight: "10px" }} />
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                          </form>
                        )}
                      />
                    )}
                  </ApolloConsumer>
                </Fragment>
              )}
            </Mutation>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
