import React from "react";
import { Field, Form } from "react-final-form";
import { Button, Card, Divider, Grid, Typography } from "@material-ui/core";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { closeDialog } from "./ScrollablePopup";
import { CSVLink } from "react-csv";
import { useRef } from "react";

const handleSubmit = () => {};
//main component
const DownloadCsv = ({ csvData }) => {
  // ref is utilised in the CSVLink component, and in the handleDownload function.
  const csvLinkRef = useRef();

  //called in csv link
  const handleDownload = () => {
    console.log("downloaded");
    csvLinkRef.current.link.click();
  };
  return (
    <Card
      style={{
        padding: "0",
        border: "none",
        boxShadow: "none",
        borderRadius: "8px",
      }}
    >
      <Form initialValues={{}} onSubmit={handleSubmit}>
        {({
          handleSubmit,
          submitting,
          values,
          // form: {
          //   mutators: { push, pop },
          // },
        }) => (
          <>
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ padding: "2em" }}
            >
              <div style={{ width: "100%", textAlign: "left" }}>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    textAlign: "left",
                    padding: "0.5em",
                  }}
                >
                  <VisibilityOffRoundedIcon style={{ marginRight: "10px" }} />
                  Download CSV File
                </Typography>
              </div>
              <div style={{ width: "100%", textAlign: "left" }}>
                <Typography
                  variant="body1"
                  style={{
                    textAlign: "left",
                    marginTop: "2rem",
                    fontWeight: "400",
                    paddingLeft: "0.5em",
                  }}
                >
                  This file contains sensitive information. Are you sure you
                  wish to proceed?
                </Typography>
              </div>
            </Grid>
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs={12}>
                <Divider
                  style={{
                    // marginTop: "25px",
                    marginBottom: "12px",
                    marginLeft: "-12px",
                    marginRight: "-24px",
                    backgroundColor: "#C3CAD8",
                  }}
                />
              </Grid>
              <Grid
                item
                xs
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "13px",
                  marginBottom: "12px",
                  paddingLeft: "0.75em",
                  paddingRight: "0.75em",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    closeDialog();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    handleDownload();
                    closeDialog();
                  }}
                  color="primary"
                >
                  Download CSV
                </Button>
                <CSVLink
                  data={csvData}
                  filename="contacts.csv"
                  ref={csvLinkRef}
                  style={{ display: "none" }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Form>
    </Card>
  );
};

export default DownloadCsv;
