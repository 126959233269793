import React from "react";

import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { openSnackbar } from "../../reusable/Notifier";

import { Form, Field } from "react-final-form";
import { closeDialog } from "../../reusable/ScrollablePopup";

import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import {
  roles,
  filterOnRole,
  GET_USERS,
  filterHidden,
  sortByName,
} from "../../queries/User";
import { leadLabel } from "../../assets/GlobalLabels";
import { GET_PROJECT } from "../../queries/Project";

const handleSubmit = () => {};

const ADD_PROJECT_USER = gql`
  mutation addProjectUser(
    $project_id: String!
    $user_id: String!
    $role: String!
    $lead: Boolean!
  ) {
    addProjectUser(
      project_id: $project_id
      user_id: $user_id
      role: $role
      lead: $lead
    ) {
      _id
    }
  }
`;
const ProjectPeopleAddUserModal = (props) => {
  const [addProjectUser, { adding, addingError, addingData }] = useMutation(
    ADD_PROJECT_USER,
    {
      onCompleted: () => {
        openSnackbar({
          message: "Staff have been added.",
          type: "success",
        });

        props.onAdded();
        closeDialog();
      },
      onError: (error) => {
        console.log(error);
        openSnackbar({
          message: "Failed to add staff.",
          type: "error",
        });
      },
      refetchQueries: [
        {
          query: GET_PROJECT,

          variables: { project_id: props.projectId.toString() },
        },
      ],
    }
  );

  const { loading, error, data } = useQuery(GET_USERS);
  // console.log("all possible users", data);
  // console.log("project users", props);

  if (loading || data == null) {
    return <Loading />;
  }
  if (error) {
    return <ErrorMessage />;
  }
  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{
        setLeadChecked: (args, state, utils) => {
          utils.changeValue(state, "lead", () => args[0]);
        },
      }}
    >
      {({
        handleSubmit,
        submitting,
        values,
        form: {
          mutators: { setLeadChecked },
        },
      }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              Add Staff
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Field name="role">
              {({ input, meta }) => (
                <TextField
                  // {...input}
                  onChange={(e) => {
                    input.onChange(e.target.value);
                    // console.log("target", e.target);

                    if (
                      !props.projectUsers.getProjectUsers.find(
                        (projectUser) => projectUser.role === e.target.value
                      )
                    ) {
                      setLeadChecked(true);
                    }
                  }}
                  select
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                  label="Select Role"
                  fullWidth
                  // required
                  variant="outlined"
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid item xs={5}>
            <Field name="user_id">
              {({ input, meta, ...rest }) => (
                <TextField
                  {...input}
                  select
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                  label="Select Staff Member"
                  fullWidth
                  // required
                  variant="outlined"
                >
                  {data.users
                    .filter(filterHidden)
                    .filter(filterOnRole(values.role))
                    .sort(sortByName)
                    .map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {`${user.first_name} ${user.last_name}`}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Field>
          </Grid>

          <Grid
            item
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Field name="lead" type="checkbox">
              {({ input }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      // {...input}

                      onChange={input.onChange}
                      checked={input.checked}
                    />
                  }
                  label={leadLabel}
                />
              )}
            </Field>
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                values.project_id = props.project_id;

                if (values.lead == null) {
                  values.lead = false;
                }

                addProjectUser({ variables: values });
              }}
              disabled={
                submitting || values.role == null || values.user_id == null
              }
              type="submit"
            >
              Save
            </Button>
          </Grid>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </Grid>
      )}
    </Form>
  );
};

export default ProjectPeopleAddUserModal;
