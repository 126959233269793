import React from "react";

import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Field, Form, FormSpy, useForm } from "react-final-form";
import { Box } from "@mui/system";

import { openSnackbar } from "../../reusable/Notifier";

import { useStore } from "./WizPageStore";

import PageSwitch from "./PageSwitch";
import { Grid, TextField, Typography } from "@material-ui/core";
import {
  clientNameLabel,
  contactNumberLabel,
  emailAddressLabel,
} from "../../assets/GlobalLabels";

const NEW_COMPANY_AND_CONTACT = gql`
  mutation newCompanyAndContact(
    $name: String
    $contact_name: String
    $contact_email: String
    $contact_number: String
    $contact_position: String
  ) {
    newCompanyAndContact(
      name: $name
      contact_name: $contact_name
      contact_email: $contact_email
      contact_number: $contact_number
      contact_position: $contact_position
    ) {
      newCompany {
        id
        name
      }
      newContact {
        _id
        contact_name
        contact_email
        contact_number
        contact_position
      }
    }
  }
`;

export default function CompanyDetails() {
  // const formState = useFormState();
  const formHook = useForm();
  // console.log("formHook", formHook);

  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);

  const [newCompany, { loading, error, data }] = useMutation(
    NEW_COMPANY_AND_CONTACT,
    {
      onCompleted: ({ newCompanyAndContact }) => {
        openSnackbar({
          message: `Customer ${newCompanyAndContact.newCompany.name} created.`,
          type: "success",
        });

        // console.log("newCompany", newCompanyAndContact);

        formHook.mutators.setCompanyDetails(
          newCompanyAndContact.newCompany.id,
          newCompanyAndContact.newCompany.name
        );

        formHook.mutators.setProjectContact([
          {
            ...newCompanyAndContact.newContact,
            contact_role: newCompanyAndContact.newContact.contact_position,
          },
        ]);

        next();
      },

      onError: (error) => {
        console.log(error);
        openSnackbar({
          message: "Failed to create company.",
          type: "error",
        });
      },
    }
  );

  // console.log("newCompany data", data);

  return (
    <FormSpy
    //  subscription={{ values: true, pristine: true, submitting: true }}
    >
      {({
        // values,
        // pristine,
        // submitting,
        form: {
          mutators: { setCompanyDetails },
        },
      }) => (
        <Form
          onSubmit={() => {}}
          initialValues={{}}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }

            return errors;
          }}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            valid,
            invalid,
          }) => {
            return (
              <form id="myForm" onSubmit={handleSubmit}>
                <Box
                  //main container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Grid
                    //content container
                    container
                    spacing={3}
                    style={{ flex: "1 0 auto" }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        component="h2"
                        variant="h5"
                        style={{
                          fontWeight: 600,
                          fontFamily: "Poppins",
                        }}
                      >
                        Create New Client
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Field name="name">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            // inputRef={(input) => input && input.focus()}
                            label={clientNameLabel}
                            fullWidth
                            required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={7}>
                      <Typography variant="body1">
                        Primary Contact on this project
                      </Typography>
                    </Grid>

                    <Grid item xs={7}>
                      <Field name="first_name">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="First Name"
                            fullWidth
                            // required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={7}>
                      <Field name="last_name">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Last Name"
                            fullWidth
                            // required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={7}>
                      <Field name="job_role">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label="Job Role"
                            fullWidth
                            // required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={7}>
                      <Field name="contact_number">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label={contactNumberLabel}
                            fullWidth
                            // required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={7}>
                      <Field name="contact_email">
                        {({ input, meta }) => (
                          <TextField
                            {...input}
                            label={emailAddressLabel}
                            fullWidth
                            // required
                            size="small"
                            variant="outlined"
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                        )}
                      </Field>
                    </Grid>
                  </Grid>

                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}

                  {/* <Grid container style={{ marginTop: "calc(5%)" }}>
                    <Grid item xs={12}>
                      <Divider
                        style={{
                          // marginTop: "25px",
                          marginBottom: "12px",
                          marginLeft: "-12px",
                          marginRight: "-24px",
                          backgroundColor: "#C3CAD8",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "13px",
                        marginBottom: "12px",
                        marginLeft: "24px",
                        marginRight: "24px",
                      }}
                    >
                      <Button
                        // disabled={true}
                        onClick={previous}
                        variant="outlined"
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        // type="submit"
                        onClick={() => {
                          form.focus("name");

                          // console.log("valid", valid);
                          // console.log("invalid", invalid);
                          // console.log("form", form);

                          if (valid) {
                            newCompany({
                              variables: {
                                name: values.name,

                                contact_name:
                                  values.first_name + " " + values.last_name,
                                contact_email: values.contact_email,
                                contact_number: values.contact_number,
                                contact_position: values.job_role,
                              },
                            });
                          }
                        }}
                        disabled={
                          submitting
                          // || !values.name
                          //  ||
                          // !values.first_name ||
                          // !values.last_name ||
                          // !values.job_role ||
                          // !values.contact_number ||
                          // !values.contact_email
                        }
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid> */}

                  <PageSwitch
                    disabled={submitting}
                    onClick={() => {
                      form.focus("name");

                      if (valid) {
                        newCompany({
                          variables: {
                            name: values.name,

                            contact_name:
                              values.first_name + " " + values.last_name,
                            contact_email: values.contact_email,
                            contact_number: values.contact_number,
                            contact_position: values.job_role,
                          },
                        });
                      }
                    }}
                  />
                </Box>
              </form>
            );
          }}
        />
      )}
    </FormSpy>
  );
}
