import styled from 'styled-components'
import React from 'react'
import Icon from '@mdi/react'
import { rgba } from 'polished'

const StyledIconButton = styled.button`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: ${({ disabled }) => {
    if (disabled) return 'not-allowed'

    return 'pointer'
  }};
`

export default function IconButton({
  disabled,
  onClick,
  size,
  color,
  dropdownTrigger,
  ...rest
}) {
  //claculate colour if disabled
  let colour = color

  if (disabled) {
    colour = rgba(colour, 0.4)
  }
  return (
    <StyledIconButton
      disabled={disabled}
      onClick={onClick}
      size={size}
      type="button"
      dropdownTrigger={dropdownTrigger ? 1 : 0}
    >
      <Icon size={size} color={colour} {...rest} />
    </StyledIconButton>
  )
}
