import gql from "graphql-tag";

export const roles = [
  "Contract Manager",
  "Design",
  "Furniture",
  "Project Coordinator",
  "Project Manager",
  "QS",
  "Site Foreman",
  "Health And Safety",
];

export const rolesToFields = {
  "Project Coordinator": "is_coordinator",
  "Contract Manager": "is_acct_manager",
  Design: "is_designer",
  Furniture: "is_furniture",
  "Project Manager": "is_project_manager",
  QS: "is_qs",
  "Site Foreman": "is_foreman",
  "Health And Safety": "is_health_and_safety",
};

export const GET_USER = gql`
  query user($userId: String!) {
    user(userId: $userId) {
      _id
      first_name
      last_name
      role_id
      email
      created_date
      updated_date
      archived
      hideFromAddToProject
      mobile
      is_foreman
      is_designer
      is_acct_manager
      is_qs
      is_project_manager
      is_coordinator
      avatar
      is_health_and_safety
      is_furniture
      receive_all_project_emails
      receive_activity_alert_emails
      do_not_show_on_activity_reports
    }
  }
`;

export function filterHidden(user) {
  return user.hideFromAddToProject !== true && user.archived !== true;
}
export function filterHiddenFromActivityReports(user) {
  return user.do_not_show_on_activity_reports !== true;
}

export function filterOnRole(role) {
  return (user) => {
    if (role == null) {
      return false;
    }
    const field = rolesToFields[role];
    if (field == null) {
      return false;
    }
    return user[field];
  };
}

export function sortByName(a, b) {
  const name1 = a.first_name.toUpperCase() + " " + a.last_name.toUpperCase();
  const name2 = b.first_name.toUpperCase() + " " + b.last_name.toUpperCase();
  return name1.localeCompare(name2);
}

export const GET_USERS = gql`
  query users {
    users {
      _id
      first_name
      last_name
      role_id
      email
      created_date
      archived
      updated_date
      hideFromAddToProject
      is_foreman
      is_designer
      is_acct_manager
      is_qs
      is_project_manager
      is_coordinator
      is_health_and_safety
      is_furniture
      receive_activity_alert_emails
      do_not_show_on_activity_reports
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ($email: String!, $admin: Boolean) {
    forgotPassword(email: $email, admin: $admin) {
      _id
    }
  }
`;

export const PASSWORD_RESET_ALLOWED = gql`
  query passwordResetAllowed($resetToken: String!) {
    passwordResetAllowed(resetToken: $resetToken) {
      user {
        _id
      }
      error {
        message
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $userId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $roleId: Int!
    $hideFromAddToProject: Boolean
    $mobile: String!
    $is_foreman: Boolean
    $is_designer: Boolean
    $is_acct_manager: Boolean
    $is_qs: Boolean
    $is_project_manager: Boolean
    $is_coordinator: Boolean
    $is_health_and_safety: Boolean
    $is_furniture: Boolean
    $receive_all_project_emails: Boolean
    $receive_activity_alert_emails: Boolean
    $do_not_show_on_activity_reports: Boolean
  ) {
    updateUser(
      userId: $userId
      firstName: $firstName
      lastName: $lastName
      email: $email
      roleId: $roleId
      hideFromAddToProject: $hideFromAddToProject
      mobile: $mobile
      is_foreman: $is_foreman
      is_designer: $is_designer
      is_acct_manager: $is_acct_manager
      is_qs: $is_qs
      is_project_manager: $is_project_manager
      is_coordinator: $is_coordinator
      is_health_and_safety: $is_health_and_safety
      is_furniture: $is_furniture
      receive_all_project_emails: $receive_all_project_emails
      receive_activity_alert_emails: $receive_activity_alert_emails
      do_not_show_on_activity_reports: $do_not_show_on_activity_reports
    ) {
      _id
      first_name
      last_name
      email
      updated_date
      hideFromAddToProject
      role_id
      mobile
      is_foreman
      is_designer
      is_acct_manager
      is_qs
      is_project_manager
      is_coordinator
      is_health_and_safety
      is_furniture
      receive_all_project_emails
      receive_activity_alert_emails
      do_not_show_on_activity_reports
    }
  }
`;

export const UPDATE_USER_PREFERENCES = gql`
  mutation ($_id: ID!, $preferences: JSON!) {
    updateUserPreferences(_id: $_id, preferences: $preferences) {
      _id
      preferences
    }
  }
`;

export const NEW_USER = gql`
  mutation (
    $email: String!
    $firstName: String!
    $lastName: String!
    $roleId: Int!
    $mobile: String
    $hideFromAddToProject: Boolean
    $receive_all_project_emails: Boolean
    $receive_activity_alert_emails: Boolean
    $do_not_show_on_activity_reports: Boolean
    $is_foreman: Boolean
    $is_designer: Boolean
    $is_acct_manager: Boolean
    $is_qs: Boolean
    $is_project_manager: Boolean
    $is_coordinator: Boolean
    $is_health_and_safety: Boolean
    $is_furniture: Boolean
  ) {
    newUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      roleId: $roleId
      mobile: $mobile
      hideFromAddToProject: $hideFromAddToProject
      receive_all_project_emails: $receive_all_project_emails
      receive_activity_alert_emails: $receive_activity_alert_emails
      do_not_show_on_activity_reports: $do_not_show_on_activity_reports
      is_foreman: $is_foreman
      is_designer: $is_designer
      is_acct_manager: $is_acct_manager
      is_qs: $is_qs
      is_project_manager: $is_project_manager
      is_coordinator: $is_coordinator
      is_health_and_safety: $is_health_and_safety
      is_furniture: $is_furniture
    ) {
      _id
      first_name
      last_name
    }
  }
`;

export const ARCHIVE_USER = gql`
  mutation ($userId: String!) {
    archiveUser(userId: $userId) {
      _id
      first_name
      last_name
      archived
    }
  }
`;

export const UNARCHIVE_USER = gql`
  mutation ($userId: String!) {
    unArchiveUser(userId: $userId) {
      _id
      first_name
      last_name
      archived
    }
  }
`;

export const USER_UPLOAD_AVATAR = gql`
  mutation userUploadAvatar($_id: ID!, $userId: ID!) {
    userUploadAvatar(_id: $_id, userId: $userId) {
      avatar
    }
  }
`;
