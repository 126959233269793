import React from "react";
import { Query, Mutation } from "@apollo/react-components";
import moment from "moment";
import { Form, Field } from "react-final-form";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";

import { UPDATE_USER } from "../../queries/User";

import Loading from "../../reusable/Loading";
import { openSnackbar } from "../../reusable/Notifier";
import ActivityTable from "../../reusable/ActivityTable";
import Error from "../../uicomponents/Error";

import {
  Button,
  Divider,
  Typography,
  Grid,
  Card,
  TextField,
  CardContent,
} from "@material-ui/core";

import { Save } from "@material-ui/icons";

const GET_USER = gql`
  query user {
    user {
      _id
      first_name
      last_name
      role_id
      email
      created_date
      updated_date
      archived
    }
  }
`;

const GET_ACTIVITIES = gql`
  query getActivities {
    getActivities {
      _id
      time
      content
      user {
        _id
        first_name
        last_name
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation updatePassword($userId: String!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      _id
    }
  }
`;

export default function User() {
  const [updatePassword, { loading: updatePasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted: () => {
        openSnackbar({
          message: "Password updated successfully",
          type: "success",
        });
      },
      onError: () => {
        openSnackbar({
          message: "Failed to reset password, please try again.",
          type: "error",
        });
      },
    }
  );

  const {
    loading: activitiesLoading,
    error: activitiesError,
    data: activitiesData,
  } = useQuery(GET_ACTIVITIES);

  if (activitiesLoading) return <Loading />;

  if (activitiesError) return <Error />;

  const activities = activitiesData.getActivities || [];

  return (
    <Query query={GET_USER}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }

        if (error) {
          return null;
        }

        const { user } = data;

        let userRole = "";
        switch (user.role_id) {
          case 1:
            userRole = "User";

            break;
          case 2:
            userRole = "Manager";

            break;
          case 3:
            userRole = "Office Member";

            break;
          case 4:
            userRole = "Admin";

            break;

          default:
            break;
        }

        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="h1" variant="h4" gutterBottom>
                {`User - ${user.first_name} ${user.last_name}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h5" gutterBottom>
                        Details
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">{`Role: ${userRole}`}</Typography>
                      <Typography variant="body1">{`Email: ${user.email}`}</Typography>
                      <Typography variant="body1">{`Created: ${moment(
                        user.created_date,
                        "x"
                      ).format("DD/MM/YYYY")}`}</Typography>
                      <Typography variant="body1">{`Last Updated: ${moment(
                        user.updated_date,
                        "x"
                      ).format("DD/MM/YYYY")}`}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Mutation
                        mutation={UPDATE_USER}
                        refetchQueries={[
                          {
                            query: GET_USER,
                            variables: {
                              _id: user._id,
                            },
                          },
                        ]}
                      >
                        {(updateUser) => (
                          <Form
                            initialValues={{
                              firstName: user.first_name,
                              lastName: user.last_name,
                            }}
                            validate={(values) => {
                              const errors = {};

                              const requiredFields = ["firstName", "lastName"];

                              requiredFields.forEach((requiredField) => {
                                if (!values[requiredField]) {
                                  errors[requiredField] = "Required";
                                }
                              });

                              return errors;
                            }}
                            onSubmit={(values) => {
                              updateUser({
                                variables: {
                                  userId: user._id,
                                  firstName: values.firstName,
                                  lastName: values.lastName,
                                },
                              })
                                .then(() => {
                                  openSnackbar({
                                    message: "User updated successfully",
                                    type: "success",
                                    open: true,
                                  });
                                })
                                .catch(() => {
                                  openSnackbar({
                                    message:
                                      "Failed to update user, please try again",
                                    type: "error",
                                    open: true,
                                  });
                                });
                            }}
                            render={({
                              handleSubmit,
                              pristine,
                              submitting,
                              invalid,
                            }) => (
                              <form onSubmit={handleSubmit} autoComplete="off">
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={6}>
                                    <Field name="firstName">
                                      {({ input, meta }) => (
                                        <TextField
                                          {...input}
                                          label="First Name"
                                          fullWidth
                                          required
                                          variant="outlined"
                                          error={meta.error && meta.touched}
                                          helperText={
                                            meta.error && meta.touched
                                              ? meta.error
                                              : ""
                                          }
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <Field name="lastName">
                                      {({ input, meta }) => (
                                        <TextField
                                          {...input}
                                          label="Last Name"
                                          fullWidth
                                          required
                                          variant="outlined"
                                          error={meta.error && meta.touched}
                                          helperText={
                                            meta.error && meta.touched
                                              ? meta.error
                                              : ""
                                          }
                                        />
                                      )}
                                    </Field>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      type="submit"
                                      disabled={
                                        pristine || submitting || invalid
                                      }
                                    >
                                      <Save
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      />
                                      Save
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            )}
                          />
                        )}
                      </Mutation>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography component="h2" variant="h5" gutterBottom>
                        Change Password
                      </Typography>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Form
                        validate={(values) => {
                          const errors = {};

                          const requiredFields = ["password", "password2"];

                          requiredFields.forEach((requiredField) => {
                            if (!values[requiredField]) {
                              errors[requiredField] = "Required";
                            }
                          });

                          if (values.password !== values.password2) {
                            errors.password2 = "Passwords Must Match";
                          }

                          return errors;
                        }}
                        onSubmit={({ password }) => {
                          updatePassword({
                            variables: {
                              userId: user._id,
                              password: password,
                            },
                          });
                        }}
                        render={({
                          handleSubmit,
                          pristine,
                          submitting,
                          invalid,
                        }) => (
                          <form onSubmit={handleSubmit} autoComplete="off">
                            <Grid container spacing={3}>
                              <Grid item xs={12}>
                                <Field name="password">
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Password"
                                      fullWidth
                                      required
                                      type="password"
                                      variant="outlined"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12}>
                                <Field name="password2">
                                  {({ input, meta }) => (
                                    <TextField
                                      {...input}
                                      label="Repeat Password"
                                      fullWidth
                                      required
                                      type="password"
                                      variant="outlined"
                                      error={meta.error && meta.touched}
                                      helperText={
                                        meta.error && meta.touched
                                          ? meta.error
                                          : ""
                                      }
                                    />
                                  )}
                                </Field>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  type="submit"
                                  disabled={
                                    pristine ||
                                    submitting ||
                                    invalid ||
                                    updatePasswordLoading
                                  }
                                >
                                  <Save
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  />
                                  Save
                                </Button>
                              </Grid>
                            </Grid>
                          </form>
                        )}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <ActivityTable title="User Activity" activities={activities} />
            </Grid>
          </Grid>
        );
      }}
    </Query>
  );
}
