import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../utils/PageWrapper";
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Breadcrumbs,
} from "@material-ui/core";
import UserManagement from "../../assets/images/UserManagement.svg";
import ProductManagement from "../../assets/images/ProductManagement.svg";
import RoomSettings from "../../assets/images/RoomSettings.svg";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";

const SYNC_TEAMS = gql`
  mutation syncTeams($doit: Boolean) {
    syncTeams(doit: $doit) {
      doit
    }
  }
`;

export default function AdminSettings() {
  const user = useContext(UserContext);
  const [syncTeams] = useMutation(SYNC_TEAMS, {
    onError: (err) => {
      alert("There was a problem syncing the teams " + err);
    },
    onCompleted: () => {
      alert("Syncing.");
    },
  });

  const CardTemplate = ({
    header,
    label,
    buttonLabel,
    linkTo,
    onClick,
    image,
  }) => {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <Card style={{ height: "100%" }}>
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${image})`,
                border: "solid #D8D8D8 1px",
                height: "120px",
                width: "120px",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                marginBottom: "30px",
              }}
            />
            <Typography variant="h6" gutterBottom align="center">
              {header}
            </Typography>
            <Typography variant="body1" paragraph align="center">
              {label}
            </Typography>
            {linkTo ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                component={Link}
                to={linkTo}
              >
                {buttonLabel}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={onClick}
              >
                {buttonLabel}
              </Button>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb" style={{ fontSize: "14px" }}>
          <Typography color="text.primary" style={{ fontSize: "14px" }}>
            Admin Settings
          </Typography>
        </Breadcrumbs>
      </Grid>

      <CardTemplate
        header="User Management"
        label="See all users, add new users, and edit existing users."
        linkTo="/admin-settings/users"
        buttonLabel="Manage Users"
        image={UserManagement}
      />

      <CardTemplate
        header="List Management"
        label="Manage the content of drop downs and other lists."
        linkTo="/admin-settings/lists"
        buttonLabel="Manage Lists"
        image={ProductManagement}
      />

      <CardTemplate
        header="Activity Alerts"
        label="Manage the activity alerts settings."
        linkTo="/admin-settings/activityalerts"
        buttonLabel="Manage Alerts"
        image={RoomSettings}
      />

      {user && user.role_id > 4 && (
        <CardTemplate
          header="Sync Microsoft Teams"
          label="Synchronises all Microsoft Teams with their projects."
          buttonLabel="Sync Teams"
          image={UserManagement}
          onClick={() => {
            if (window.confirm("Are you sure you want to sync all teams?")) {
              syncTeams();
            }
          }}
        />
      )}
    </Grid>
  );
}
