import React, { useEffect, useState } from "react";
import { closeDialog } from "./ScrollablePopup";
import { Card, Grid, Typography, Button, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FlexBox from "../uicomponents/FlexBox";
import { Message } from "@material-ui/icons";

export default function ConfirmationModal({
  title,
  message,
  argsArray,
  onConfirm,
  confirmButtonName,
}) {
  return (
    <Card
      style={{
        padding: "1rem",
        minWidth: "23rem",
        maxWidth: "28rem",
      }}
    >
      <Grid
        container
        style={{
          width: "100%",
          height: "65%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h4">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
              marginLeft: "24px",
              marginRight: "24px",
              backgroundColor: "#C3CAD8",
            }}
          />
        </Grid>
        {message && (
          <Grid
            container
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body1"
              style={{ textAlign: "center", maxWidth: "80%" }}
            >
              Perminently delete link: "{message}"?
            </Typography>
          </Grid>
        )}
      </Grid>

      <Grid
        item
        xs
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => {
            closeDialog();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            onConfirm(argsArray[0], argsArray[1]);
            closeDialog();
          }}
        >
          {confirmButtonName}
        </Button>
      </Grid>
    </Card>
  );
}
