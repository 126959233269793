import React, { createContext } from "react";
import Navbar from "../uicomponents/Navbar";

export const UserContext = createContext();

export default function PageWrapper({ children, user }) {
  return (
    <UserContext.Provider value={user ? { ...user } : user}>
      <div style={{ display: "flex" }}>
        {user && <Navbar user={user} />}

        <main
          style={{
            flexGrow: 1,
            position: "relative",
            height: "100%",
            width: "100%",
            margin: "100px auto",
            maxWidth: "1200px",
            padding: "0 20px",
          }}
        >
          {children}
        </main>
      </div>
    </UserContext.Provider>
  );
}
