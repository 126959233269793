import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

const DropdownWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const DropdownMenu = styled(animated.div)`
  border: 1px solid #dfe3eb;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 24px 0 rgba(28, 28, 38, 0.08);
  position: absolute;
  right: ${({ anchor }) => {
    if (anchor === "right") return 0;
  }};
  min-width: 140px;
  width: ${({ fullwidth }) => {
    if (fullwidth) return "100%";
  }};
  z-index: 1;
  max-height: 250px;
  overflow-y: auto;
  padding: 8px;
`;

export default function Dropdown({
  children,
  anchor,
  fullwidth,
  disabled,
  ...rest
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const wrapperRef = useRef(null);

  function useClickAwayListener(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  }

  useClickAwayListener(wrapperRef);

  //dropdown animation

  const props = useSpring({
    config: { duration: 240 },
    opacity: dropdownOpen ? 1 : 0,
    marginTop: dropdownOpen ? 8 : -8,
  });

  //get trigger

  let dropdownTrigger = children.filter(
    (child) => child.props?.dropdownTrigger
  );

  //there can only be one dropdown trigger
  dropdownTrigger = dropdownTrigger[0];

  // get menuitems

  const menuItems = children.filter((child) => !child.props?.dropdownTrigger);

  return (
    <DropdownWrapper
      onClick={() => {
        if (!disabled) {
          setDropdownOpen(!dropdownOpen);
        }
      }}
      ref={wrapperRef}
      {...rest}
    >
      {dropdownTrigger}

      <DropdownMenu
        className="dropdown-menu"
        anchor={anchor}
        style={{
          ...props,
          visibility: props.opacity.interpolate((opacity) =>
            opacity === 0 ? "hidden" : "visible"
          ),
        }}
        fullwidth={fullwidth ? 1 : 0}
        {...rest}
      >
        {menuItems}
      </DropdownMenu>
    </DropdownWrapper>
  );
}
