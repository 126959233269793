import React from "react";

import AuthenticatedRoute from "../utils/AuthenticatedRoute";

import UserList from "../pages/User/UserList";
import NewUser from "../pages/User/NewUser";
import AdminSettings from "../pages/Settings/AdminSettings";
import UserView from "../pages/User/UserView";
import Updates from "../pages/Updates/Updates";
import UIListSettings from "../pages/Settings/UIListSettings";
import Activity from "../pages/User/Activity";
import ActivityAlerts from "../pages/Settings/ActivityAlerts";

const AdminRoutes = [
  <AuthenticatedRoute
    path="/updates"
    exact
    component={Updates}
    title="Updates"
  />,
  <AuthenticatedRoute
    path="/admin-settings/users"
    exact
    component={UserList}
    title="User Management"
  />,
  <AuthenticatedRoute
    path="/admin-settings/users/activity"
    exact
    component={Activity}
    title="Activity"
  />,
  <AuthenticatedRoute
    path="/admin-settings/lists"
    exact
    component={UIListSettings}
    title="User Management"
  />,

  <AuthenticatedRoute
    path="/admin-settings/users/add"
    exact
    component={NewUser}
    title="Add New User"
  />,
  <AuthenticatedRoute
    path="/admin-settings/users/edit/:userId"
    exact
    component={UserView}
    title="Edit User"
  />,

  <AuthenticatedRoute
    path="/admin-settings"
    exact
    component={AdminSettings}
    title="Admin Settings"
  />,

  <AuthenticatedRoute
    path="/admin-settings/activityalerts"
    exact
    component={ActivityAlerts}
    title="Activity Alerts"
  />,
];

export default AdminRoutes;
