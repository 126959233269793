import React from "react";

import { Grid, Button, Divider } from "@material-ui/core";

import { FormSpy } from "react-final-form";

import { useStore } from "./WizPageStore";

export default function PageSwitch({
  onClick,
  onBackClick,
  submit,
  disabled,
  disabledBack,
  nextButtonLabel,
}) {
  const page = useStore((state) => state.page);
  const next = useStore((state) => state.next);
  const previous = useStore((state) => state.previous);
  const pageChange = useStore((state) => state.pageChange);
  const reset = useStore((state) => state.reset);

  return (
    // <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
    //   {({
    //     values,
    //     pristine,
    //     submitting,
    //     form: {
    //       mutators: { push, pop },
    //     },
    //   }) => (

    <Grid
      //footer container
      container
      style={{ flexShrink: 0, marginTop: "24px" }}
    >
      <Grid item xs={12}>
        <Divider
          style={{
            marginBottom: "24px",
            marginLeft: "-24px",
            marginRight: "-24px",
            backgroundColor: "#C3CAD8",
          }}
        />
      </Grid>
      <Grid
        item
        xs
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          disabled={disabledBack}
          onClick={onBackClick || previous}
          variant="outlined"
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onClick}
          disabled={disabled}
          type={submit ? "submit" : "button"}
        >
          {nextButtonLabel || "Next"}
        </Button>
      </Grid>
    </Grid>

    //   )}
    // </FormSpy>
  );
}
