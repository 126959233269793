import React from "react";

import AuthenticatedRoute from "../utils/AuthenticatedRoute";

import Projects from "../pages/Projects/Projects";
import SingleProject from "../pages/Projects/SingleProject";
import ProjectsGanttSearch from "../pages/Projects/gantt/ProjectsGanttSearch";

const ProjectRoutes = [
  <AuthenticatedRoute
    path="/projects"
    exact
    component={Projects}
    title="Projects"
  />,
  <AuthenticatedRoute
    path="/projectsganttchart"
    exact
    component={ProjectsGanttSearch}
    title="Projects Gantt"
  />,
  <AuthenticatedRoute
    path="/projects/single/:project_id"
    exact
    component={SingleProject}
    title="Project"
  />,
];

export default ProjectRoutes;
