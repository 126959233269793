import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/client";
import { Typography, Grid, Card, Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import {
  Loading,
  ErrorMessage,
} from "@web-applications/daffodil-component-library";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NewProjectDateModal from "../NewProjectDateModal";
import { openDialog } from "../../../reusable/ScrollablePopup";
import ProjectDatesTable from "../ProjectDatesTable";
import { primaryColor } from "../../../assets/ThemeStyling";

/*
:[{
      _id
      event
      date
      department
      details
    }]
*/
export const GET_DATES = gql`
  query getProjectDates($project_id: Int!, $department: String!) {
    getProjectDates(project_id: $project_id, department: $department) {
      project
      dates
    }
  }
`;

export default function ProjectDates({
  project_id,
  department,
  title,
  _id,
  setProject,
}) {
  const [
    getDates,
    { loading: updatesLoading, error: updatesError, data: updatesData },
  ] = useLazyQuery(GET_DATES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
    variables: {
      project_id: parseInt(project_id),
      department: department,
    },
    onCompleted: (args) => {
      if (setProject) {
        setProject(args.getProjectDates.project);
      }
    },
  });

  useEffect(() => {
    getDates();
  }, [getDates]);

  if (updatesError) return <ErrorMessage error={updatesError} />;

  if (updatesLoading || updatesData == null) return <Loading />;

  return (
    <Card
      style={{
        marginBottom: "24px",
        padding: "0",
        width: "100%",
      }}
    >
      <Grid
        item
        xs
        style={{
          padding: "18px 24px",
          backgroundColor: "#4B4F54",
        }}
      >
        <Typography
          variant="h5"
          style={{
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "white",
          }}
        >
          {title}
          <Tooltip title="Add" placement="top">
            <IconButton
              onClick={() => {
                openDialog({
                  content: (
                    <NewProjectDateModal
                      project_id={project_id}
                      onAdded={getDates}
                    />
                  ),
                });
              }}
            >
              <AddCircleIcon style={{ fill: primaryColor }} />
            </IconButton>
          </Tooltip>
        </Typography>
      </Grid>
      <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
        <ProjectDatesTable
          dates={updatesData.getProjectDates.dates}
          _id={_id}
          onRemoved={getDates}
        />
      </div>
    </Card>
  );
}
