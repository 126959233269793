import React from "react";
import ProjectDetailsWizardStep1 from "./ProjectDetailsWizardStep1";

const ProjectCostsModal = ({ project, onSaveClicked }) => {
  return (
    <ProjectDetailsWizardStep1
      project={project}
      onSaveClicked={onSaveClicked}
    />
  );
};

export default ProjectCostsModal;
