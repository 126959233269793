import React, { createContext } from "react";
import { Route } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Helmet } from "react-helmet-async";

import PageWrapper from "./PageWrapper";

const AUTHENTICATE_USER = gql`
  query user {
    user {
      _id
      user_id
      first_name
      last_name
      role_id
      email
      avatar
      preferences
      receive_all_project_emails
    }
  }
`;

export const UserContext = createContext();

export default function AuthenticatedRoute({
  component: Component,
  title,
  ...props
}) {
  const { loading, error, data } = useQuery(AUTHENTICATE_USER, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true, //needed or the oncompleted stop firing and it all breaks
  });

  /*if (error  || !Cookies.get("app")) return <Redirect to="/login" />;*/

  return (
    <Route
      {...props}
      render={(innerProps) => (
        <PageWrapper user={data ? data.user : undefined}>
          <Helmet>
            <title>{title || "App"}</title>
          </Helmet>
          {loading ? <p>Loading...</p> : <Component {...innerProps} />}
        </PageWrapper>
      )}
    />
  );
}
