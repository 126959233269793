import React from "react";
import Popover from "@mui/material/Popover";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EventIcon from "@mui/icons-material/Event";
import { Button, Grid, IconButton } from "@material-ui/core";
import { secondaryColor } from "../assets/ThemeStyling";
import { Form } from "react-final-form";

export default function SmallFormPopover({
  content,
  onSubmit,
  initialValues,
  arrowIcon,
  eventIcon,
  noDateButton,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        style={{ marginLeft: "-12px" }}
      >
        {arrowIcon && (
          <ArrowDropDownIcon
            style={{ fill: secondaryColor }}
          ></ArrowDropDownIcon>
        )}
        {eventIcon && <EventIcon style={{ fill: secondaryColor }}></EventIcon>}
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Form
          onSubmit={(values) => {
            onSubmit(values);
            handleClose();
          }}
          initialValues={initialValues}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                style={{
                  padding: "0",
                  margin: "0",
                  width: "100%",
                  height: "100%",
                  maxWidth: "340px",
                }}
              >
                <Grid xs={12} item>
                  {content}
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      form.reset();
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                  {noDateButton && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        form.change(Object.keys(values)[0], null);
                      }}
                    >
                      No Date
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </form>
          )}
        />
      </Popover>
    </div>
  );
}
