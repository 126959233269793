import { Button } from "@material-ui/core";
import { openDialog } from "./ScrollablePopup";
import { UserContext } from "../utils/PageWrapper";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { useContext } from "react";

export default function ({ text, children }) {
  const user = useContext(UserContext);

  return (
    user.role_id === 10 && (
      <Button
        variant="contained"
        style={{ marginRight: "8%", width: "16rem" }}
        color="secondary"
        onClick={() => {
          openDialog({
            content: children,
          });
        }}
      >
        <VisibilityOffRoundedIcon style={{ marginRight: "10px" }} />
        {text}
      </Button>
    )
  );
}
