import React from "react";

import { Snackbar, SnackbarContent, Typography } from "@material-ui/core";

import { CheckCircle, Error } from "@material-ui/icons";

let openSnackbarFn;

class Notifier extends React.Component {
  state = {
    open: false,
    message: "",
    type: "",
  };

  componentDidMount() {
    openSnackbarFn = this.openSnackbar;
  }

  openSnackbar = ({ message, type }) => {
    this.setState({
      open: true,
      message,
      type,
    });
  };

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
      type: "",
    });
  };

  render() {
    let color;

    if (this.state.type === "success") {
      color = "#66BC0C";
    } else if (this.state.type === "error") {
      color = "#E02020";
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
          border: "1px solid #DFE3EB",
          padding: "0",
          margin: "0",
        }}
        open={this.state.open}
        autoHideDuration={4000}
        onClose={this.handleSnackbarClose}
      >
        <SnackbarContent
          aria-describedby="Notifications"
          style={{ backgroundColor: color, border: "1px solid #DFE3EB" }}
          message={
            <span
              style={{
                display: "flex",
                paddingLeft: "8px",
              }}
            >
              {this.state.type === "success" && (
                <CheckCircle
                  style={{
                    marginTop: "26px",
                  }}
                />
              )}
              {this.state.type === "error" && (
                <Error
                  style={{
                    marginTop: "26px",
                  }}
                />
              )}

              <Typography
                variant="body1"
                style={{
                  color: "black",
                  marginLeft: "8px",
                  backgroundColor: "white",
                  padding: "24px",
                }}
              >
                {this.state.type === "success" && (
                  <div
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    Confirmation
                  </div>
                )}
                {this.state.type === "error" && (
                  <div
                    style={{
                      marginBottom: "8px",
                    }}
                  >
                    Something Failed
                  </div>
                )}
                {this.state.message}
              </Typography>
            </span>
          }
        />
      </Snackbar>
    );
  }
}

export function openSnackbar({ message, type }) {
  openSnackbarFn({ message, type });
}

export default Notifier;
