import "react-app-polyfill/ie11"; // For IE 11 support
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "./assets/overrides.css";

// Poppins:wght@400;500;600&family=Roboto:wght@400;500

import "./assets/fonts/Poppins-Regular.ttf";
import "./assets/fonts/Poppins-Medium.ttf";
import "./assets/fonts/Poppins-SemiBold.ttf";

import "./assets/fonts/Roboto-Regular.ttf";
import "./assets/fonts/Roboto-Medium.ttf";

ReactDOM.render(<App />, document.getElementById("root"));
