import React from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";

import { filterHidden, GET_USERS, sortByName } from "../../queries/User";

import {
  Grid,
  Card,
  Button,
  TextField,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import { FormControl, InputLabel, Select } from "@mui/material";
import { Field, Form } from "react-final-form";
import Typography from "../../uicomponents/Typography";

import { openSnackbar } from "../../reusable/Notifier";

const GET_SETTINGS = gql`
  query settings {
    settings {
      staff_activity_check_days
      staff_activity_check_updates
      project_activity_check_days
      project_activity_check_updates
      activity_alert_days
    }
  }
`;
const UPDATE_SETTINGS = gql`
  mutation updateSettings(
    $staff_activity_check_days: Int
    $staff_activity_check_updates: Int
    $project_activity_check_days: Int
    $project_activity_check_updates: Int
    $users: [String]
    $activity_alert_days: [Int]
  ) {
    updateSettings(
      staff_activity_check_days: $staff_activity_check_days
      staff_activity_check_updates: $staff_activity_check_updates
      project_activity_check_days: $project_activity_check_days
      project_activity_check_updates: $project_activity_check_updates
      users: $users
      activity_alert_days: $activity_alert_days
    ) {
      staff_activity_check_days
      staff_activity_check_updates
      project_activity_check_days
      project_activity_check_updates
      activity_alert_days
    }
  }
`;

export default function ActivityAlerts(props) {
  const {
    loading: usersLoading,
    data: usersData,
    usersError,
  } = useQuery(GET_USERS);

  const {
    loading: settingsLoading,
    data: settingsData,
    settingsError,
  } = useQuery(GET_SETTINGS);

  const [
    updateSettings,
    {
      loading: updateSettingsLoading,
      error: updateSettingsError,
      data: updateSettingsData,
    },
  ] = useMutation(UPDATE_SETTINGS, {
    refetchQueries: [
      {
        query: GET_SETTINGS,
      },
      {
        query: GET_USERS,
      },
    ],
    onError: () => {
      openSnackbar({
        message: "Error updating settings, please try again",
        type: "error",
      });
    },
    onCompleted: (data) => {
      openSnackbar({
        message: "Settings updated successfully",
        type: "success",
      });
    },
  });

  // console.log("users", usersData);
  // console.log("settingsData", settingsData);

  const days=[];
  for(let i=1;i<=31;i++){
    days.push(i);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h1" variant="h4">
          Activity Alerts Settings
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Form
            initialValues={{
              activity_alert_days: [],
              ...settingsData?.settings,
              users: usersData?.users
                ? usersData?.users
                    .filter(
                      (user) => user.receive_activity_alert_emails === true
                    )
                    .map((user) => user._id)
                : [],
            }}
            onSubmit={(values) => {
              updateSettings({
                variables: {
                  staff_activity_check_days: parseInt(
                    values.staff_activity_check_days
                  ),
                  staff_activity_check_updates: parseInt(
                    values.staff_activity_check_updates
                  ),
                  project_activity_check_days: parseInt(
                    values.project_activity_check_days
                  ),
                  project_activity_check_updates: parseInt(
                    values.project_activity_check_updates
                  ),
                  users: values.users,
                  activity_alert_days: values.activity_alert_days,
                },
              });
            }}
            render={({ handleSubmit, form, values, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Field name="staff_activity_check_days">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Days of staff activity"
                          fullWidth
                          // required
                          variant="outlined"
                          type="number"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={4}>
                    <Field name="staff_activity_check_updates">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Number of staff updates"
                          fullWidth
                          // required
                          variant="outlined"
                          type="number"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid item xs={4}>
                    <Field name="project_activity_check_days">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Days of project activity"
                          fullWidth
                          // required
                          variant="outlined"
                          type="number"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={4}>
                    <Field name="project_activity_check_updates">
                      {({ input, meta }) => (
                        <TextField
                          {...input}
                          label="Number of project updates"
                          fullWidth
                          // required
                          variant="outlined"
                          type="number"
                          error={meta.error && meta.touched}
                          helperText={
                            meta.error && meta.touched ? meta.error : ""
                          }
                          InputProps={{
                            inputProps: { min: 0 },
                          }}
                        />
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid item xs={12}>
                    <Typography variant="label">
                      Select users to send activity alerts to
                    </Typography>
                    <Field name="users">
                      {({
                        input: { name, onChange, value, ...restInput },
                        meta,
                        ...rest
                      }) => (
                        <FormControl>
                          {!values.users[0] && (
                            <InputLabel id="demo-multiple-chip-label">
                              Send activity alerts to?
                            </InputLabel>
                          )}
                          <Select
                            style={{
                              maxHeight: "56px",
                              maxWidth: "30vw",
                              minWidth: "300px",
                            }}
                            {...rest}
                            helperText={meta.touched ? meta.error : undefined}
                            error={meta.error && meta.touched}
                            inputProps={restInput}
                            onChange={onChange}
                            value={value}
                            labelId="multiple-name-label"
                            id="multiple-select"
                            multiple
                            input={<OutlinedInput />}
                          >
                            {usersData?.users
                              .filter(filterHidden)
                              .sort(sortByName)
                              .map((user) => (
                                <MenuItem key={user._id} value={user._id}>
                                  {`${user.first_name} ${user.last_name}`}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="label">
                      Select days to send activity alerts on
                    </Typography>
                    <Field name="activity_alert_days">
                      {({
                        input: { name, onChange, value, ...restInput },
                        meta,
                        ...rest
                      }) => (
                        <FormControl>
                          {!values.activity_alert_days[0] && (
                            <InputLabel id="demo-multiple-chip-label">
                              Days to send activity alerts on?
                            </InputLabel>
                          )}
                          <Select
                            style={{
                              maxHeight: "56px",
                              maxWidth: "30vw",
                              minWidth: "300px",
                            }}
                            {...rest}
                            helperText={meta.touched ? meta.error : undefined}
                            error={meta.error && meta.touched}
                            inputProps={restInput}
                            onChange={onChange}
                            value={value}
                            labelId="multiple-name-label"
                            id="multiple-select"
                            multiple
                            input={<OutlinedInput />}
                          >
                            {days.map((day) => (
                              <MenuItem key={day} value={day}>
                                {day}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      loading={
                        usersLoading || settingsLoading || updateSettingsLoading
                      }
                      disabled={
                        submitting ||
                        usersLoading ||
                        settingsLoading ||
                        updateSettingsLoading
                      }
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            )}
          />
        </Card>
      </Grid>
    </Grid>
  );
}
