import { Form } from "react-final-form";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import React from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Field } from "react-final-form";
import { openSnackbar } from "../../reusable/Notifier";
import { closeDialog } from "../../reusable/ScrollablePopup";
import {
  Grid,
  Divider,
  Typography,
  Button,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { dateLabel } from "../../assets/GlobalLabels";

const TextFieldAdapter = ({ input, meta, ...rest }) => (
  <TextField
    {...input}
    {...rest}
    onChange={({ target: { value } }) => input.onChange(value)}
    errorText={meta.touched ? meta.error : ""}
    fullWidth
    variant="outlined"
    color="secondary"
  />
);

const ADD_PROJECT_DATE = gql`
  mutation addProjectDate(
    $project_id: Int
    $event: String
    $date: String
    $department: String
    $details: String
  ) {
    addProjectDate(
      project_id: $project_id
      event: $event
      date: $date
      department: $department
      details: $details
    ) {
      _id
    }
  }
`;

const roles = ["Start On Site", "Handover", "Custom"];

const departments = ["All", "Design", "QS", "PM", "Purchasing"];

export default function NewProjectDateWizardStep1(props) {
  const [addProjectDate, { loading, error, data }] = useMutation(
    ADD_PROJECT_DATE,
    {
      onCompleted: () => {
        openSnackbar({
          message: "Project date has been created.",
          type: "success",
        });

        //next();
        props.onAdded();
        closeDialog();
      },
      onError: (error) => {
        console.log(error);
        openSnackbar({
          message: "Failed to create project date.",
          type: "error",
        });
      },
    }
  );

  // console.log("step1 props", props);
  return (
    <Form onSubmit={() => {}}>
      {({
        handleSubmit,
        submitting,
        values,
        // form: {
        //   mutators: { push, pop },
        // },
      }) => (
        <Grid
          container
          spacing={3}
          style={{
            padding: "12px",
            margin: "0px",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontWeight: 600,
                fontFamily: "Poppins",
              }}
            >
              New Date
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Field name="event_select">
              {({ input, meta }) => (
                <TextField
                  {...input}
                  select
                  error={meta.error && meta.touched}
                  helperText={meta.error && meta.touched ? meta.error : ""}
                  label="Choose event"
                  fullWidth
                  size="small"
                  // required
                  variant="outlined"
                  // style={{ paddingBottom: "15px" }}
                >
                  {roles.map((role) => (
                    <MenuItem value={role}>{role}</MenuItem>
                  ))}
                </TextField>
              )}
            </Field>
          </Grid>

          {values.event_select === "Custom" && (
            <Grid item xs={12}>
              <Field
                name="event"
                component={TextFieldAdapter}
                label="Please enter the name of the event"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Field
              name="details"
              component={TextFieldAdapter}
              label="Please enter the details of the event"
            />
          </Grid>

          <Grid item xs={6}>
            <Field name="department">
              {({ input, meta }) => (
                <>
                  <TextField
                    {...input}
                    select
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    label="Department"
                    fullWidth
                    // required
                    variant="outlined"
                    // style={{ paddingBottom: "15px" }}
                  >
                    {departments.map((department) => (
                      <MenuItem key={department} value={department}>
                        {department}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              )}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Field name="date">
                {({ input, meta }) => (
                  <DatePicker
                    format="DD/MM/YYYY"
                    name={input.name}
                    onChange={input.onChange}
                    value={input.value ? input.value : null}
                    onBlur={input.onBlur}
                    fullWidth
                    required
                    disablePast={false}
                    autoOk={true}
                    disabled={false}
                    error={meta.error && meta.touched}
                    helperText={meta.error && meta.touched ? meta.error : ""}
                    /*maxDate={moment(values.depositDate).add(
                  1,
                  values.orderType === 'weekly' ? 'weeks' : 'months'
                )}
                minDate={moment(values.depositDate).add(1, 'days')}*/
                    label={dateLabel}
                    leftArrowIcon={<KeyboardArrowLeft />}
                    inputVariant="outlined"
                    rightArrowIcon={<KeyboardArrowRight />}
                  />
                )}
              </Field>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={12}>
            <Divider
              style={{
                marginLeft: "-24px",
                marginRight: "-24px",
                backgroundColor: "#C3CAD8",
              }}
            />
          </Grid>

          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                addProjectDate({
                  variables: {
                    project_id: parseInt(props.project_id),
                    event: values.event ? values.event : values.event_select,
                    date: values.date,
                    department: values.department,
                    details: values.details,
                  },
                });
              }}
              disabled={
                submitting ||
                (!values.event && !values.event_select) ||
                (!values.event && values.event_select === "Custom") ||
                !values.date ||
                !values.department
              }
              type="submit"
            >
              Add Date
            </Button>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </Grid>
        </Grid>
      )}
    </Form>
  );
}
